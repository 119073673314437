import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import api from '@shared/services'
import { keyToString } from '@shared/utils'

// Encounters Query Key factory
export const PatientEncountersKeys = {
  // ['patient-encounters']
  all: ['patient-encounters'],

  // ['patient-encounters', patientId]
  patient: (patientId) => keyToString([...PatientEncountersKeys.all, patientId]),

  // ['patient-encounters', patientId, 'lists']
  lists: (patientId) => [...PatientEncountersKeys.patient(patientId), 'lists'],

  // ['patient-encounters', patientId, 'lists', { ...query }]
  list: (patientId, query) => [...PatientEncountersKeys.lists(patientId), query],

  // ['patient-encounters', patientId, 'encounter']
  encounters: (patientId) => [...PatientEncountersKeys.patient(patientId), 'encounter'],

  // ['patient-encounters', patientId, 'encounter', encounterId]
  encounter: (patientId, encounterId) => keyToString([...PatientEncountersKeys.encounters(patientId), encounterId]),

  // ===================== NOTES =====================

  // ['patient-encounters', patientId, 'encounter', encounterId, 'notes']
  notesLists: (patientId, encounterId) => [...PatientEncountersKeys.encounter(patientId, encounterId), 'notes'],

  // ['patient-encounters', patientId, 'encounter', encounterId, 'notes', { ...query }]
  notesList: (patientId, encounterId, query) => [...PatientEncountersKeys.notesLists(patientId, encounterId), query],

  // ===================== VACCINES =====================

  // ['patient-encounters', patientId, 'encounter', encounterId, 'vaccines-lists']
  vaccinesLists: (patientId, encounterId) => [...PatientEncountersKeys.encounter(patientId, encounterId), 'vaccines-lists'],

  // ['patient-encounters', patientId, 'encounter', encounterId, 'vaccines-lists', { ...query }]
  vaccinesList: (patientId, encounterId, query) => [...PatientEncountersKeys.vaccinesLists(patientId, encounterId), query],

  // ===================== APPOINTMENTS =====================

  // ['patient-encounters', patientId, 'encounter', encounterId, 'appointments'],
  appointmentsLists: (patientId, encounterId) => [...PatientEncountersKeys.encounter(patientId, encounterId), 'appointments'],

  // ['patient-encounters', patientId, 'encounter', encounterId, 'appointments', { ...query }]
  appointmentsList: (patientId, encounterId, query) => [...PatientEncountersKeys.appointmentsLists(patientId, encounterId), query],

  // ===================== AVAILABILITIES =====================

  // ['patient-encounters', patientId, 'encounter', encounterId, 'availabilities'],
  availabilitiesLists: (patientId, encounterId) => [...PatientEncountersKeys.encounter(patientId, encounterId), 'availabilities'],

  // ['patient-encounters', patientId, 'encounter', encounterId, 'availabilities', { ...query }]
  availabilitiesList: (patientId, encounterId, query) => [...PatientEncountersKeys.availabilitiesLists(patientId, encounterId), query],

  // ===================== LABS =====================

  // ['patient-encounters', patientId, 'encounter', encounterId, 'labs']
  labsLists: (patientId, encounterId) => [...PatientEncountersKeys.encounter(patientId, encounterId), 'labs'],

  // ['patient-encounters', patientId, 'encounter', encounterId, 'labs', { ...query }]
  labsList: (patientId, encounterId, query) => [...PatientEncountersKeys.labsLists(patientId, encounterId), query],

  // ===================== PRESCRIPTIONS =====================

  // ['patient-encounters', patientId, 'encounter', encounterId, 'prescriptions']
  prescriptionsLists: (patientId, encounterId) => [...PatientEncountersKeys.encounter(patientId, encounterId), 'prescriptions'],

  // ['patient-encounters', patientId, 'encounter', encounterId, 'prescriptions', { ...query }]
  prescriptionsList: (patientId, encounterId, query) => [...PatientEncountersKeys.prescriptionsLists(patientId, encounterId), query],

  // ===================== REFERRALS =====================

  // ['patient-encounters', patientId, 'encounter', encounterId, 'referrals']
  referralsLists: (patientId, encounterId) => [...PatientEncountersKeys.encounter(patientId, encounterId), 'referrals'],

  // ['patient-encounters', patientId, 'encounter', encounterId, 'referrals', { ...query }]
  referralsList: (patientId, encounterId, query) => [...PatientEncountersKeys.referralsLists(patientId, encounterId), query],

  // ===================== ASSESSMENTS =====================

  // ['patient-encounters', patientId, 'encounter', encounterId, 'assessments']
  assessmentsLists: (patientId, encounterId) => [...PatientEncountersKeys.encounter(patientId, encounterId), 'assessments'],

  // ['patient-encounters', patientId, 'encounter', encounterId, 'assessments', { ...query }]
  assessmentsList: (patientId, encounterId, query) => [...PatientEncountersKeys.assessmentsLists(patientId, encounterId), query],

  // ===================== DOH Morbidity =====================

  // ['patient-encounters', patientId, 'encounter', encounterId, 'doh-morbidity']
  morbidityLists: (patientId, encounterId) => [...PatientEncountersKeys.encounter(patientId, encounterId), 'doh-morbidity'],

  // ['patient-encounters', patientId, 'encounter', encounterId, 'doh-morbidity', { ...query }]
  morbidityList: (patientId, encounterId, query) => [...PatientEncountersKeys.morbidityLists(patientId, encounterId), query],
}

const PatientEncountersApi = {
  // ===================== ENCOUNTERS =====================
  list: (patientId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/encounters`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  details: (patientId, encounterId) =>
    api
      .get(`/patients/${patientId}/encounters/${encounterId}`)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  create: (patientId, data) =>
    api.post(`/patients/${patientId}/encounters`, data).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  delete: (patientId, encounterId) => api.delete(`/patients/${patientId}/encounters/${encounterId}`),

  update: (patientId, encounterId, data) =>
    api
      .patch(`/patients/${patientId}/encounters/${encounterId}`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  // ===================== NOTES =====================
  notes: (patientId, encounterId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/encounters/${encounterId}/notes`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  createNote: (patientId, encounterId, message) =>
    api
      .post(`/patients/${patientId}/encounters/${encounterId}/notes`, { message })
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  // ===================== APPOINTMENTS =====================
  appointments: (patientId, encounterId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/encounters/${encounterId}/appointments`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  createNotScheduledAppointment: (patientId, encounterId, data) =>
    api
      .post(`/patients/${patientId}/encounters/${encounterId}/appointments`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  createScheduledAppointment: (patientId, encounterId, data) =>
    api
      .post(`/patients/${patientId}/encounters/${encounterId}/scheduled_appointments`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  updateScheduledAppointment: (patientId, encounterId, appointmentId, data) =>
    api
      .patch(`/patients/${patientId}/encounters/${encounterId}/scheduled_appointments/${appointmentId}`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  submitSoapNote: (patientId, encounterId, appointmentId, data) =>
    api
      .patch(`/patients/${patientId}/encounters/${encounterId}/appointments/${appointmentId}`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  // ===================== AVAILABILITIES =====================
  availabilities: (patientId, encounterId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/encounters/${encounterId}/availabilities`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  // ===================== LABS =====================
  labs: (patientId, encounterId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/encounters/${encounterId}/labs`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  // ===================== PRESCRIPTIONS =====================
  prescriptions: (patientId, encounterId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/encounters/${encounterId}/prescriptions`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  // ===================== VACCINES =====================
  vaccineOrders: (patientId, encounterId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/encounters/${encounterId}/vaccine_orders`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  createVaccineOrder: (patientId, encounterId, data) =>
    api
      .post(`/patients/${patientId}/encounters/${encounterId}/vaccine_orders`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  // ===================== ASSESSMENTS =====================
  assessments: (patientId, encounterId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/encounters/${encounterId}/assessments`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  createAssessment: (patientId, encounterId, data) =>
    api
      .post(`/patients/${patientId}/encounters/${encounterId}/assessments`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  reviewAssessment: (patientId, encounterId, assessmentId) =>
    api
      .post(`/patients/${patientId}/encounters/${encounterId}/assessments/${assessmentId}/review`)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  resendAssessment: (patientId, encounterId, assessmentId) =>
    api
      .put(`/patients/${patientId}/encounters/${encounterId}/assessments/${assessmentId}/resend`)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  // ===================== REFERRALS =====================
  referrals: (patientId, encounterId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/encounters/${encounterId}/referrals`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  referralSubmit: (patientId, encounterId, data) =>
    api
      .post(`/patients/${patientId}/encounters/${encounterId}/referrals`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  referralPreview: (patientId, encounterId, query) =>
    api
      .get(
        QS.stringifyUrl(
          {
            url: `/patients/${patientId}/encounters/${encounterId}/referrals/preview`,
            query,
          },
          { arrayFormat: 'bracket' }
        ),
        { timeout: Infinity, responseType: 'blob' }
      )
      .then((res) => res.data),

  // ===================== DOH MORBIDITY =====================
  morbidity: (patientId, encounterId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/encounters/${encounterId}/doh_morbidities`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  createMorbidity: (patientId, encounterId, data) =>
    api
      .post(`/patients/${patientId}/encounters/${encounterId}/doh_morbidities`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  previewMorbidity: (patientId, encounterId, query) =>
    api
      .get(
        QS.stringifyUrl(
          {
            url: `/patients/${patientId}/encounters/${encounterId}/doh_morbidities/preview`,
            query,
          },
          { arrayFormat: 'bracket' }
        ),
        { timeout: Infinity, responseType: 'blob' }
      )
      .then((res) => res.data),
}

export default PatientEncountersApi
