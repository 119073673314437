import { outlineSearchStyling } from '@utils/StylesHelper'

const styles = {
  input: {
    ...outlineSearchStyling,
    input: {
      padding: '8px !important',
      '&::placeholder': {
        color: 'text.primary',
        opacity: 1,
      },
    },
  },
}

export default styles
