import { useMemo, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import interactionPlugin from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayjs from 'dayjs'
import { useUpdateEffect } from 'usehooks-ts'
import { styled } from '@mui/material/styles'

import useLoadingState from '@shared/hooks/src/useLoadingState'
import { useQueryEvents } from '@shared/hooks/src/useQueryEvents'
import { TimeSlotDuration } from '@shared/utils'

import useBusinessHours from '@hooks/useBusinessHours'
import AppointmentDetailsModal from '@pages/Appointments/AppointmentDetailsModal'
import { Box, Fade, Stack, Tooltip, Typography } from '@mui-components'
import CalendarDate from '@components/CalendarDate'
import LinearProgress from '@components/LinearProgress'

import AvailabilityUpdate from './AvailabilityUpdate'
import { useBlackoutPeriods, useDate, useWeekAdminTimes, useWeekAppointments, useWeekAvailability } from './Calendar.hooks'
import { IndicatorGuide, Timing } from './Calendar.utils'
import CalendarStyled from './CalendarStyled'

export default function Calendar({ user, isLoading = false, selectable = false }) {
  const calendarRef = useRef(null)

  const timezone = user?.provider.timezone
  const [date, setDate] = useDate(timezone)
  const [view, setView] = useState('week')

  const [isUpdateAvailabilityOpen, setIsUpdateAvailabilityOpen] = useState(false)
  const [isAppointmentDetailsOpen, setIsAppointmentDetailsOpen] = useState(false)
  const [selectedAvailability, setSelectedAvailability] = useState(null)
  const [selectedAppointmentId, setSelectedAppointmentId] = useState(null)

  const businessHoursQuery = useBusinessHours()
  const { data: businessHours, isPending: areBusinessHoursPending } = businessHoursQuery
  const { data: availabilities, isFetching: areAvailabilitiesLoading } = useWeekAvailability(user, date)
  const { data: adminTimes, isFetching: areAdminTimesLoading } = useWeekAdminTimes(user, date)
  const { data: appointments, isFetching: areAppointmentsLoading } = useWeekAppointments(user, date)
  const { data: blackoutPeriods, isFetching: areBlackoutPeriodsLoading } = useBlackoutPeriods(user, date, businessHours)

  useQueryEvents(businessHoursQuery, {
    onSuccess: (data) => calendarRef.current?.getApi()?.scrollToTime(data?.start_time),
  })

  const loading = useLoadingState(
    isLoading ||
      areAppointmentsLoading ||
      areAdminTimesLoading ||
      areAvailabilitiesLoading ||
      areBlackoutPeriodsLoading ||
      areBusinessHoursPending
  )
  const events = useMemo(() => {
    return (availabilities || [])
      .concat(adminTimes || [])
      .concat(appointments?.events || [])
      .concat(blackoutPeriods || [])
  }, [adminTimes, appointments?.events, availabilities, blackoutPeriods])

  const timing = useMemo(() => {
    if (!user) return undefined
    if (!availabilities || !adminTimes) return undefined

    const maxMinutes = user?.provider.maxHours * 60
    const adminTimeInMinutes = adminTimes?.reduce((acc, { duration }) => acc + duration, 0) || 0
    const apptsTimeInMinutes = availabilities?.reduce((acc, { duration }) => acc + duration, 0) || 0

    return {
      max: maxMinutes,
      adminTime: adminTimeInMinutes,
      apptsTime: apptsTimeInMinutes,
    }
  }, [adminTimes, availabilities, user])

  const selectedAppointment = useMemo(() => {
    return appointments?.data.find((a) => a.id.toString() === selectedAppointmentId)
  }, [appointments?.data, selectedAppointmentId])

  const handleEventSelect = (event) => {
    const now = dayjs().tz(timezone)
    if (dayjs(event.end).isBefore(now)) {
      toast.error('Sorry, changes to past availabilities are restricted.')
      return calendarRef.current?.getApi()?.unselect()
    }

    setSelectedAvailability(event)
    setIsUpdateAvailabilityOpen(true)
  }

  const handleEventClick = ({ event }) => {
    const type = event.extendedProps?.type

    if (type === 'appointment') {
      setSelectedAppointmentId(event.id)
      setIsAppointmentDetailsOpen(true)
    }

    if (!selectable) return

    if (['availability', 'adminTime'].includes(type)) {
      const now = dayjs().tz(timezone)
      if (dayjs(event.end).isBefore(now)) return toast.error('Sorry, changes to past availabilities are restricted.')

      setSelectedAvailability({ id: event.extendedProps.id, type, start: event.start, end: event.end })
      setIsUpdateAvailabilityOpen(true)
    }
  }

  useUpdateEffect(() => {
    const calendarEl = calendarRef.current
    if (!calendarEl) return
    calendarEl.getApi().gotoDate(date.toDate())
  }, [date])

  const slotDuration = `00:${TimeSlotDuration}:00`

  return (
    <Fade in>
      <Stack spacing={1} flexGrow={1}>
        <AvailabilityUpdate
          availability={selectedAvailability}
          open={isUpdateAvailabilityOpen}
          onClose={() => {
            setIsUpdateAvailabilityOpen(false)
            setTimeout(() => setSelectedAvailability(null), 300)
          }}
        />
        <AppointmentDetailsModal
          timezone={timezone}
          appointment={{ ...selectedAppointment, provider: user?.provider }}
          open={isAppointmentDetailsOpen}
          onClose={() => {
            setIsAppointmentDetailsOpen(false)
            setTimeout(() => setSelectedAppointmentId(null), 300)
          }}
        />

        <Stack spacing={2}>
          <Stack direction="row" spacing={1} alignItems="flex-end" justifyContent="space-between">
            <Stack>
              <IndicatorGuide />
              <Typography variant="body2">
                Times shown in <b>Provider’s timezone {timezone ? `(${timezone})` : ''}</b>
              </Typography>
            </Stack>
            <Timing data={timing} />
          </Stack>

          <CalendarDate
            date={date}
            onDateChange={setDate}
            view={view}
            onViewChange={(view) => {
              setView(view)
              calendarRef.current?.getApi()?.changeView(view === 'day' ? 'timeGridDay' : 'timeGridWeek')
            }}
          />
        </Stack>

        <Stack sx={{ position: 'relative' }}>
          <LinearProgress loading={loading} />
          <CalendarStyled id="scheduling-calendar">
            <FullCalendar
              ref={calendarRef}
              weekends
              nowIndicator
              events={events}
              initialDate={date.toDate()}
              scrollTime={businessHours?.start_time}
              slotDuration={slotDuration}
              defaultTimedEventDuration={slotDuration}
              eventContent={getEventContent}
              forceEventDuration
              rerenderDelay={10}
              headerToolbar={false}
              allDaySlot={false}
              displayEventTime={false}
              selectable={selectable}
              select={handleEventSelect}
              eventClick={handleEventClick}
              selectOverlap={false}
              firstDay={-1}
              selectConstraint="businessHours"
              height={1100}
              businessHours={{
                daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
                startTime: businessHours?.start_time,
                endTime: businessHours?.end_time,
              }}
              plugins={[timeGridPlugin, interactionPlugin]}
            />
          </CalendarStyled>
        </Stack>
      </Stack>
    </Fade>
  )
}

const getEventContent = (arg) => (
  <Hover event={arg.event}>
    <Box
      sx={{
        height: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }}
    >
      {arg.event.title}
    </Box>
  </Hover>
)

const Hover = styled(({ className, children, event, ...props }) => (
  <Tooltip placement="top" title={event.extendedProps.tooltip} followCursor {...props} classes={{ popper: className }}>
    {children}
  </Tooltip>
))({
  [`& .MuiTooltip-tooltip`]: {
    maxWidth: 500,
  },
})
