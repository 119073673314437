import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import api from '@shared/services'
import { keyToString } from '@shared/utils'

export const ProviderKeys = {
  // ['providers']
  all: ['providers'],

  // ['providers', 'lists']
  lists: () => [...ProviderKeys.all, 'lists'],

  // ['providers', 'lists', { ...query }]
  list: (query) => [...ProviderKeys.lists(), query],

  // ['providers', providerId]
  provider: (providerId) => keyToString([...ProviderKeys.all, providerId]),
}

// Availabilities Query Key factory
export const ProviderAvailabilitiesKeys = {
  // ['provider-availabilities']
  all: ['provider-availabilities'],

  // ['provider-availabilities', providerId]
  provider: (providerId) => keyToString([...ProviderAvailabilitiesKeys.all, providerId]),

  // ['provider-availabilities', providerId, 'lists']
  lists: (providerId) => [...ProviderAvailabilitiesKeys.provider(providerId), 'lists'],

  // ['provider-availabilities', providerId, 'lists', { ...query }]
  list: (providerId, query) => [...ProviderAvailabilitiesKeys.lists(providerId), query],

  // ['provider-availabilities', providerId, 'availability']
  availabilities: (providerId) => [...ProviderAvailabilitiesKeys.provider(providerId), 'availability'],

  // ['provider-availabilities', providerId, 'availability', availabilityId]
  availability: (providerId, availabilityId) => keyToString([...ProviderAvailabilitiesKeys.availabilities(providerId), availabilityId]),

  // ['provider-availabilities', providerId, 'months']
  months: (providerId) => [...ProviderAvailabilitiesKeys.provider(providerId), 'months'],

  // ['provider-availabilities', providerId, 'months', { ...query }]
  month: (providerId, query) => [...ProviderAvailabilitiesKeys.months(providerId), query],
}

// Admin Time Query Key factory
export const ProviderAdminTimeKeys = {
  // ['provider-admin-time']
  all: ['provider-admin-time'],

  // ['provider-admin-time', providerId]
  provider: (providerId) => keyToString([...ProviderAdminTimeKeys.all, providerId]),

  // ['provider-admin-time', providerId, 'lists']
  lists: (providerId) => [...ProviderAdminTimeKeys.provider(providerId), 'lists'],

  // ['provider-admin-time', providerId, 'lists', { ...query }]
  list: (providerId, query) => [...ProviderAdminTimeKeys.lists(providerId), query],

  // ['provider-admin-time', providerId, 'time']
  times: (providerId) => [...ProviderAdminTimeKeys.provider(providerId), 'time'],

  // ['provider-admin-time', providerId, 'time', timeId]
  time: (providerId, timeId) => keyToString([...ProviderAdminTimeKeys.times(providerId), timeId]),
}

export const ProviderCBOsKey = (providerId, state) => keyToString(['provider-cbos', providerId, 'state', state])
export const ProviderAssessmentsKey = {
  all: ['provider-assessments'],
  lists: (providerId) => keyToString([...ProviderAssessmentsKey.all, providerId]),
  list: (providerId, query) => [...ProviderAssessmentsKey.lists(providerId), query],
}
export const ProviderLabsKeys = {
  all: ['provider-labs'],
  lists: (providerId) => keyToString([...ProviderLabsKeys.all, providerId]),
  list: (providerId, query) => [...ProviderLabsKeys.lists(providerId), query],
}
export const ProviderPARTasksKeys = {
  all: ['provider-par-tasks'],
  lists: (providerId) => keyToString([...ProviderPARTasksKeys.all, providerId]),
  list: (providerId, query) => [...ProviderPARTasksKeys.lists(providerId), query],
}
export const ProvidersAvailabilitiesSummaryKey = {
  lists: ['providers-availabilities-summary'],
  list: (query) => [...ProvidersAvailabilitiesSummaryKey.lists, query],
}

const ProvidersApi = {
  // GET
  details: (providerId) => api.get(`/providers/${providerId}`).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data),

  appointments: (providerId) =>
    api
      .get(`/providers/${providerId}/dashboard_next_appointments`)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  labs: (providerId, query) =>
    api.get(QS.stringifyUrl({ url: `/providers/${providerId}/dashboard_labs_review`, query })).then((res) => {
      return {
        review: deserialize(res.data.review_status, { transformKeys: 'camelCase' })?.data ?? [],
        followUp: deserialize(res.data.follow_up_status, { transformKeys: 'camelCase' })?.data ?? [],
        positive: deserialize(res.data.positive_status, { transformKeys: 'camelCase' })?.data ?? [],
      }
    }),
  assessments: (providerId, query) => {
    return api
      .get(QS.stringifyUrl({ url: `/providers/${providerId}/dashboard_assessments_review`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? [])
  },
  patientAtRiskTasks: (providerId, query) => {
    return api
      .get(QS.stringifyUrl({ url: `/providers/${providerId}/par_tasks`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? [])
  },
  patientAtRiskResolve: (providerId, taskId, data) => {
    return api
      .post(`/providers/${providerId}/par_tasks/${taskId}/mark_completed`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {})
  },
  cbos: (providerId, query, config) =>
    api
      .get(QS.stringifyUrl({ url: `/providers/${providerId}/cbos`, query }), config)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  getChangeUrl: (providerId, data) => api.post(`/providers/${providerId}/prescriptions/rx_url`, data),
  syncPrescriptions: (providerId, data) => api.post(`/providers/${providerId}/prescriptions/sync`, data),

  // Availability
  availabilities: (providerId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/providers/${providerId}/availabilities`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  availabilitiesMonthly: (providerId, query) =>
    api.get(QS.stringifyUrl({ url: `/providers/${providerId}/availabilities/monthly_count`, query })).then((res) => {
      return res.data.reduce((acc, day) => {
        Object.keys(day).forEach((date) => (acc[date] = day[date]))
        return acc
      }, {})
    }),
  createAvailability: (providerId, data) =>
    api
      .post(`/providers/${providerId}/availabilities`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  createAvailabilityRange: (providerId, data) =>
    api
      .post(`/providers/${providerId}/availabilities/range`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  removeAvailability: (providerId, availabilityId) =>
    api
      .delete(`/providers/${providerId}/availabilities/${availabilityId}`)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  editAvailability: (providerId, availabilityId, data) =>
    api
      .patch(`/providers/${providerId}/availabilities/${availabilityId}`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  availabilitySummary: (query) =>
    api
      .get(QS.stringifyUrl({ url: '/providers/availabilities_summary', query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  // Admin Time
  adminTimes: (providerId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/providers/${providerId}/admin_times`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  createAdminTime: (providerId, data) =>
    api.post(`/providers/${providerId}/admin_times`, data).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  editAdminTime: (providerId, timeId, data) =>
    api
      .patch(`/providers/${providerId}/admin_times/${timeId}`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  removeAdminTime: (providerId, timeId) =>
    api
      .delete(`/providers/${providerId}/admin_times/${timeId}`)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
}

export default ProvidersApi
