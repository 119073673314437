import { useState } from 'react'
import copyToClipboard from 'clipboard-copy'

import { Tooltip } from '@mui-components'

export default function CopyToClipboard({ children }) {
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const handleCopy = (content) => {
    return copyToClipboard(content).then(() => {
      setTooltipOpen(true)
      setTimeout(() => setTooltipOpen(false), 1000)
    })
  }

  return (
    <Tooltip open={tooltipOpen} title="Copied to Clipboard">
      <span>{children({ copy: handleCopy })}</span>
    </Tooltip>
  )
}
