import { Skeleton, Stack, Typography } from '@mui-components'

import { Section } from '../CBORuleSet.utils'

export default function Contacts({ data, isLoading }) {
  return (
    <Section paper title="Contacts">
      {isLoading && <Contact.Loading />}
      <Stack spacing={1}>
        {data?.contacts?.map((contact, i) => (
          <Contact key={i} {...contact} />
        ))}
      </Stack>
    </Section>
  )
}

function Contact({ name, email, title }) {
  return (
    <Stack direction="row" spacing={1}>
      <Typography noWrap fontWeight="bold" sx={{ width: 200 }}>
        {name}
      </Typography>
      <Typography noWrap sx={{ width: 200 }}>
        {email}
      </Typography>
      {title && <Typography>{title}</Typography>}
    </Stack>
  )
}

Contact.Loading = function () {
  return (
    <Stack direction="row" spacing={1}>
      <Typography sx={{ width: 200 }}>
        <Skeleton width={120} />
      </Typography>
      <Typography sx={{ width: 200 }}>
        <Skeleton width={150} />
      </Typography>
      <Typography>
        <Skeleton width={100} />
      </Typography>
    </Stack>
  )
}
