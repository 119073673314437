import { useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { DesktopTimePicker } from '@mui/x-date-pickers-pro'

import usePromiseLoading from '@shared/hooks/src/usePromiseLoading'
import { handleError, includesOneOfErrorMessages } from '@shared/utils'

import { CloseOutlinedIcon, DeleteOutlinedIcon, PlusOutlinedIcon } from '@icons'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from '@mui-components'

import { useAppointments } from './AvailableItems.hooks'
import { appointmentErrors, parseBlackoutOverlap, validationSchema } from './AvailableItems.utils'

export default function AvailableItems({ dateRange, disabled, loading, availability = [], onAdd, onEdit, onRemove }) {
  const [isCreating, setIsCreating] = useState(false)

  const handleEdit = ({ id, start, end }) => onEdit(id, [start, end])
  const handleAdd = ({ start, end }) => onAdd([start, end]).then(() => setIsCreating(false))

  const title = useMemo(() => {
    const [startDate, endDate] = dateRange
    if (startDate?.isSame(endDate, 'day')) return startDate?.format('ll')
    return [startDate?.format('ll') ?? ' ? ', endDate?.format('ll') ?? ' ? '].join(' - ')
  }, [dateRange])

  return (
    <Stack spacing={3} sx={{ width: '100%' }}>
      <Typography align="center">Available times</Typography>
      <Stack direction="row" justifyContent="space-between" sx={{ width: '100%', minHeight: 50 }} spacing={1}>
        <Typography variant="h5" noWrap sx={{ position: 'relative', top: 8 }}>
          {title}
        </Typography>
        <Stack spacing={1}>
          {loading && <Item.Loading />}
          {availability.map((availability) => (
            <Item key={availability.id} dateRange={dateRange} data={availability} onRemove={onRemove} onSubmit={handleEdit} />
          ))}
          {isCreating && (
            <Item create dateRange={dateRange} data={{ id: 'new' }} onSubmit={handleAdd} onCancel={() => setIsCreating(false)} />
          )}
        </Stack>
        <IconButton
          disabled={disabled}
          variant="outlined"
          color="secondary"
          size="small"
          onClick={() => setIsCreating((o) => !o)}
          sx={{ top: 6 }}
        >
          <PlusOutlinedIcon />
        </IconButton>
      </Stack>
    </Stack>
  )
}

function Item({ create = false, dateRange, data, onRemove, onSubmit, onCancel }) {
  const [isNotice, setIsNotice] = useState(false)

  const [handleRemove, removing] = usePromiseLoading((id) => {
    return onRemove(id).catch((e) => {
      if (includesOneOfErrorMessages(e, appointmentErrors)) {
        setIsNotice(true)
      } else if (includesOneOfErrorMessages(e, ['Availability is in the past'])) {
        toast.error('Sorry, changes to past availabilities are restricted.')
      } else {
        handleError(e)
      }
    })
  })

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      id: data.id,
      start: data.start ? dayjs(data.start).tz(window.timezone) : null,
      end: data.end ? dayjs(data.end).tz(window.timezone) : null,
    },
    validationSchema,
    onSubmit: (values, { setFieldError }) => {
      const { id, start, end } = values
      return onSubmit({ id, start: start.format('HH:mm'), end: end.format('HH:mm') }).catch((e) => {
        if (includesOneOfErrorMessages(e, ['Date time range is overlapping with other availability'])) {
          return setFieldError('range', 'You already have availability for these times')
        }
        if (includesOneOfErrorMessages(e, appointmentErrors)) {
          if (!create) formik.resetForm()
          return setIsNotice(true)
        }
        if (
          includesOneOfErrorMessages(e, ['Date time range is outside of business hours', 'There are availabilities outside business hours'])
        ) {
          return setFieldError('range', 'Availability must be within business hours')
        }
        if (includesOneOfErrorMessages(e, ['Date time range is overlapping with Blackout Period'])) {
          return setFieldError('range', `Availability cannot overlap with Blackout Period. ${parseBlackoutOverlap(e)}`)
        }
        if (includesOneOfErrorMessages(e, ['Given range is fully within a blackout period'])) {
          return setFieldError('range', 'Availability cannot be within Blackout Period')
        }
        if (includesOneOfErrorMessages(e, ['Max hours allowed per week exceeded'])) {
          return setFieldError('range', 'You have reached the maximum allowed working hours per week')
        }
        if (includesOneOfErrorMessages(e, ['Availability is in the past'])) {
          return setFieldError('range', 'Changes to past availabilities are restricted')
        }
        if (includesOneOfErrorMessages(e, ['Availability cannot be scheduled more than 8 weeks ahead'])) {
          return setFieldError('range', 'Cannot schedule beyond 8 weeks')
        }
        handleError(e)
      })
    },
  })

  const handleBlur = async (e) => {
    formik.handleBlur(e)
    // try to submit form on blur when values are present
    if (dayjs.isDayjs(formik.values.start) && dayjs.isDayjs(formik.values.end)) {
      formik.handleSubmit()
    }
  }

  const handleCancel = () => {
    formik.resetForm()
    onCancel()
  }

  return (
    <Stack>
      <AppointmentNotice dateRange={dateRange} time={formik.values} open={isNotice} onClose={() => setIsNotice(false)} />
      <Stack key={data.id} direction="row" spacing={1}>
        <DesktopTimePicker
          disableOpenPicker
          label="Start"
          value={formik.values.start}
          onChange={(start) => formik.setFieldValue('start', start)}
          disabled={formik.isSubmitting}
          slotProps={{
            textField: {
              id: 'start',
              name: 'start',
              onBlur: handleBlur,
              error: (formik.touched.start && Boolean(formik.errors.start)) || Boolean(formik.errors.range),
              helperText: formik.touched.start && formik.errors.start,
            },
          }}
          sx={{ width: 120 }}
        />
        <Typography sx={{ position: 'relative', top: 8 }}>-</Typography>
        <DesktopTimePicker
          disableOpenPicker
          label="End"
          value={formik.values.end}
          onChange={(end) => formik.setFieldValue('end', end)}
          disabled={formik.isSubmitting}
          slotProps={{
            textField: {
              id: 'end',
              name: 'end',
              onBlur: handleBlur,
              error: (formik.touched.end && Boolean(formik.errors.end)) || Boolean(formik.errors.range),
              helperText: formik.touched.end && formik.errors.end,
            },
          }}
          sx={{ width: 120 }}
        />
        {create ? (
          <IconButton
            shape="rounded"
            variant="outlined"
            color="secondary"
            onClick={handleCancel}
            disabled={formik.isSubmitting}
            sx={{ top: 4 }}
          >
            <CloseOutlinedIcon />
          </IconButton>
        ) : (
          <IconButton
            shape="rounded"
            variant="outlined"
            color="secondary"
            onClick={() => handleRemove(data.id)}
            disabled={removing || formik.isSubmitting}
            sx={{ top: 4 }}
          >
            <DeleteOutlinedIcon />
          </IconButton>
        )}
      </Stack>
      {formik.errors.range && <FormHelperText error>{formik.errors.range}</FormHelperText>}
    </Stack>
  )
}

Item.Loading = function () {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Skeleton width={120} height={44} sx={{ transform: 'none' }} />
      <Typography>-</Typography>
      <Skeleton width={120} height={44} sx={{ transform: 'none' }} />
      <Skeleton variant="circular" width={34} height={34} />
    </Stack>
  )
}

export function AppointmentNotice({ dateRange, time, open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Appointment scheduled</DialogTitle>
      <DialogContent dividers>
        <AppointmentNoticeContent dateRange={dateRange} time={time} />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} variant="contained">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function AppointmentNoticeContent({ dateRange, time }) {
  const { data, isPending } = useAppointments(dateRange, time)

  return (
    <Typography sx={{ whiteSpace: 'pre-line' }}>
      There are currently appointments scheduled that require availability during the time that would be removed.
      <br />
      <br />
      Appointments:
      {isPending ? (
        <>
          <Skeleton width={120} />
          <Skeleton width={100} />
        </>
      ) : (
        (data || []).map((a) => (
          <Typography key={a.id} component="li">
            {dayjs(a.start).tz(window.timezone).format('MMMM D @ LT')}
          </Typography>
        ))
      )}
    </Typography>
  )
}
