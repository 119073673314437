import useQuery from '@shared/hooks/src/useQuery'
import PatientsSharedApi, { PatientInsurancesKeys } from '@shared/services/src/Patients.api'

export function useInsurance(patientId, insuranceId) {
  return useQuery({
    queryKey: PatientInsurancesKeys.insurance(patientId, insuranceId),
    queryFn: () => PatientsSharedApi.insurance(patientId, insuranceId, { skipHandling: true }),
    enabled: Boolean(patientId) && Boolean(insuranceId),
  })
}
