import { useState } from 'react'
import toast from 'react-hot-toast'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { DesktopTimePicker } from '@mui/x-date-pickers-pro'

import usePromiseLoading from '@shared/hooks/src/usePromiseLoading'
import { handleError, includesOneOfErrorMessages } from '@shared/utils'

import { DeleteOutlinedIcon } from '@icons'
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  IconButton,
  LoadingButton,
  Stack,
  Typography,
} from '@mui-components'
import LinearProgress from '@components/LinearProgress'

import { appointmentErrors, AppointmentNotice, validationSchema } from '../../AvailableItems'
import {
  useAdminTimeCreate,
  useAdminTimeEdit,
  useAdminTimeRemove,
  useAvailabilityCreate,
  useAvailabilityEdit,
  useAvailabilityRemove,
} from '../../Edit/Edit.hooks'

export default function AvailabilityUpdate({ availability, open, onClose }) {
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={(e, reason) => {
        if (['escapeKeyDown', 'backdropClick'].includes(reason)) return
        onClose()
      }}
    >
      <Content availability={availability} onClose={onClose} />
    </Dialog>
  )
}

function Content({ availability, onClose }) {
  const isEditingExistingAvailability = Boolean(availability?.id)
  const [isNotice, setIsNotice] = useState(false)

  const createAvailability = useAvailabilityCreate()
  const editAvailability = useAvailabilityEdit()
  const removeAvailability = useAvailabilityRemove()

  const createAdminTime = useAdminTimeCreate()
  const editAdminTime = useAdminTimeEdit()
  const removeAdminTime = useAdminTimeRemove()

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      id: availability?.id ?? null,
      type: availability?.type,
      start: availability?.start ? dayjs(availability.start) : null,
      end: availability?.end ? dayjs(availability.end) : null,
    },
    validationSchema,
    onSubmit: ({ id, type, start, end }, { setFieldError }) => {
      return update({ id, type, start: start.format('HH:mm'), end: end.format('HH:mm') }).catch((e) => {
        if (includesOneOfErrorMessages(e, ['Date time range is overlapping with other availability'])) {
          return setFieldError('range', 'You already have availability for these times')
        }
        if (includesOneOfErrorMessages(e, appointmentErrors)) {
          // Reset to original values if editing
          if (isEditingExistingAvailability) formik.resetForm()
          return setIsNotice(true)
        }
        if (
          includesOneOfErrorMessages(e, ['Date time range is outside of business hours', 'There are availabilities outside business hours'])
        ) {
          return setFieldError('range', 'Availability must be within business hours')
        }
        if (includesOneOfErrorMessages(e, ['Date time range is overlapping with Blackout Period'])) {
          return setFieldError('range', 'Availability cannot overlap with Blackout Period')
        }
        if (includesOneOfErrorMessages(e, ['Given range is fully within a blackout period'])) {
          return setFieldError('range', 'Availability cannot be within Blackout Period')
        }
        if (includesOneOfErrorMessages(e, ['Max hours allowed per week exceeded'])) {
          return setFieldError('range', 'You have reached the maximum allowed working hours per week')
        }
        if (includesOneOfErrorMessages(e, ['Availability is in the past'])) {
          return setFieldError('range', 'Changes to past availabilities are restricted')
        }
        if (includesOneOfErrorMessages(e, ['Availability cannot be scheduled more than 8 weeks ahead'])) {
          return setFieldError('range', 'Cannot schedule beyond 8 weeks')
        }
        handleError(e)
      })
    },
  })

  const update = ({ type, start, end }) => {
    let mutation
    if (type === 'availability') mutation = isEditingExistingAvailability ? editAvailability : createAvailability
    if (type === 'adminTime') mutation = isEditingExistingAvailability ? editAdminTime : createAdminTime

    return mutation({
      id: availability.id,
      date_formatted: dayjs(availability.start).format('YYYY-MM-DD'),
      start_time_formatted: start,
      end_time_formatted: end,
    }).then(onClose)
  }

  const [remove, removing] = usePromiseLoading(() => {
    let mutation
    if (availability.type === 'availability') mutation = removeAvailability
    if (availability.type === 'adminTime') mutation = removeAdminTime

    return mutation(availability.id)
      .then(() => {
        formik.resetForm()
        onClose()
      })
      .catch((e) => {
        if (includesOneOfErrorMessages(e, appointmentErrors)) {
          setIsNotice(true)
        } else if (includesOneOfErrorMessages(e, ['Availability is in the past'])) {
          toast.error('Sorry, changes to past availabilities are restricted.')
        } else {
          handleError(e)
        }
      })
  })

  const isLoading = formik.isSubmitting || removing

  return (
    <>
      <AppointmentNotice
        dateRange={[dayjs(availability?.start), dayjs(availability?.end)]}
        time={formik.values}
        open={isNotice}
        onClose={() => setIsNotice(false)}
      />
      <LinearProgress loading={isLoading} />
      <DialogTitle sx={{ textAlign: 'center' }}>
        <Typography component="span" variant="h3">
          {isEditingExistingAvailability
            ? `Edit ${availability.type === 'availability' ? 'Appointments' : 'Admin'} Availability`
            : 'Add Availability'}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="h4" align="center">
            {dayjs(availability?.start).format('L')}
          </Typography>
          <Stack key={availability?.id} direction="row" spacing={1}>
            <DesktopTimePicker
              disableOpenPicker
              label="Start"
              value={formik.values.start}
              onChange={(start) => formik.setFieldValue('start', start)}
              disabled={isLoading}
              slotProps={{
                textField: {
                  id: 'start',
                  name: 'start',
                  error: (formik.touched.start && Boolean(formik.errors.start)) || Boolean(formik.errors.range),
                  helperText: formik.touched.start && formik.errors.start,
                },
              }}
            />
            <Typography sx={{ position: 'relative', top: 8 }}>-</Typography>
            <DesktopTimePicker
              disableOpenPicker
              label="End"
              value={formik.values.end}
              onChange={(end) => formik.setFieldValue('end', end)}
              disabled={isLoading}
              slotProps={{
                textField: {
                  id: 'end',
                  name: 'end',
                  error: (formik.touched.end && Boolean(formik.errors.end)) || Boolean(formik.errors.range),
                  helperText: formik.touched.end && formik.errors.end,
                },
              }}
            />
          </Stack>
          <Collapse in={Boolean(formik.errors.range)} unmountOnExit>
            <FormHelperText error sx={{ textAlign: 'center' }}>
              {formik.errors.range}
            </FormHelperText>
          </Collapse>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%" spacing={1}>
          {isEditingExistingAvailability ? (
            <>
              <IconButton color="error" onClick={remove} disabled={isLoading}>
                <DeleteOutlinedIcon style={{ fontSize: 21 }} />
              </IconButton>
              <Stack direction="row" spacing={1}>
                <Button onClick={onClose} color="error" variant="text" disabled={isLoading}>
                  Cancel
                </Button>
                <LoadingButton
                  autoFocus
                  onClick={formik.handleSubmit}
                  color="primary"
                  variant="contained"
                  disabled={removing}
                  loading={formik.isSubmitting}
                >
                  Confirm
                </LoadingButton>
              </Stack>
            </>
          ) : (
            <>
              <Button onClick={onClose} color="error" variant="text" disabled={isLoading}>
                Cancel
              </Button>
              <Stack direction="row" spacing={1}>
                <LoadingButton
                  color="primary"
                  variant="outlined"
                  disabled={removing}
                  loading={formik.isSubmitting}
                  onClick={(e) => {
                    formik.setFieldValue('type', 'adminTime')
                    formik.handleSubmit(e)
                  }}
                >
                  Admin Time
                </LoadingButton>
                <LoadingButton
                  color="primary"
                  variant="contained"
                  disabled={removing}
                  loading={formik.isSubmitting}
                  onClick={(e) => {
                    formik.setFieldValue('type', 'availability')
                    formik.handleSubmit(e)
                  }}
                >
                  Appointments Time
                </LoadingButton>
              </Stack>
            </>
          )}
        </Stack>
      </DialogActions>
    </>
  )
}
