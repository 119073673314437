import { SvgIcon } from '@mui-components'

export default function AccessTimeOutlined(props) {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path fill="#fff" d="M0 0h16v16H0z" />
      <path
        d="M7.994 1.333A6.663 6.663 0 0 0 1.333 8c0 3.68 2.98 6.667 6.66 6.667A6.67 6.67 0 0 0 14.666 8a6.67 6.67 0 0 0-6.673-6.667zm.006 12A5.332 5.332 0 0 1 2.667 8 5.332 5.332 0 0 1 8 2.667 5.332 5.332 0 0 1 13.334 8 5.332 5.332 0 0 1 8 13.333zm.334-8.666h-1v4l3.5 2.1.5-.82-3-1.78v-3.5z"
        fill="#949696"
      />
    </SvgIcon>
  )
}
