import axios from 'axios'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'

import { Logger } from '../Logger'

const log = Logger('AuthUtils.js')

const AuthStorageKeys = {
  AuthToken: 'auth_token',
}

const initialize = () => {
  // ignore initial cookie check on local because
  // it is breaking authentication since we don't have a cookie
  if (import.meta.env.VITE_BUILD_ENV === 'local') return

  // Set the local storage token from the cookie on init
  const localStorageToken = localStorage.getItem(AuthStorageKeys.AuthToken)
  const cookieToken = Cookies.get(import.meta.env.VITE_COOKIE_NAME)
  if (localStorageToken === cookieToken) return

  // set directly to local storage
  if (cookieToken) {
    localStorage.setItem(AuthStorageKeys.AuthToken, cookieToken)
  } else {
    localStorage.removeItem(AuthStorageKeys.AuthToken)
  }
}

class AuthUtils {
  constructor() {
    initialize()
    this.listeners = []
  }

  onTokenChange(listener) {
    function handler({ key, newValue }) {
      if (key === AuthStorageKeys.AuthToken) {
        listener(newValue)
      }
    }

    this.listeners.push(listener)

    window.addEventListener('storage', handler)

    return {
      unsubscribe: () => {
        this.listeners = this.listeners.filter((l) => l !== listener)
        window.removeEventListener('storage', handler)
      },
    }
  }

  _notifyTokenChange() {
    const authToken = this.getAuthToken()
    this.listeners.forEach((listener) => listener(authToken))
  }

  getAuthToken() {
    return localStorage.getItem(AuthStorageKeys.AuthToken)
  }

  setAuthToken(token) {
    localStorage.setItem(AuthStorageKeys.AuthToken, token)
    this._notifyTokenChange()
  }

  clearAuthToken() {
    localStorage.removeItem(AuthStorageKeys.AuthToken)
    this._notifyTokenChange()
  }

  isAuthenticated() {
    return Boolean(this.getUserId())
  }

  /**
   * Logout the currently logged in user on this device.
   *
   * This will handle all the required actions to correctly logout the user.
   * It doesn't use existing axios client to avoid recursion and be independent of interceptors
   *
   * @returns {Promise<void>}
   */
  logout() {
    window.queryClient?.clear()
    window.honeybadger?.clear()
    window.honeybadger?.resetMaxErrors()

    const token = this.getAuthToken()
    if (!token) return Promise.resolve()

    const isAlreadyExpired = this.isTokenExpired()
    this.clearAuthToken()
    if (isAlreadyExpired) return Promise.resolve()

    return axios
      .post('auth/logout', undefined, {
        baseURL: import.meta.env.VITE_API_URL,
        withCredentials: true,
        headers: { Authorization: token },
      })
      .catch((error) => log.error('Logout error', error))
  }

  getUserId(token = this.getAuthToken()) {
    const decoded = this.decodeToken(token)
    return decoded?.user_id
  }

  isTokenExpired(token = this.getAuthToken()) {
    if (!token) return true

    const decoded = this.decodeToken(token)
    const expiry = decoded?.exp || 0

    // convert current time to UTC  and then to seconds
    const now = Date.now().valueOf() / 1000

    return now > expiry
  }

  decodeToken(token) {
    if (!token) return null

    try {
      return jwtDecode(token)
    } catch (error) {
      return null
    }
  }
}

const authUtils = new AuthUtils()

export default authUtils
