import { useMemo } from 'react'
import dayjs from 'dayjs'

import { Divider, ListItemText, MenuItem, Typography } from '@mui-components'

export default function DateRangeShortcuts({ value: selectedValue = [], onChange }) {
  const shortcuts = useMemo(() => {
    const today = dayjs()
    const prevWeek = today.subtract(7, 'day')
    const endOfPreviousMonth = today.startOf('month').subtract(1, 'day')

    return [
      { label: 'This Week', value: [today.startOf('week'), today.endOf('week')] },
      { label: 'This Month', value: [today.startOf('month'), today.endOf('month')] },
      { label: 'This Year', value: [today.startOf('year'), today.endOf('year')] },
      { divider: true },
      { label: 'Last 7 days', value: [prevWeek, today] },
      { label: 'Last Week', value: [prevWeek.startOf('week'), prevWeek.endOf('week')] },
      { label: 'Last Month', value: [endOfPreviousMonth.startOf('month'), endOfPreviousMonth] },
    ]
  }, [])

  const isSelected = ([start, end]) => Boolean(start.isSame(selectedValue[0], 'day') && end.isSame(selectedValue[1], 'day'))
  const handleValueChange = (newValue) => onChange(isSelected(newValue) ? [] : newValue)

  return (
    <>
      {shortcuts.map(({ divider = false, label, value }, index) => {
        if (divider) return <Divider key={index} />
        const selected = isSelected(value)

        return (
          <MenuItem key={index} selected={selected} onClick={() => handleValueChange(value)}>
            <ListItemText>{label}</ListItemText>
            <Typography variant="subtitle2" color="text.secondary">
              {value.map((date) => dayjs(date).format('MMM D')).join(' - ')}
            </Typography>
          </MenuItem>
        )
      })}
    </>
  )
}
