/**
 * Filter cache data based on predicate function;
 * Note: it maps over pages if data is paginated.
 *
 * @param predicate - filter function
 *
 * @example
 * queryClient.setQueriesData(
 *   { queryKey: ['queryKey'] },
 *   filterCache((item) => item.id !== id)
 * )
 */
export const filterCache = (predicate) => (data) => {
  if (!data) return data
  const filter = (_) => _.filter(predicate)
  if (data?.pages) return { ...data, pages: data.pages.map(filter) }
  return filter(data)
}

/**
 * Map over cache data based on iteratee function;
 * Note: it maps over pages if data is paginated.
 *
 * @param iteratee - map function
 * @example
 * queryClient.setQueriesData(
 *   { queryKey: ['queryKey'] },
 *   mapCache((item) => (item.id === id ? { ...item, ...data } : item))
 * )
 */
export const mapCache = (iteratee) => (data) => {
  if (!data) return data
  const map = (_) => _.map(iteratee)
  if (data?.pages) return { ...data, pages: data.pages.map(map) }
  return map(data)
}

/**
 * Map over cache data based map function;
 * Note: it maps over pages if data is paginated.
 *
 * @param mapFn - map function which applies to whole array or page array
 * @example
 * queryClient.setQueriesData(
 *  { queryKey: ['queryKey'] },
 *  mapCacheWithFn((list) => {
 *    const updated = list.map((a) => (a.id === time.id ? time : a))
 *    return [...updated].sort((a, b) => new Date(a.start) - new Date(b.start))
 *  })
 * )
 */
export const mapCacheWithFn = (mapFn) => (data) => {
  if (!data) return data
  if (data?.pages) return { ...data, pages: data.pages.map(mapFn) }
  return mapFn(data)
}

/**
 * Extend cache data with new item using add function;
 * Note: it flattens pages, add new item then split into pages again and return.
 *
 * @param addFn - add function which appends new item to list
 * @param LIMIT - page limit
 *
 * @example
 * queryClient.setQueriesData(
 *  { queryKey: ['queryKey'] },
 *  extendCache((list) => [appointment, ...list], LIMIT)
 * )
 */
export const extendCache = (addFn, LIMIT) => (data) => {
  if (!data) return data
  if (data?.pages) {
    const pages = data.pages.flat()
    const updated = addFn(pages)
    const newPages = []
    while (updated.length) newPages.push(updated.splice(0, LIMIT))
    return { ...data, pages: newPages }
  }
  return addFn(data)
}

/**
 * Get next page param based on limit
 *
 * @param limit - page limit
 *
 * @example
 * getNextPageParam: pageParam(NOTES_LIMIT),
 */
export const pageParam = (limit) => (lastPage, allPages) => (lastPage.length === limit ? allPages.length : undefined)

/**
 * Flatten pages array
 *
 * @example
 * select: flatten
 */
export const flatten = (data) => data.pages.flat()
