import React, { useState } from 'react'
import { styled, useTheme } from '@mui/material/styles'

import { CBORuleSetDetailsDialog } from '@pages/CBOs'
import { Box, Dialog, DialogContent, DialogTitle, Stack, Tooltip, Typography } from '@mui-components'
import USAMap from '@components/USAMap'

import useCBOsCoverage from './CBOsSupportMapDialog.hooks'

export default function CBOsSupportMapDialog({ open, onClose }) {
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>State Coverage Map</DialogTitle>
      <DialogContent dividers>
        <Content />
      </DialogContent>
    </Dialog>
  )
}

function Content() {
  const theme = useTheme()

  const [cboId, setCboId] = useState()
  const [open, setOpen] = useState(false)

  const { data } = useCBOsCoverage({
    select: (data = []) => {
      return data.reduce((acc, cbo) => {
        if (!cbo.acceptingNewPatients || !cbo.coveredCounties?.length) return acc

        cbo.coveredCounties.forEach(({ state, counties }) => {
          const isFullyCovered = !counties || counties.length === 0
          let fill = acc[state]?.fill || theme.palette.warning.light
          if (isFullyCovered) {
            fill = theme.palette.success.main
          }
          acc[state] = {
            fill,
            cbos: (acc[state]?.cbos || []).concat({
              id: cbo.id,
              name: cbo.name,
              isFullyCovered,
            }),
          }
        })

        return acc
      }, {})
    },
  })

  return (
    <Stack>
      <Stack direction="row" justifyContent="center" gap={3}>
        <LegendItem color="success.main">Fully covered</LegendItem>
        <LegendItem color="warning.light">Partially covered</LegendItem>
        <LegendItem color="error.light">Not covered</LegendItem>
      </Stack>
      <USAMap
        data={data || {}}
        defaultFill={theme.palette.error.light}
        Tooltip={({ abbreviation, ...props }) => (
          <StateTooltip
            state={data?.[abbreviation]}
            onClick={(cboId) => {
              setCboId(cboId)
              setOpen(true)
            }}
            {...props}
          />
        )}
      />
      <CBORuleSetDetailsDialog id={cboId} slide open={open} onClose={() => setOpen(false)} />
    </Stack>
  )
}

function LegendItem({ children, color }) {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Box
        sx={{
          width: 24,
          height: 24,
          borderRadius: 1,
          backgroundColor: color,
          border: '1px solid',
          borderColor: 'divider',
        }}
      />
      <Typography>{children}</Typography>
    </Stack>
  )
}

const StateTooltip = ({ state, onClick, ...props }) => {
  const hasCBOs = state?.cbos?.length > 0

  const title = hasCBOs
    ? state.cbos.map((cbo) => (
        <Typography key={cbo.id} component="a" onClick={() => onClick(cbo.id)} sx={{ cursor: 'pointer' }}>
          {`${cbo.name}${cbo.isFullyCovered ? '' : ' - Partially Covered'}`}
        </Typography>
      ))
    : undefined

  return <StyledTooltip title={title} {...props} />
}

const StyledTooltip = styled(({ className, children, ...props }) => (
  <Tooltip enterNextDelay={300} leaveDelay={300} {...props} classes={{ popper: className }}>
    {children}
  </Tooltip>
))({
  '& .MuiTooltip-tooltip': {
    maxWidth: 500,
    whiteSpace: 'pre-wrap',
    '& > .MuiTypography-root': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
})
