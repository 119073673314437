import { useLayoutEffect, useRef } from 'react'

// The Latest Ref Pattern
export function useLatestRef(callback) {
  const ref = useRef(callback)

  useLayoutEffect(() => {
    ref.current = callback
  })

  return ref
}
