import { useMutation } from '@tanstack/react-query'
import { useEffectOnce } from 'usehooks-ts'

import { isPermissionDenied } from '@shared/twilio/src/utils'

export default function useMicPermissions() {
  const { mutate, error, isPending, isSuccess, isError } = useMutation({
    mutationFn: async () => {
      await checkDeniedPermissions()
      await checkUserMediaPermissions()
    },
  })

  useEffectOnce(() => {
    mutate()
  })

  return { error, isPending, isSuccess, isError, isCompleted: isSuccess || isError }
}

const checkDeniedPermissions = async () => {
  const isMicrophonePermissionDenied = await isPermissionDenied('microphone')

  if (isMicrophonePermissionDenied) {
    throw new Error('MicrophonePermissionsDenied')
  }
}

export const checkUserMediaPermissions = async () => {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true })

  // Stop immediately to avoid the camera light turning on.
  stream.getTracks().forEach((track) => track.stop())
}
