import { generateText } from '@tiptap/core'

import { baseExtensions } from '@shared/messaging/src/RichTextHelper'
import { parseJsonSafe } from '@shared/utils'

import MentionExtension from './MentionExtension'

export const extensions = [...baseExtensions, MentionExtension]

export const getExtensions = ({ enableMentions = false } = {}) => [...baseExtensions, ...(enableMentions ? [MentionExtension] : [])]

/**
 * Method to convert JSON Object OR JSON String OR String to inline text
 *
 * @see RichText.test.js for usage examples
 */
export const convertPossibleRichTextToInlineText = (message) => {
  try {
    const json = parseJsonSafe(message)
    return generateText(json, extensions)
  } catch {
    return message
  }
}
