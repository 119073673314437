import { Box } from '@mui/material'

import { RoleGuard } from '@shared/providers/src/MeProvider'
import { UserRole } from '@shared/utils'

import { useMediaQuery } from '@mui-components'

import Availability from '../Availability'
import CBOSupportMap from './CBOSupportMap'
import Environment from './Environment'
import Notifications from './Notifications'
import PagesHistory from './PagesHistory'
import PhoneCall from './PhoneCall'
import Profile from './Profile'
import Search from './Search'
import Settings from './Settings'
import SOPs from './SOPs'

export default function Content() {
  const matchDownSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <>
      {!matchDownSm && <Search />}
      <PagesHistory />

      {/*{matchDownSm && <Box sx={{ width: '100%', ml: 1 }} />}*/}
      <Box sx={{ width: '100%', ml: 1 }} />

      {/* Display the current environment and user */}
      <Environment />

      <RoleGuard allowed={[UserRole.Admin, UserRole.MA, UserRole.Provider, UserRole.Support]}>
        <PhoneCall />
      </RoleGuard>
      <RoleGuard prohibited={[UserRole.CBO]}>
        <CBOSupportMap />
        <SOPs />
      </RoleGuard>
      <RoleGuard allowed={[UserRole.Provider]}>
        <Availability />
        <Settings />
      </RoleGuard>
      <Notifications />

      {/* User profile and menu */}
      {/*{matchDownSm ? <MobileSection /> : <Profile />}*/}
      <Profile />
    </>
  )
}
