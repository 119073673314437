import Timeline from '@mui/lab/Timeline'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'

import { Box, Typography } from '@mui-components'
import Avatar from '@components/Avatar'
import { styles } from '@components/TimelineNotes/TimelineNotes.utils'
import { appName } from '@config'

import Note from './Note'

export default function TimelineNotes({ children }) {
  return <Timeline sx={styles.container}>{children}</Timeline>
}

TimelineNotes.Item = Note

TimelineNotes.Loading = function ({ showSource = false }) {
  return (
    <TimelineNotes>
      <Note.Loading showSource={showSource} />
      <Note.Loading showSource={showSource} />
      <Note.Loading showSource={showSource} isLast />
    </TimelineNotes>
  )
}

TimelineNotes.Empty = function ({ readOnly = false }) {
  return (
    <TimelineNotes>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot variant="outlined" sx={styles.dot}>
            <Avatar.Company />
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent>
          <Typography variant="subtitle1" fontWeight="normal">
            <b>{appName}</b>
          </Typography>
          <Box sx={styles.message}>
            <Typography>{readOnly ? 'No notes found' : 'No notes found, feel free to add one!'}</Typography>
          </Box>
        </TimelineContent>
      </TimelineItem>
    </TimelineNotes>
  )
}
