import { Skeleton, Stack, Typography } from '@mui-components'
import Avatar from '@components/Avatar'

export default function Provider({ small = false, user, children }) {
  if (!user) return null

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Avatar user={user} size={small ? 'sm' : 'md'} hover="card" />
      <Stack spacing={-0.5}>
        <Typography variant={small ? 'body2' : 'body1'}>{user.name}</Typography>
        {children || (
          <Typography variant={small ? 'body2' : 'body1'} color="text.secondary">
            {[user.city, user.state].filter(Boolean).join(', ')}
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}

Provider.Loading = function ({ small }) {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Skeleton variant="rounded" width={small ? 32 : 40} height={small ? 32 : 40} />
      <Stack spacing={-0.5}>
        <Typography variant={small ? 'body2' : 'body1'}>
          <Skeleton width={150} />
        </Typography>
        <Typography variant={small ? 'body2' : 'body1'}>
          <Skeleton width={120} />
        </Typography>
      </Stack>
    </Stack>
  )
}
