import { useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import PropTypes from 'prop-types'

// load locales files
const loadLocaleData = (locale) => {
  switch (locale) {
    case 'en':
    default:
      return import('@utils/locales/en.json')
  }
}

export default function LocalesProvider({ children }) {
  const i18n = 'en'

  const [messages, setMessages] = useState()

  useEffect(() => {
    loadLocaleData('en').then((d) => {
      setMessages(d.default)
    })
  }, [i18n])

  return (
    <>
      {messages && (
        <IntlProvider locale={i18n} defaultLocale="en" messages={messages}>
          {children}
        </IntlProvider>
      )}
    </>
  )
}

LocalesProvider.propTypes = {
  children: PropTypes.node,
}
