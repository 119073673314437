import { useMemo } from 'react'

import useQuery from '@shared/hooks/src/useQuery'
import { useMe } from '@shared/providers/src/MeProvider'
import { AppointmentStatus } from '@shared/utils'

import AppointmentsApi, { ProviderAppointmentsKeys } from '@services/Appointments.api'

export function useAppointments(dateRange, { start, end }) {
  const me = useMe()

  const query = useMemo(() => {
    const [startDate, endDate] = dateRange
    return {
      start_date: `${startDate?.format('YYYY-MM-DD')}T${start.format('HH:mm')}`,
      end_date: `${endDate?.format('YYYY-MM-DD')}T${end.format('HH:mm')}`,
      appointment_status: AppointmentStatus.Scheduled,
    }
  }, [dateRange, start, end])

  return useQuery({
    queryKey: ProviderAppointmentsKeys.list(me.provider.id, query),
    queryFn: () => AppointmentsApi.appointments(me.provider.id, query),
  })
}
