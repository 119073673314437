import { useMutation } from '@tanstack/react-query'

import useQuery from '@shared/hooks/src/useQuery'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import CbosSharedApi, { CBOKeys as CBOSharedKeys } from '@shared/services/src/Cbos.api'
import { mapCache } from '@shared/utils'

import AdminCBOsApi, { AdminCBOsKeys } from '@services/AdminCBOs.api'
import CbosApi, { CBOKeys } from '@services/Cbos.api'

export function useCBO(cboId, options = {}, config) {
  return useQuery({
    queryKey: CBOSharedKeys.cbo(cboId),
    queryFn: () => CbosSharedApi.cbo(cboId, config),
    ...options,
  })
}

export function useCBOStatusUpdate() {
  return useMutation({
    mutationFn: ({ id, status }) => AdminCBOsApi.updateStatus(id, { status }),
    onSuccess: (response, { id, status }) => {
      return queryClient.setQueriesData(
        { queryKey: AdminCBOsKeys.lists() },
        mapCache((old) => (old.id === id ? { ...old, data: status } : old))
      )
    },
  })
}

export function useCBOUpdate(cboId) {
  return useMutation({
    mutationFn: (data) => CbosSharedApi.updateCBO(cboId, data),
    onSuccess: (data) => queryClient.setQueryData(CBOSharedKeys.cbo(cboId), data),
  })
}

export function useCBOContractedPharmacies(cboId, query, options = {}) {
  return useQuery({
    queryKey: CBOKeys.contractedPharmacies(cboId, query),
    queryFn: () => CbosApi.contractedPharmacies(cboId, query),
    ...options,
  })
}
