import Grid from '@mui/material/Unstable_Grid2'

import { Skeleton, Stack, Typography } from '@mui-components'
import Property from '@components/Property'

import { Section } from '../CBORuleSet.utils'

export default function PatientCare({ ruleSets, ruleSetsAreLoading, cbo, cboIsLoading }) {
  return (
    <Section paper title="Patient Care">
      <Grid container spacing={1}>
        <Grid xs={4}>
          <Stack spacing={1}>
            <Property
              label="Q Care Plus Services"
              value={
                cboIsLoading ? (
                  <>
                    <Skeleton width={60} />
                    <Skeleton width={80} />
                  </>
                ) : (
                  <Stack>
                    {!cbo?.cbosProviderTypes?.length && <Typography>-</Typography>}
                    {cbo?.cbosProviderTypes?.map(({ id, name }) => (
                      <Typography key={id}>{name}</Typography>
                    ))}
                  </Stack>
                )
              }
            />
            <Property
              label="Lab Vendors Available"
              value={
                cboIsLoading ? (
                  <>
                    <Skeleton width={60} />
                    <Skeleton width={80} />
                  </>
                ) : (
                  <Stack>
                    {!cbo?.labVendors?.length && <Typography>-</Typography>}
                    {cbo?.labVendors?.map(({ id, name }) => (
                      <Typography key={id}>{name}</Typography>
                    ))}
                  </Stack>
                )
              }
            />
          </Stack>
        </Grid>
        <Grid xs={4}>
          <Property label="STI Reporting" value={ruleSetsAreLoading ? <Skeleton width={100} /> : ruleSets?.patientCare?.stiReporting} />
        </Grid>
        <Grid xs={4}>
          <Stack spacing={1}>
            <Property label="pos. Chlamydia" value={ruleSetsAreLoading ? <Skeleton width={100} /> : ruleSets?.patientCare?.posChlamydia} />
            <Property label="pos. Gonorrhea" value={ruleSetsAreLoading ? <Skeleton width={100} /> : ruleSets?.patientCare?.posGonorrhea} />
            <Property label="pos. Syphilis" value={ruleSetsAreLoading ? <Skeleton width={100} /> : ruleSets?.patientCare?.posSyphilis} />
            <Property label="pos. HIV" value={ruleSetsAreLoading ? <Skeleton width={100} /> : ruleSets?.patientCare?.posHIV} />
          </Stack>
        </Grid>
      </Grid>
    </Section>
  )
}
