import { isMobileSafari, isSafari } from 'react-device-detect'
import dayjs from 'dayjs'
import EXIF from 'exif-js'

/**
 * Converts blob file into base64 string
 *
 * @param {Blob} file
 * @returns {Promise<string>} base64 string
 */
export const toBase64 = (file) => {
  if (typeof FileReader === 'undefined') {
    return Promise.reject(new Error('Your browser does not support FileReader API'))
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

/**
 * Gets a file as an array buffer
 *
 * @param {Blob} file
 * @return {Promise<ArrayBuffer>}
 */
export const getFileArrayBuffer = (file) => {
  if (typeof FileReader === 'undefined') {
    return Promise.reject(new Error('Your browser does not support FileReader API'))
  }

  return new Promise(function (resolve, reject) {
    const reader = new FileReader()
    reader.onload = function () {
      resolve(reader.result)
    }
    reader.readAsArrayBuffer(file)
  })
}

/**
 * Gets a Base64 as binary array buffer
 *
 * @param {String} file
 * @return {ArrayBuffer}
 */
export const getBase64BinaryArrayBuffer = (file) => {
  const base64 = file.replace(/^data:([^;]+);base64,/gim, '')
  const binaryString = atob(base64)
  const len = binaryString.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes.buffer
}

/**
 * Convert a base64 string in a Blob according to the data and mimeType.
 * @param {string} base64Data - The base64 string data.
 * @param {string} mimeType - The mime type of the file i.e (image/jpeg - image/png - text/plain).
 */
export function base64toBlob(base64Data, mimeType) {
  const sliceSize = 1024
  const byteCharacters = atob(base64Data)
  const bytesLength = byteCharacters.length
  const slicesCount = Math.ceil(bytesLength / sliceSize)
  const byteArrays = new Array(slicesCount)

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize
    const end = Math.min(begin + sliceSize, bytesLength)

    const bytes = new Array(end - begin)
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0)
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes)
  }
  return new Blob(byteArrays, { type: mimeType })
}

/**
 * Opens blob file in new tab
 *
 * @param blob - blob file
 * @param filename - file name
 */
export function viewBlob(blob, filename = `file ${dayjs().format('L LT')}`) {
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename)
  } else {
    const blobUrl = createObjectURL(blob)

    if (!isSafari && !isMobileSafari) {
      window.open(blobUrl)
      return
    }

    // download file instead if it is safari

    const tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = blobUrl
    tempLink.setAttribute('download', filename)

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank')
    }

    document.body.appendChild(tempLink)
    tempLink.click()

    // Fixes "webkit blob resource error 1"
    setTimeout(function () {
      document.body.removeChild(tempLink)
      window.URL.revokeObjectURL(blobUrl)
    }, 200)
  }
}

/**
 * Opens base64 file in new tab
 *
 * @param content - the base64 content of the file
 * @param mimetype - the mime type of the file i.e (image/jpeg - image/png - text/plain)
 * @param filename - file name
 */
export function viewBase64(content, mimetype, filename) {
  const blob = base64toBlob(content, mimetype)
  viewBlob(blob, filename)
}

/**
 * Get the mime type of a base64 file. This uses the embedded mime type in the base64 string.
 *
 * @param content - the base64 content of the file
 */
export function getBase64MimeType(content) {
  if (!content) {
    throw new TypeError('getBase64MimeType requires a valid base64 file string for the "content" argument')
  }
  return content.split(';')[0].split('/')[1]
}

/**
 * Get EXIF data from file
 * Returns false if no data
 *
 * @param {Blob} file
 * @return {Promise<Boolean | Object>}
 */
export const getExifDataFromFile = async (file) => {
  const fileBuffer = await getFileArrayBuffer(file)

  return EXIF.readFromBinaryFile(fileBuffer)
}

/**
 * Get EXIF data from base64
 * Returns false if no data
 *
 * @param {String} file
 * @return {Boolean|Object}
 */
export const getExifDataFromBase64 = (file) => {
  const fileBuffer = getBase64BinaryArrayBuffer(file)

  return EXIF.readFromBinaryFile(fileBuffer)
}

/**
 * Get EXIF data orientation from file
 *
 * @param {Blob} file
 * @return {Promise<Object>}
 */
export const getRotationAngleFromFile = async (file) => {
  const exifData = await getExifDataFromFile(file)

  return getRotationAngle(exifData)
}

/**
 * Get EXIF data orientation from base64
 *
 * @param {String} file
 * @return {Object}
 */
export const getRotationAngleFromBase64 = (file) => {
  const exifData = getExifDataFromBase64(file)

  return getRotationAngle(exifData)
}

/**
 * Get angle rotation and mirror from exif data if any
 *
 * @param {Boolean|Object} exifData
 * @return {{mirror: boolean, angle: number}}
 */
const getRotationAngle = (exifData) => {
  if (!exifData || !exifData.Orientation) {
    return { mirror: false, angle: 0 }
  }

  let angle
  switch (exifData.Orientation) {
    case 3:
    case 4:
      angle = 180
      break
    case 5:
    case 6:
      angle = 90
      break
    case 7:
    case 8:
      angle = 270
      break
    default:
      angle = 0
      break
  }

  return { mirror: 0 === exifData.Orientation % 2, angle }
}

/**
 * Format bytes to human-readable format
 */
export const prettyBytes = (bytes) => {
  if (isNaN(bytes)) {
    throw new TypeError('Expected a number')
  }

  const exponent = Math.floor(Math.log(bytes) / Math.log(1000))
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const n = Number(bytes / Math.pow(1000, exponent)).toPrecision(3)
  return n + units[exponent]
}

/**
 * Limit the size of image by 4096 * 4096 pixels if it exceeds
 * because of canvas limitations on mobile devices
 *
 * @param width - width of image
 * @param height - height of image
 *
 * @returns {{ width: number, height: number, scalar: number }}
 */
export function limitImageSize({ width, height }) {
  const maximumPixels = 4096 * 4096

  const originalPixels = width * height
  if (originalPixels <= maximumPixels) return { width, height, scalar: 1 }

  const scalar = Math.sqrt(maximumPixels) / Math.sqrt(originalPixels)
  return {
    width: Math.floor(width * scalar),
    height: Math.floor(height * scalar),
    scalar,
  }
}

/**
 * Download file from browser
 *
 * @example
 * api.get(`/export`, { responseType: 'blob' }).then(() => {
 *    downloadFile(response.data, 'export.csv')
 * })
 */
export function downloadFile(data, filename = `file ${dayjs().format('L LT')}`, mime) {
  const blobData = [data]
  const blob = new Blob(blobData, { type: mime || 'application/octet-stream' })
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename)
  } else {
    const blobURL = createObjectURL(blob)
    const tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = blobURL
    tempLink.setAttribute('download', filename)

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank')
    }

    document.body.appendChild(tempLink)
    tempLink.click()

    // Fixes "webkit blob resource error 1"
    setTimeout(function () {
      document.body.removeChild(tempLink)
      window.URL.revokeObjectURL(blobURL)
    }, 200)
  }
}

const createObjectURL = (blob) =>
  window.URL && window.URL.createObjectURL ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob)
