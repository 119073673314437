import { Helmet } from 'react-helmet'

import { appName } from '@config'

export default function DocTitle({ title = appName }) {
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  )
}
