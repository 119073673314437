import PubSub from 'pubsub-js'
import { useLocalStorage } from 'usehooks-ts'

import AudioLevelIndicator from '@shared/twilio/src/components/AudioLevelIndicator'
import useDevices from '@shared/twilio/src/hooks/useDevices'
import useMediaStreamTrack from '@shared/twilio/src/hooks/useMediaStreamTrack'
import { useTracksContext } from '@shared/twilio/src/providers/TracksProvider'
import { SELECTED_AUDIO_INPUT_KEY } from '@shared/twilio/src/utils'

import { FormControl, MenuItem, Select, Stack, Typography } from '@mui-components'

export default function AudioInputList() {
  const { audioInputDevices } = useDevices()
  const { localTracks } = useTracksContext()
  const [storedLocalAudioInputDeviceId, setStoredLocalAudioInputDeviceId] = useLocalStorage(SELECTED_AUDIO_INPUT_KEY, '')

  const localAudioTrack = localTracks.find((track) => track.kind === 'audio')
  const mediaStreamTrack = useMediaStreamTrack(localAudioTrack)
  const localAudioInputDeviceId = mediaStreamTrack?.getSettings().deviceId || storedLocalAudioInputDeviceId

  function replaceTrack(newDeviceId) {
    setStoredLocalAudioInputDeviceId(newDeviceId)
    PubSub.publish(SELECTED_AUDIO_INPUT_KEY, newDeviceId)
  }

  return (
    <div>
      <Typography gutterBottom color="text.secondary">
        Microphone
      </Typography>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        {audioInputDevices.length > 1 ? (
          <FormControl fullWidth>
            <Select fullWidth onChange={(e) => replaceTrack(e.target.value)} value={localAudioInputDeviceId || ''} variant="outlined">
              {audioInputDevices.map((device) => (
                <MenuItem value={device.deviceId} key={device.deviceId}>
                  {device.label || device.deviceId}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <Typography>{localAudioTrack?.mediaStreamTrack.label || 'No Local Audio'}</Typography>
        )}
        <AudioLevelIndicator audioTrack={localAudioTrack} color="black" />
      </Stack>
    </div>
  )
}
