import { EditorContent, useEditor } from '@tiptap/react'

import usePromiseLoading from '@shared/hooks/src/usePromiseLoading'
import { clipboardTextParser, richTextStyles } from '@shared/messaging/src/RichTextHelper'

import { outlineRichTextEditorStyling } from '@utils/StylesHelper'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, LoadingButton, Stack, Typography } from '@mui-components'
import { getExtensions } from '@components/RichText'

export default function PhotoIdReminderDialog({ open, onClose, name, onSubmit }) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Content name={name} onClose={onClose} onSubmit={onSubmit} />
    </Dialog>
  )
}

function Content({ name, onClose, onSubmit }) {
  const editor = useEditor({
    extensions: getExtensions(),
    content: getDefaultMessage(name),
    autofocus: 'end',
    editorProps: { clipboardTextParser },
  })

  const [handleSend, loading] = usePromiseLoading(() => {
    const message = editor.getJSON()
    return onSubmit(JSON.stringify(message))
  })

  return (
    <>
      <DialogTitle>ID Photo Reminder</DialogTitle>
      <DialogContent dividers>
        <Stack spacing={1}>
          <Typography variant="h4">Send a reminder message to the patient to upload their id photo.</Typography>
          <Box sx={[outlineRichTextEditorStyling, richTextStyles, { '& .tiptap': { minHeight: 150, maxHeight: 400 } }]}>
            <EditorContent editor={editor} />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error">
          Cancel
        </Button>
        <LoadingButton loading={loading} disabled={editor.isEmpty} variant={editor.isEmpty ? 'text' : 'contained'} onClick={handleSend}>
          Send Message
        </LoadingButton>
      </DialogActions>
    </>
  )
}

const getDefaultMessage = (name) => ({
  type: 'doc',
  content: [
    {
      type: 'paragraph',
      content: [{ type: 'text', text: `Hi ${name}!` }],
    },
    { type: 'paragraph' },
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: "Telehealth regulations require that we have your photo ID on file prior to your scheduled appointment. If you could, please upload your photo ID that clearly shows your picture, name, and date of birth (passport, driver's license, or other form of government ID). We're excited to provide your care, but may need to pause or reschedule until your photo ID is uploaded.",
        },
      ],
    },
    { type: 'paragraph' },
    { type: 'paragraph', content: [{ type: 'text', text: 'Instructions:' }] },
    {
      type: 'orderedList',
      attrs: { start: 1, type: null },
      content: [
        {
          type: 'listItem',
          content: [{ type: 'paragraph', content: [{ type: 'text', text: 'Please log in to your account.' }] }],
        },
        {
          type: 'listItem',
          content: [
            {
              type: 'paragraph',
              content: [
                {
                  type: 'text',
                  text: 'Once logged in, click the three lines in the upper right-hand corner to open the menu and click "My Profile."',
                },
              ],
            },
          ],
        },
        {
          type: 'listItem',
          content: [
            {
              type: 'paragraph',
              content: [{ type: 'text', text: 'Once there, you should see the option to upload your ID at the bottom of the page.' }],
            },
          ],
        },
      ],
    },
  ],
})
