import { useLayoutEffect, useRef, useState } from 'react'

/**
 * Prevents flickering when loading data from multiple sources.
 *
 * Example:
 * const isLoading = isLoading1 || isLoading2 || isLoading3
 * isLoading can change like this in some cases: false ->true -> false -> true -> false
 *
 * the hook will prevent the flickering by delaying the change of isLoading to false
 */
export default function useLoadingState(condition, delay = 50) {
  const [isLoading, setIsLoading] = useState(true)
  const timeoutId = useRef(null)

  useLayoutEffect(() => {
    if (condition) {
      setIsLoading(true)
      if (timeoutId.current !== null) {
        clearTimeout(timeoutId.current)
      }
    } else {
      timeoutId.current = setTimeout(() => {
        setIsLoading(false)
      }, delay)
    }
  }, [condition, delay])

  return isLoading
}
