import toast from 'react-hot-toast'
import { useTheme } from '@mui/material/styles'

import useDialog from '@shared/hooks/src/useDialog'
import { isUserAllowed, useMe } from '@shared/providers/src/MeProvider'
import { UserRole } from '@shared/utils'

import { DeleteOutlinedIcon, EditOutlinedIcon, FileAddOutlinedIcon, LinkOutlinedIcon } from '@icons'
import { IconButton, Stack } from '@mui-components'
import CopyToClipboard from '@components/CopyToClipboard'
import Confirmation from '@components/Dialog/Confirmation'

const styles = {
  header: {
    backgroundColor: 'primary.100',
    borderRadius: 1,
    border: '1px solid',
    borderColor: 'primary.300',
    px: 2,
    py: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 1,
  },
}

export default function Header({ data, onEdit, onDelete }) {
  const theme = useTheme()
  const me = useMe()

  const confirmRemove = useDialog({
    component: Confirmation,
    props: ({ close }) => {
      return {
        maxWidth: 'xs',
        title: 'Delete SOP',
        description: 'Are you sure you want to delete the SOP?',
        rejectLabel: 'Cancel',
        confirmLabel: 'Delete',
        onReject: () => close(),
        onConfirm: () => {
          return onDelete().then(() => {
            close()
            toast.success('SOP has been deleted')
          })
        },
      }
    },
  })

  const isEditor = isUserAllowed(me, [UserRole.Admin])

  return (
    <Stack direction="row" sx={styles.header}>
      {isEditor ? (
        <IconButton onClick={confirmRemove}>
          <DeleteOutlinedIcon style={{ color: theme.palette.error.main }} />
        </IconButton>
      ) : (
        <div />
      )}
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
        {isEditor && (
          <IconButton onClick={onEdit}>
            <EditOutlinedIcon style={{ color: theme.palette.text.primary }} />
          </IconButton>
        )}
        <CopyToClipboard>
          {({ copy }) => (
            <IconButton
              onClick={() => {
                const to = encodeURIComponent(`${import.meta.env.VITE_URL}/app?sopId=${data.id}&sops=view`)
                const url = `${import.meta.env.VITE_URL}/redirect?to=${to}`
                return copy(url)
              }}
            >
              <LinkOutlinedIcon style={{ color: theme.palette.text.primary }} />
            </IconButton>
          )}
        </CopyToClipboard>
      </Stack>
    </Stack>
  )
}

Header.Loading = function Loading() {
  const me = useMe()
  const isEditor = isUserAllowed(me, [UserRole.Admin])

  return (
    <Stack direction="row" sx={styles.header}>
      {isEditor ? (
        <IconButton disabled>
          <DeleteOutlinedIcon />
        </IconButton>
      ) : (
        <div />
      )}
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
        {isEditor && (
          <>
            <IconButton disabled>
              <FileAddOutlinedIcon />
            </IconButton>
            <IconButton disabled>
              <EditOutlinedIcon />
            </IconButton>
          </>
        )}
        <IconButton disabled>
          <LinkOutlinedIcon />
        </IconButton>
      </Stack>
    </Stack>
  )
}
