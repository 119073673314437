import SvgIcon from '@mui/material/SvgIcon'

export default function LoaderIcon(props) {
  return (
    <SvgIcon width="180" height="136" viewBox="0 0 180 136" fill="none" sx={{ width: 'auto', height: 'auto' }} {...props}>
      <g clipPath="url(#q95w3i8fxa)">
        <path
          d="m130.736 120.347-21.214-21.289a9.077 9.077 0 0 0-6.135-2.67c-.109-.003-.218-.016-.327-.016a9.118 9.118 0 0 0-3.106.556 9.018 9.018 0 0 0-2.46 1.352c-.036.028-.073.05-.106.079-.355.35-.72.688-1.084 1.027-3.26 3.597-3.172 9.164.293 12.639l5.918 5.938 1.872 1.879 1.871 1.88 11.551 11.591a9.093 9.093 0 0 0 6.463 2.687 9.092 9.092 0 0 0 6.461-2.687c3.569-3.579 3.569-9.386.003-12.966z"
          fill="#45BAEB"
          opacity="0"
        >
          <animate
            attributeName="opacity"
            values="0;1;0"
            keyTimes="0;0.3;1"
            dur="1.5s"
            fill="freeze"
            restart="always"
            repeatCount="indefinite"
          />
        </path>
        <path
          d="M92.903 115.729A14.344 14.344 0 0 1 88.7 105.54c0-.142.016-.282.02-.423-6.945 4.205-15.12 6.664-24.015 6.664-.087 0-.175 0-.262-.002-.046 0-.09-.007-.137-.007-17.96-.151-34.092-10.969-41.568-26.824-.372-.792-.72-1.6-1.05-2.416a46.45 46.45 0 0 1-.92-2.456l-.106.033c-1.168-3.716-4.587-6.428-8.674-6.428-5.05 0-9.139 4.106-9.139 9.17 0 .94.183 1.828.444 2.68l-.013.004c.022.062.048.12.066.18.11.33.217.655.361.964.153.444.324.878.488 1.314.305.822.63 1.637.969 2.442 9.71 23.058 32.31 39.044 57.756 39.671l.188.001c.388 0 .771-.026 1.163-.026H64.58c12.376 0 23.78-3.365 33.473-9.182l-5.15-5.17z"
          fill="#DC258B"
          opacity="0"
        >
          <animate
            attributeName="opacity"
            values="0;1;0"
            keyTimes="0;0.3;1"
            dur="1.5s"
            fill="freeze"
            restart="always"
            repeatCount="indefinite"
          />
        </path>
        <path
          d="M65.913.014C65.825.01 65.738 0 65.647 0c-.099 0-.197.012-.295.026-.091.012-.181.026-.276.032V.032a64.66 64.66 0 0 0-2.642.086c-.885.043-1.766.105-2.642.182C22.45 3.615-4.53 37.27.634 74.061a14.546 14.546 0 0 1 18.14-4.007c-2.747-25.305 15.55-48.198 41.018-51.337a49.241 49.241 0 0 1 2.642-.254c.83-.055 1.662-.098 2.504-.112h.01c.041 0 .081.003.122.003h.006v-.072c.194.012.377.057.572.057 5.11 0 9.248-4.106 9.248-9.169 0-4.976-3.999-9.016-8.983-9.156z"
          fill="#f78f20"
          opacity="0"
        >
          <animate
            attributeName="opacity"
            values="0;1;0"
            keyTimes="0;0.3;1"
            dur="1.5s"
            fill="freeze"
            restart="always"
            repeatCount="indefinite"
          />
        </path>
        <path
          d="M75.804 19.459c17.52 3.895 31.905 17.683 35.824 35.595 1.486 6.792 1.41 13.49.043 19.774-.942 4.348 1.305 8.745 5.495 10.328l.218.083a9.394 9.394 0 0 0 3.333.613c4.233 0 8.119-2.897 9.062-7.264 2.035-9.433 1.985-19.535-.62-29.743-6.288-24.637-26.852-43.202-51.498-47.759a14.227 14.227 0 0 1 2.52 8.083c0 4.03-1.68 7.672-4.377 10.29z"
          fill="#8AC541"
          opacity="0"
        >
          <animate
            attributeName="opacity"
            values="0;1;0"
            keyTimes="0;0.3;1"
            dur="1.5s"
            fill="freeze"
            restart="always"
            repeatCount="indefinite"
          />
        </path>
        <path d="M161.328 46.274h-8.012V27.712H134.57v-7.89h18.746V1.18h8.012v18.641H180v7.89h-18.672v18.563z" fill="#636569" opacity="0">
          <animate
            attributeName="opacity"
            values="0;1;0"
            keyTimes="0;0.3;1"
            dur="1.5s"
            fill="freeze"
            restart="always"
            repeatCount="indefinite"
          />
        </path>
        <path d="M96.597 99.058c-.104.104-.196.22-.294.327.365-.338.73-.676 1.084-1.026a9.324 9.324 0 0 0-.79.699z" fill="#45BAEB" />
      </g>
      <defs>
        <clipPath id="q95w3i8fxa">
          <path fill="#fff" d="M0 0h180v136H0z" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}
