import CircularProgress from '@mui/material/CircularProgress'
import { styled, useTheme } from '@mui/material/styles'

import LoaderIcon from './LoaderIcon'

const LoaderWrapper = styled('div')({
  position: 'fixed',
  top: 0,
  bottom: 56,
  left: 42,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export default function Loader() {
  const theme = useTheme()

  if (theme.app?.default ?? true) {
    return (
      <LoaderWrapper>
        <LoaderIcon />
      </LoaderWrapper>
    )
  }

  return (
    <LoaderWrapper sx={{ inset: 0 }}>
      <CircularProgress />
    </LoaderWrapper>
  )
}
