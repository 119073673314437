import useDevices from '@shared/twilio/src/hooks/useDevices'
import { useMicPermission } from '@shared/twilio/src/hooks/useMediaPermissions'
import { getMediaErrorDescription } from '@shared/twilio/src/utils'
import { VoiceCallStatus } from '@shared/utils'

import { Box, Grow, Typography } from '@mui-components'

export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: 170,
    height: 340,
    p: 1,
    pt: 2,
    borderRadius: 3,
    border: '3px solid',
    borderColor: 'grey.200',
    boxShadow: '0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 11px 15px -7px rgba(0, 0, 0, 0.2)',
  },
  handle: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    height: 20,
    width: '100%',
    cursor: 'grab',
    display: 'flex',
    justifyContent: 'center',
    color: 'text.secondary',
  },
  bar: {
    width: 100,
    height: 4,
    borderRadius: 4,
    mt: 0.5,
    bgcolor: 'divider',
  },
  close: {
    zIndex: 2,
    position: 'absolute',
    top: 4,
    right: 2,
  },
  pad: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    alignSelf: 'center',
    gap: 1,
    pt: 2,
  },
}

export function getLabel(status, hasCall) {
  if (status === VoiceCallStatus.Initiated && hasCall) return 'Connection...'
  if (status === VoiceCallStatus.Ringing && hasCall) return 'Ringing...'
  if (status === VoiceCallStatus.InProgress && hasCall) return 'In Progress'
  if (status === VoiceCallStatus.Completed) return 'Finished'
  if (status === VoiceCallStatus.Failed) return 'Failed'
  if (status === VoiceCallStatus.Busy) return 'Busy'
  if (status === VoiceCallStatus.NoAnswer) return 'No Answer'
  return 'Phone:'
}

export function Handle(props) {
  return (
    <Box {...props} sx={styles.handle}>
      <Box sx={styles.bar} />
    </Box>
  )
}

export function MicPermission({ children }) {
  const { hasAudioInputDevices } = useDevices()
  const { error, isError, isCompleted } = useMicPermission()

  if (!isCompleted) return null
  if (!isError)
    return (
      <Grow in>
        <div>{children}</div>
      </Grow>
    )

  const { title, content } = getMediaErrorDescription(error, hasAudioInputDevices)

  return (
    <Typography color="text.secondary" variant="body2">
      {title}
      <br />
      {content}
    </Typography>
  )
}
