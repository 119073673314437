import { useMemo } from 'react'
import { useLocalStorage } from 'usehooks-ts'

import useDevices from '../../../hooks/useDevices'
import { SELECTED_AUDIO_OUTPUT_KEY } from '../../../utils'

export default function useActiveSinkId() {
  const { audioOutputDevices } = useDevices()
  const [selectedSinkId, setSelectedSinkId] = useLocalStorage(SELECTED_AUDIO_OUTPUT_KEY, 'default')

  const activeSinkId = useMemo(() => {
    const hasSelectedAudioOutputDevice = audioOutputDevices.some((device) => selectedSinkId && device.deviceId === selectedSinkId)
    return hasSelectedAudioOutputDevice ? selectedSinkId : 'default'
  }, [audioOutputDevices, selectedSinkId])

  return [activeSinkId, setSelectedSinkId]
}
