import { Link } from '@mui-components'
import ErrorBase from '@components/ErrorBase'

export default function Forbidden() {
  return (
    <ErrorBase
      backToHomeForbidden
      title="Forbidden"
      subtitle={
        <>
          If you are trying to access the platform from outside North America, please contact Support at{' '}
          <Link href="tel:+18887080561" sx={{ whiteSpace: 'nowrap' }}>
            +1-888-708-0561
          </Link>{' '}
          for further assistance
        </>
      }
    />
  )
}
