import { useMemo } from 'react'

import { AuthUtils } from '@shared/utils'

import useAuthToken from './useAuthToken'

// Helper hook to get userId from the token
export default function useCurrentUserId() {
  const token = useAuthToken()

  return useMemo(() => AuthUtils.getUserId(token), [token])
}
