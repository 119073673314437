import { deserialize } from 'deserialize-json-api'

export const consentDocuments = {
  agree_tos: 'https://qcareplus.com/wp-content/uploads/2022/06/Software-Terms-of-Use-May-2022.pdf',
  agree_privacy_policy: 'https://qcareplus.com/wp-content/uploads/2022/06/Notice-of-Health-Information-Privacy-Practices-c.pdf',
  telehealth_consent:
    'https://qcareplus.com/wp-content/uploads/2023/12/Understanding-Your-Consent-to-Care-via-Telehealth-Q-Care-Plus-rev-20231108-CLEAN_Branded_footer2.pdf',
}

export const featureFlags = {
  data: [],
  jsonapi: {
    version: '1.0',
  },
}

export const settings = {
  data: [
    {
      id: '14',
      type: 'system_settings',
      attributes: {
        key: 'inactivity_period',
        value: '1800',
      },
    },
    {
      id: '2',
      type: 'system_settings',
      attributes: {
        key: 'php_url',
        value: 'https://local.qcareplus.com',
      },
    },
    {
      id: '5',
      type: 'system_settings',
      attributes: {
        key: 'rails_url',
        value: 'https://railslocal.qcareplus.com:3000',
      },
    },
    {
      id: '37',
      type: 'system_settings',
      attributes: {
        key: 'twilio_token',
        value: '8d2b39729915b7a3723586798dab4f22',
      },
    },
    {
      id: '38',
      type: 'system_settings',
      attributes: {
        key: 'twilio_account_sid',
        value: 'ACe6065df723101dd1f21db4d2073e182d',
      },
    },
    {
      id: '24',
      type: 'system_settings',
      attributes: {
        key: 'phone_numbers_for_sms',
        value: '9546863947',
      },
    },
    {
      id: '35',
      type: 'system_settings',
      attributes: {
        key: 'enable_change',
        value: 'false',
      },
    },
  ],
  jsonapi: {
    version: '1.0',
  },
}

export const settingsDeserialized = deserialize(settings, { transformKeys: 'camelCase' })
