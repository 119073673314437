import { SvgIcon } from '@mui-components'

export default function BusinessIcon(props) {
  return (
    <SvgIcon width="17" height="16" viewBox="0 0 17 16" fill="none" {...props}>
      <path fill="#fff" d="M.464 0h16v16h-16z" />
      <path
        d="M8.465 4.667V2H1.798v12H15.13V4.667H8.465zm-4 8H3.13v-1.334h1.334v1.334zm0-2.667H3.13V8.667h1.334V10zm0-2.667H3.13V6h1.334v1.333zm0-2.666H3.13V3.333h1.334v1.334zm2.666 8H5.798v-1.334H7.13v1.334zm0-2.667H5.798V8.667H7.13V10zm0-2.667H5.798V6H7.13v1.333zm0-2.666H5.798V3.333H7.13v1.334zm6.667 8H8.465v-1.334h1.333V10H8.465V8.667h1.333V7.333H8.465V6h5.333v6.667zm-1.334-5.334h-1.333v1.334h1.333V7.333zm0 2.667h-1.333v1.333h1.333V10z"
        fill="#949696"
      />
    </SvgIcon>
  )
}
