import PhoneFilled from '@ant-design/icons/PhoneFilled'
import RedoOutlined from '@ant-design/icons/RedoOutlined'
import VideoCameraFilled from '@ant-design/icons/VideoCameraFilled'

import { AppointmentTypes } from '@shared/utils'

export default function AppointmentIcon({ type, ...props }) {
  if (type === AppointmentTypes.Video) return <VideoCameraFilled {...props} />
  if (type === AppointmentTypes.Phone) return <PhoneFilled {...props} />
  return <RedoOutlined {...props} />
}
