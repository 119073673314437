import { useState } from 'react'

import usePubNub from '@shared/hooks/src/usePubNub'
import useSound from '@shared/hooks/src/useSound'
import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'

import { UserNotificationsKeys } from '@services/UserNotifications'
import { useNotificationOnClick } from '@pages/Notifications/Notifications.hooks'
import { NotificationContent } from '@pages/Notifications/NotificationsPopover'
import { CardActionArea, Snackbar } from '@mui-components'

import { styles, Title } from '../Notifications.utils'
import { useUser } from './UserNotifications.hooks'
import { mapNotification, notificationToLabel } from './UserNotifications.utils'

export default function UserNotifications() {
  const [open, setOpen] = useState(false)
  const [notification, setNotification] = useState(undefined)

  const me = useMe()
  const { data: author } = useUser(notification?.authorId)

  const playSound = useSound({
    howlProps: { src: '/sounds/notification2.mp3' },
  })

  usePubNub(`user_${me.id}`, ({ action, attributes }) => {
    if (action !== 'new_notification') return

    queryClient.invalidateQueries({ queryKey: UserNotificationsKeys.lists(me.id) })
    setNotification(mapNotification(attributes))
    setOpen(true)
    playSound()
  })

  const notificationWithAuthor = { ...(notification || {}), author }

  const handleClose = () => setOpen(false)
  const onClick = useNotificationOnClick(notificationWithAuthor)

  return (
    <Snackbar
      open={open && author && notification && author.id === notification.authorId}
      onClose={(event, reason) => {
        if (reason === 'escapeKeyDown') handleClose()
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <CardActionArea
        onClick={() => {
          onClick()
          handleClose()
        }}
        sx={styles.container}
      >
        <Title label={notificationToLabel(notification)} onClose={handleClose} />
        <NotificationContent notification={notificationWithAuthor} />
      </CardActionArea>
    </Snackbar>
  )
}
