import { getUserSetting } from '@shared/hooks/src/useUserSetting'
import { UserSettingName } from '@shared/utils'

export function getIncompleteFields(patient) {
  const incompleteFields = []
  const contactMethodSetting = getUserSetting(patient, UserSettingName.ContactMethod)
  const emailVerifiedSetting = getUserSetting(patient, UserSettingName.EmailVerified)
  const phoneVerifiedSetting = getUserSetting(patient, UserSettingName.PhoneVerified)

  if (!patient.firstName) incompleteFields.push('Missing first name')
  if (!patient.lastName) incompleteFields.push('Missing last name')
  if (patient.phone && !phoneVerifiedSetting?.value && !patient?.smsOptOut) incompleteFields.push('Missing phone verification')
  if (!patient.email) incompleteFields.push('Missing email')
  if (patient.email && !emailVerifiedSetting?.value) incompleteFields.push('Missing email verification')
  if (!patient.dob) incompleteFields.push('Missing date of birth')
  if (!patient.social) incompleteFields.push('Missing social')
  if (!contactMethodSetting?.value) incompleteFields.push('Missing contact preference')

  if (!patient.height) incompleteFields.push('Missing height')
  if (!patient.weight) incompleteFields.push('Missing weight')
  if (!patient.gender) incompleteFields.push('Missing gender')
  if (!patient.bornAs) incompleteFields.push('Missing born as gender')
  if (!patient.sexuality) incompleteFields.push('Missing sexuality')
  if (!(patient.race ?? []).filter(Boolean).length) incompleteFields.push('Missing race')
  if (!patient.ethnicity) incompleteFields.push('Missing ethnicity')

  return incompleteFields
}
