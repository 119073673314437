import dayjs from 'dayjs'

import { Grid, Paper, Skeleton, Stack, Typography } from '@mui-components'
import Avatar from '@components/Avatar'

export default function Description({ data }) {
  return (
    <Paper variant="outlined" sx={{ px: 2, py: 3 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <Stack spacing={1}>
            <Property label="Supersedes">{data.supersedesDate ? dayjs(data.supersedesDate).format('L') : 'n/a'}</Property>
            <Property label="Approval Date">{data.approvalDate ? dayjs(data.approvalDate).format('L') : 'n/a'}</Property>
            <Property label="Original Date">{data.originalDate ? dayjs(data.originalDate).format('L') : 'n/a'}</Property>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack spacing={1}>
            <Typography>Approver(s)</Typography>
            {(!data.approvers || data.approvers.length === 0) && (
              <Typography variant="h5" color="text.secondary">
                No approver(s)
              </Typography>
            )}
            {data.approvers?.map((approver) => (
              <User key={approver.id} data={approver} />
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  )
}

Description.Loading = function Loading() {
  return (
    <Paper variant="outlined" sx={{ px: 2, py: 3 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <Stack spacing={1}>
            <Property label="Supersedes">
              <Skeleton width={50} />
            </Property>
            <Property label="Approval Date">
              <Skeleton width={100} />
            </Property>
            <Property label="Original Date">
              <Skeleton width={150} />
            </Property>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack spacing={1}>
            <Typography>Author(s)</Typography>
            <Skeleton width={200} variant="rounded" height={50} />
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  )
}

function Property({ label, children }) {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography sx={{ minWidth: 110 }}>{label}</Typography>
      {['string', 'number'].includes(typeof label) ? <Typography fontWeight="bold">{children}</Typography> : children}
    </Stack>
  )
}

function User({ data }) {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Avatar user={data} />
      <Typography variant="h5">{data.fullName}</Typography>
    </Stack>
  )
}
