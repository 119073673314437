import useQuery from '@shared/hooks/src/useQuery'

import MetricsApi, { CBOsCoverageKey } from '@services/Metrics.api'

export default function useCBOsCoverage(options = {}) {
  return useQuery({
    queryKey: CBOsCoverageKey,
    queryFn: () => MetricsApi.cbosCoverage(),
    ...options,
  })
}
