import axios from 'axios'

import { AuthUtils } from '@shared/utils'

class API {
  constructor() {
    this.controller = new AbortController()
    this.api = axios.create({
      baseURL: import.meta.env.VITE_API_URL,
      withCredentials: true,
      timeout: 60000,
      headers: {
        Authorization: AuthUtils.getAuthToken(),
        'ngrok-skip-browser-warning': true,
      },
      signal: this.controller.signal,
    })

    this.api.interceptors.request.use((config) => {
      const overrideToken = config.override?.['Authorization']
      if (overrideToken) {
        delete config.override
        config.headers['Authorization'] = overrideToken
      } else {
        config.headers['Authorization'] = AuthUtils.getAuthToken()
      }
      return config
    })
  }

  abortAndReinitialize(message) {
    this.controller.abort(message)
    this.controller = new AbortController()
    this.api.defaults.signal = this.controller.signal
  }
}

export const Service = new API()

export default Service.api
