import { useCallback } from 'react'
import { useLocalStorage } from 'usehooks-ts'

export const draftKey = (threadId) => `thread-v2-${threadId}_message`
const isValidDraftKey = (key) => /^thread-v2-\d+_message$/.test(key)

// Clean up old drafts from localStorage to prevent memory leaks and stale data
// Doing the migration may carry over old issues with the drafts, so it's better to clean them up
export const cleanUp = () => {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i)
    if (key && /^thread-\d+_message$/.test(key)) {
      localStorage.removeItem(key)
    }
  }
}

// Clear expired drafts from localStorage
export const clearExpiredDrafts = (hours) => {
  const now = Date.now()
  const expiryTime = hours * 60 * 60 * 1000

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i)
    if (key && isValidDraftKey(key)) {
      const item = localStorage.getItem(key)
      if (item) {
        const { date } = JSON.parse(item)
        if (date + expiryTime < now) {
          localStorage.removeItem(key)
        }
      }
    }
  }
}

export const useDraft = (threadId, initialValue = '') => {
  const key = draftKey(threadId)
  const [draft, setDraft] = useLocalStorage(key, { message: initialValue, date: Date.now() })

  const setDraftWithDate = useCallback(
    (message) => {
      setDraft({ message, date: Date.now() })
    },
    [setDraft]
  )

  const removeDraft = useCallback(() => {
    setDraft(undefined)
    localStorage.removeItem(key)
  }, [key, setDraft])

  return {
    draft: draft?.message,
    setDraft: setDraftWithDate,
    removeDraft,
  }
}

cleanUp()
clearExpiredDrafts(localStorage.getItem('thread-drafts-expiration-hours') || 72)
