import { useEffect, useRef } from 'react'
import { defaults } from 'lodash'
import PubSub from 'pubsub-js'

import { Logger } from '@shared/utils'

const log = Logger('usePubSub.js')

/**
 * Helper hook to reduce boilerplate when subscribing to PubSub events.
 *
 * @example
 * usePubSub(
 *   `some-event-${id}`,
 *   (data) => console.log(data)
 *   { enabled: Boolean(id) }
 * )
 */
export default function usePubSub(channel, listener, options = {}) {
  const subscriber = useRef(listener)

  const { enabled } = defaults(options, {
    enabled: true,
  })

  useEffect(() => {
    subscriber.current = listener
  }, [listener])

  useEffect(() => {
    if (!enabled) return

    const id = PubSub.subscribe(channel, (channel, data) => subscriber.current(data))
    log.debug('[SUBSCRIBED]', id, channel)
    return () => {
      PubSub.unsubscribe(id)
      log.debug('[UNSUBSCRIBED]', id, channel)
    }
  }, [channel, enabled])
}

export const PubSubEvents = {
  ConversationMessageSent: 'conversation-message-sent',
  LabOrdered: 'lab-ordered',
  NoteSigned: 'note-signed',
  FileReady: 'file-ready',
  UserOutOfOffice: 'user-out-of-office',
}
