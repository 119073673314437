import { CardActionArea, Chip, Divider, Skeleton, Stack, Typography } from '@mui-components'

export default function SOP({ data, onSelect }) {
  return (
    <CardActionArea onClick={onSelect}>
      <Stack p={1} pb={2}>
        <Typography variant="h5" fontWeight="normal">
          {data.title}
        </Typography>
        <Stack direction="row" flexWrap="wrap" gap={0.5} mt={0.5}>
          <Chip size="small" variant="outlined" color="primary" label={data.policy} sx={{ mr: 1 }} />
          {data.categories?.map((category) => (
            <Chip key={category} size="small" variant="outlined" color="default" label={category} />
          ))}
        </Stack>
      </Stack>
      <Divider />
    </CardActionArea>
  )
}

SOP.Loading = function Loading() {
  return (
    <>
      <Stack p={1} pb={2}>
        <Typography variant="h5" fontWeight="normal">
          <Skeleton width={300} />
        </Typography>
        <Stack direction="row" flexWrap="wrap" gap={0.5} mt={0.5}>
          <Skeleton width={70} sx={{ mr: 1 }} />
          <Skeleton width={50} />
          <Skeleton width={50} />
        </Stack>
      </Stack>
      <Divider />
    </>
  )
}
