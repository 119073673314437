import { styled } from '@mui/material/styles'

const ExperimentalStyled = styled('div')(({ theme }) => ({
  position: 'relative',

  th: {
    fontWeight: 'normal',
  },

  '& .fc-license-message': {
    display: 'none',
  },
  '& .fc .fc-daygrid .fc-scroller-liquid-absolute': {
    overflow: 'hidden !important',
  },

  '& .fc .fc-timegrid-slot-label-frame': {
    textAlign: 'center',
  },

  // basic style
  '& .fc': {
    '--fc-small-font-size': theme.typography.body2.fontSize,
    '--fc-bg-event-opacity': 0.4,
    '--fc-border-color': theme.palette.divider,
    '--fc-daygrid-event-dot-width': '10px',
    '--fc-today-bg-color': theme.palette.background.paper,
    '--fc-list-event-dot-width': '10px',
    '--fc-event-border-color': theme.palette.primary.dark,
    '--fc-now-indicator-color': theme.palette.error.main,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    fontFamily: theme.typography.fontFamily,
  },

  // date text
  '& .fc .fc-daygrid-day-top': {
    display: 'grid',
    '& .fc-daygrid-day-number': {
      textAlign: 'center',
      marginTop: 12,
      marginBottom: 12,
    },
  },

  // weekday
  '& .fc .fc-col-header-cell': {
    backgroundColor: theme.palette.grey[300],
  },

  '& .fc th .fc-day-today': {
    backgroundColor: theme.palette.primary[600],
    '& .fc-col-header-cell-cushion': {
      color: theme.palette.text.contrast,
    },
  },

  '& .fc .fc-col-header-cell-cushion': {
    color: theme.palette.grey[900],
    padding: 10,
  },

  // events
  '& .fc-timegrid-col-events': {
    margin: 0,
  },

  '& .fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start': {
    marginLeft: 4,
    marginBottom: 6,
    borderRadius: 4,
    backgroundColor: theme.palette.primary.main,
    border: 'none',
  },

  '& .fc-h-event .fc-event-main': {
    padding: 4,
    paddingLeft: 8,
  },

  '& .fc .fc-v-event .fc-event-title': {
    textWrap: 'nowrap',
  },

  '& .fc .availability-slot': {
    background: theme.palette.warning.light,
  },

  '& .fc .blackout-slot': {
    boxShadow: 'none',
    background: `repeating-linear-gradient(45deg, ${theme.palette.info.dark}, ${theme.palette.info.dark} 8px, ${theme.palette.info.darker} 8px, ${theme.palette.info.darker} 10px)`,
  },

  '& .fc .admin-time-slot': {
    boxShadow: 'none',
    background: `repeating-linear-gradient(45deg, ${theme.palette.warning.light}, ${theme.palette.warning.light} 8px, ${theme.palette.warning.lighter} 8px, ${theme.palette.warning.lighter} 10px)`,
  },

  '& .fc .appointment-slot': {
    boxShadow: 'none',
    border: '1px solid',
    backgroundColor: theme.palette.warning.dark,
    borderColor: theme.palette.warning.darker,
  },

  '& .fc .appointment-slot.missed': {
    backgroundColor: theme.palette.grey[400],
    borderColor: theme.palette.grey[500],
    '& .fc-event-main': {
      color: theme.palette.text.primary,
    },
  },

  '& .fc .appointment-slot.documenting': {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary[600],
  },

  '& .fc .appointment-slot.complete': {
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.grey[400],
    '& .fc-event-main': {
      color: theme.palette.text.primary,
    },
  },

  '& .fc .fc-highlight': {
    background: theme.palette.primary.light,
    opacity: 'var(--fc-bg-event-opacity)',
  },

  // popover when multiple events
  '& .fc .fc-more-popover': {
    border: 'none',
    borderRadius: 6,
    zIndex: 1200,
  },

  '& .fc .fc-more-popover .fc-popover-body': {
    backgroundColor: theme.palette.grey[200],
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },

  '& .fc .fc-popover-header': {
    padding: 12,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.primary,
  },

  // agenda view
  '& .fc-theme-standard .fc-list-day-cushion': {
    backgroundColor: theme.palette.grey[100],
  },

  '& .fc .fc-day': {
    cursor: 'pointer',
  },

  '& .fc .fc-timeGridDay-view .fc-timegrid-slot': {
    backgroundColor: theme.palette.background.paper,
  },

  '& .fc .fc-timegrid-slot': {
    cursor: 'pointer',
  },

  '& .fc .fc-list-event:hover td': {
    cursor: 'pointer',
    backgroundColor: theme.palette.grey[100],
  },
}))

export default ExperimentalStyled
