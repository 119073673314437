import { useCallback, useEffect, useRef, useState } from 'react'

/**
 * Provides hovered state with possibility to delay events
 *
 * @param ref - ref of the element to track hovering
 * @param delay - delay in ms for both mouse enter and leave events
 * @param mouseEnterDelay - delay in ms for mouse enter event
 * @param mouseLeaveDelay - delay in ms for mouse leave event
 * @returns {boolean} - hovered state
 */
export default function useDelayedHover(ref, { delay = 0, mouseEnterDelay = 0, mouseLeaveDelay = 0 } = {}) {
  const [isHovered, setIsHovered] = useState(false)
  const timeout = useRef(null)

  const handleMouseEnter = useCallback(
    (e) => {
      if (timeout.current) clearTimeout(timeout.current)
      timeout.current = setTimeout(() => setIsHovered(true), delay || mouseEnterDelay)
    },
    [delay, mouseEnterDelay]
  )

  const handleMouseLeave = useCallback(
    (e) => {
      if (timeout.current) clearTimeout(timeout.current)
      timeout.current = setTimeout(() => setIsHovered(false), delay || mouseLeaveDelay)
    },
    [delay, mouseLeaveDelay]
  )

  useEffect(() => {
    const node = ref.current
    if (!node) return

    node.addEventListener('mouseenter', handleMouseEnter)
    node.addEventListener('mouseleave', handleMouseLeave)

    return () => {
      node.removeEventListener('mouseenter', handleMouseEnter)
      node.removeEventListener('mouseleave', handleMouseLeave)
    }
  }, [handleMouseEnter, handleMouseLeave, ref])

  return isHovered
}
