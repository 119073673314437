import { forwardRef } from 'react'
import { flatten } from 'lodash'
import IconButton from '@mui/material/IconButton'

const styles = {
  override: {
    backgroundColor: 'primary.300',
    '&:hover': { backgroundColor: 'primary.200' },
    color: 'primary.contrastText',
    borderRadius: '50%',
    '&.MuiIconButton-sizeSmall': {
      width: '24px',
      height: '24px',
      fontSize: '18px',
    },
    '&.MuiIconButton-sizeMedium': {
      width: '32px',
      height: '32px',
      fontSize: '22px',
    },
    '&.MuiIconButton-sizeLarge': {
      width: '40px',
      height: '40px',
      fontSize: '26px',
    },
  },
}

const IconCircleButton = forwardRef(function IconCircleButton(props, ref) {
  const { sx, ...rest } = props

  return <IconButton sx={flatten([styles.override, sx])} ref={ref} {...rest} />
})

export default IconCircleButton
