import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'

import ProvidersApi, { ProviderAvailabilitiesKeys } from '@services/Providers.api'

export function useAvailabilityCreate() {
  const me = useMe()
  const providerId = me.provider.id

  const create = useMutation({
    mutationFn: (data) => ProvidersApi.createAvailabilityRange(providerId, data),
  })

  return useCallback(
    (data) => {
      return create.mutateAsync(data).then(() => {
        queryClient.invalidateQueries({ queryKey: ProviderAvailabilitiesKeys.months(providerId) })
        queryClient.invalidateQueries({ queryKey: ProviderAvailabilitiesKeys.lists(providerId) })
      })
    },
    [create, providerId]
  )
}
