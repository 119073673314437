import dayjs from 'dayjs'

import { AppointmentScheduleTypes, AppointmentStatus } from '@shared/utils'

import {
  AppointmentIcon,
  CheckOutlinedIcon,
  ClockCircleOutlinedIcon,
  CloseCircleOutlinedIcon,
  EditOutlinedIcon,
  SyncOutlinedIcon,
} from '@icons'
import { Chip, Skeleton, Stack, Typography } from '@mui-components'

export function Encounter({ data }) {
  return (
    <Stack spacing={-0.5}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <AppointmentIcon type={data.type} />
        <Typography>{data.visitReason}</Typography>
      </Stack>
      <Typography variant="body2" color="text.secondary">
        {SchedTypeLabel[data.schedType]}
      </Typography>
    </Stack>
  )
}

Encounter.Loading = function () {
  return (
    <Stack spacing={-0.5}>
      <Typography>
        <Skeleton width={100} />
      </Typography>
      <Typography variant="body2">
        <Skeleton width={80} />
      </Typography>
    </Stack>
  )
}

export function Date({ data }) {
  if (!data.start) return null

  return (
    <Stack spacing={-0.5}>
      <Typography>{dayjs(data.start).format('L')}</Typography>
      <Typography>{dayjs(data.start).format('LT')}</Typography>
    </Stack>
  )
}

Date.Loading = function () {
  return (
    <Stack spacing={-0.5}>
      <Typography>
        <Skeleton width={90} />
      </Typography>
      <Typography>
        <Skeleton width={60} />
      </Typography>
    </Stack>
  )
}

export function Status({ data }) {
  const status = data.status

  if (status === AppointmentStatus.Scheduled) {
    return <Chip icon={<CheckOutlinedIcon />} size="small" label="Scheduled" color="secondary" variant="combined" />
  }

  if (status === AppointmentStatus.Waiting) {
    return <Chip icon={<ClockCircleOutlinedIcon />} size="small" label="Waiting" color="primary" />
  }

  if (status === AppointmentStatus.InProgress) {
    return <Chip icon={<SyncOutlinedIcon />} size="small" label="In progress" color="success" variant="combined" />
  }

  if (status === AppointmentStatus.Documenting) {
    return <Chip icon={<EditOutlinedIcon />} size="small" label="Documenting" color="warning" variant="combined" />
  }

  if (status === AppointmentStatus.Complete) {
    return <Chip icon={<CheckOutlinedIcon />} size="small" label="Complete" color="secondary" variant="combined" />
  }

  if (status === AppointmentStatus.Canceled) {
    return <Chip icon={<CloseCircleOutlinedIcon />} size="small" label="Canceled" color="secondary" variant="combined" />
  }

  if (status === AppointmentStatus.Missed) {
    return <Chip icon={<CloseCircleOutlinedIcon />} size="small" label="Missed" color="error" variant="combined" />
  }

  if (status === AppointmentStatus.Failed) {
    return <Chip icon={<CloseCircleOutlinedIcon />} size="small" label="Failed" color="error" variant="combined" />
  }

  // Handle cases where a status is not known. This should never happen!!!
  return <Chip size="small" label="Unknown" color="error" />
}

export const SchedTypeLabel = {
  [AppointmentScheduleTypes.Scheduled]: 'Scheduled',
  [AppointmentScheduleTypes.Unscheduled]: 'Unscheduled',
  [AppointmentScheduleTypes.Async]: 'Async',
}
