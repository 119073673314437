import dayjs from 'dayjs'
import PropTypes from 'prop-types'

import { toTitleCase } from '@shared/utils'

import { Button, Chip, Divider, Stack, Typography } from '@mui-components'

import DateRangeSelect from './DateRangeSelect'
import DateSelect from './DateSelect'
import MultiSelect from './MultiSelect'
import SingleSelect from './SingleSelect'
import { FilterType, useChips } from './TableFilters.hooks'

TableFilters.propTypes = {
  schema: PropTypes.arrayOf(
    PropTypes.shape({
      /** Key of the filter */
      key: PropTypes.string.isRequired,

      /** Label of the filter */
      label: PropTypes.string,

      /** Type of the filter */
      type: PropTypes.oneOf(Object.values(FilterType)).isRequired,

      /**
       *  'defaultValue' is the initial value for the filter. If the current value of the filter matches this default value,
       *  the filter cannot be removed. This is useful for setting a baseline filter that always applies.
       */
      defaultValue: PropTypes.any,

      /** Options to display in the menu */
      options: PropTypes.arrayOf(
        PropTypes.shape({
          /** Label of the option */
          label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

          /** Value of the option */
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
        })
      ),
    })
  ),

  /** Current values of the filters */
  values: PropTypes.object,

  /** Callback when the filters change */
  onChange: PropTypes.func.isRequired,

  /** If `true`, the reset button is shown */
  showReset: PropTypes.bool,

  /** Callback when the reset button is clicked */
  onReset: PropTypes.func,

  /** Additional styles to apply to the root element */
  sx: PropTypes.object,
}

/**
 * Component that generates and displays a list of filters according to the schema.
 * The schema is an array of objects that describe the filters.
 * Each object must have a `key`, `label` and `type` property.
 * Types of filters supported can be found in `FilterType`.
 *
 * @example
 * <TableFilters
 *   onChange={console.log}
 *   values={{ category: ['test2', 'test3'], status: 'active', dates: ['2021-01-01', '2021-01-31']
 *   schema={[
 *    {
 *      key: 'category',
 *      label: 'Category',
 *      type: FilterType.MULTI_SELECT,
 *      options: [
 *        { label: 'Test1', value: 'test1' },
 *        { label: 'Test2', value: 'test2' },
 *        { label: 'Test3', value: 'test3' },
 *      ],
 *    },
 *    {
 *      key: 'status',
 *      label: 'Status',
 *      type: FilterType.SELECT,
 *      options: [
 *        { label: 'Active', value: 'active' },
 *        { label: 'Inactive', value: 'inactive' },
 *        { label: 'Disabled', value: 'disabled' },
 *      ],
 *    },
 *    {
 *      key: 'dates',
 *      label: 'Dates',
 *      type: FilterType.DATE_RANGE,
 *    },
 * ]}
 */
export default function TableFilters({ sx = {}, showReset = false, onReset, ...props }) {
  return (
    <Stack alignItems="center" direction="row" flexWrap="wrap" gap={1} sx={sx}>
      <Filters {...props} />
      <Chips {...props} />
      <Reset show={showReset} onReset={onReset} />
    </Stack>
  )
}

export function Filters({ schema = [], values = {}, onChange }) {
  return schema.map(({ type, key, label = key, options }) => {
    if (type === FilterType.MULTI_SELECT) {
      return (
        <MultiSelect
          key={key}
          label={label.toUpperCase()}
          onChange={(values) => onChange({ [key]: values.length > 0 ? values : undefined })}
          options={options}
          disabled={options.length === 0}
          value={values[key]}
        />
      )
    }
    if (type === FilterType.SELECT) {
      return (
        <SingleSelect
          key={key}
          label={label.toUpperCase()}
          onChange={(value) => onChange({ [key]: value })}
          options={options}
          disabled={options.length === 0}
          value={values[key]}
        />
      )
    }
    if (type === FilterType.DATE_RANGE) {
      return (
        <DateRangeSelect
          key={key}
          label={label.toUpperCase()}
          onChange={(values = []) => onChange({ [key]: values.map((v) => v.format('YYYY-MM-DD')) })}
          value={(values[key] || []).map((v) => dayjs(v, 'YYYY-MM-DD'))}
        />
      )
    }
    if (type === FilterType.DATE) {
      const value = values[key]
      return (
        <DateSelect
          key={key}
          label={label.toUpperCase()}
          onChange={(value) => onChange({ [key]: value.format('YYYY-MM-DD') })}
          value={value ? dayjs(value, 'YYYY-MM-DD') : null}
        />
      )
    }
    return null
  })
}

export function Chips({ schema = [], values = {}, onChange }) {
  const chips = useChips({ schema, values, onChange })

  if (chips.length === 0) return <Typography color="text.secondary">No filters applied</Typography>

  return chips.map(({ key, value, label, onDelete, disabled = false, textTransform = 'none' }) => (
    <Chip
      key={`${key}-${value}`}
      clickable
      label={`${label || toTitleCase(key)} = ${value}`}
      size="small"
      onDelete={onDelete}
      onClick={onDelete}
      disabled={disabled}
      sx={{ textTransform }}
    />
  ))
}

export function Reset({ show, onReset }) {
  if (!show || !onReset) return null
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Divider orientation="vertical" sx={{ height: 26 }} />
      <Button size="small" color="secondary" sx={{ textTransform: 'none' }} onClick={onReset}>
        clear all
      </Button>
    </Stack>
  )
}
