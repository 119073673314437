import { useCallback, useRef, useState } from 'react'

export default function usePopover() {
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleToggle = useCallback(() => {
    setOpen((prevState) => !prevState)
  }, [])

  return { anchorRef, handleClose, handleOpen, handleToggle, open }
}
