import { useVoiceWidgetState } from '@providers/VoiceWidgetStateProvider'
import { PhoneOutlinedIcon } from '@icons'
import { Box, IconButton } from '@mui-components'

export default function PhoneCall() {
  const { toggle } = useVoiceWidgetState()

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        id="phone-call-button"
        color="secondary"
        onClick={() => toggle()}
        aria-label="Phone Call"
        data-testid="header-phone-call"
        sx={{ color: 'text.primary' }}
      >
        <PhoneOutlinedIcon style={{ fontSize: 23 }} />
      </IconButton>
    </Box>
  )
}
