import React from 'react'
import PropTypes from 'prop-types'

import { LinearProgress as LinearProgressMui } from '@mui-components'
import Transitions from '@components/Transitions'

LinearProgress.propTypes = {
  /** Will be displayed if true */
  loading: PropTypes.bool,

  /** Color of linear progress */
  color: PropTypes.string,

  /** Can pass mui sx style */
  sx: PropTypes.object,
}

export default function LinearProgress({ loading = false, color, sx = {} }) {
  return (
    <Transitions type="fade" in={loading}>
      <LinearProgressMui sx={{ position: 'absolute', width: '100%', zIndex: 1, borderRadius: 0, ...sx }} color={color} />
    </Transitions>
  )
}
