import { Skeleton, Stack, Typography } from '@mui-components'

import Property from './Property'

export default function PharmacyProperty({ label, data }) {
  if (!data) return <Property label={label}>—</Property>

  const subtitle = [data.city, data.state].filter(Boolean).join(', ')

  return (
    <Property label={label}>
      <Stack spacing={-0.5}>
        <Typography>{data.name}</Typography>
        {subtitle && <Typography color="text.secondary">{subtitle}</Typography>}
      </Stack>
    </Property>
  )
}

PharmacyProperty.Loading = function ({ label }) {
  return (
    <Property label={label}>
      <Stack spacing={-0.5}>
        <Typography>
          <Skeleton width={150} />
        </Typography>
        <Typography>
          <Skeleton width={100} />
        </Typography>
      </Stack>
    </Property>
  )
}
