import { keepPreviousData } from '@tanstack/react-query'
import { styled } from '@mui/material/styles'

import { useMe } from '@shared/providers/src/MeProvider'
import { UserRole } from '@shared/utils'

import { CopyOutlinedIcon } from '@icons'
import { Dialog, DialogContent, DialogTitle, Skeleton, Stack, Typography } from '@mui-components'
import CopyToClipboard from '@components/CopyToClipboard'
import { spacing } from '@components/Details/Details.utils'

import { SchedTypeLabel } from '../Appointments.utils'
import { invalidateEncounterNotes, useAppointment } from './AppointmentDetailsModal.hooks'
import DetailsSection from './components/DetailsSection'
import NotesSection from './components/NotesSection'
import PatientSection from './components/PatientSection'

export default function AppointmentDetailsModal({ appointmentId, appointment, open, onClose }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      scroll="paper"
      sx={{ '& .MuiDialog-container': { justifyContent: 'flex-end' } }}
    >
      <Content appointmentId={appointmentId} appointment={appointment} onClose={onClose} />
    </Dialog>
  )
}

function Content({ appointmentId, appointment, onClose }) {
  const me = useMe()
  const { data } = useAppointment(appointmentId, {
    enabled: !appointment && Boolean(appointmentId),
    placeholderData: keepPreviousData,
  })

  const appointmentData = appointment || data
  const title = [SchedTypeLabel[appointmentData?.schedType], 'Appointment'].filter(Boolean).join(' ')
  const showData = Boolean(appointmentData)

  return (
    <>
      <DialogTitle onClose={onClose}>
        {showData ? (
          <Stack spacing={-0.5}>
            <Stack direction="row" spacing={1}>
              <Typography fontWeight="normal" color="text.secondary">
                ID: {appointmentData.id}
              </Typography>
              <CopyToClipboard>{({ copy }) => <CopyIcon onClick={() => copy(appointmentData.id)} />}</CopyToClipboard>
            </Stack>
            <Typography component="h2" variant="h4">
              {title}
            </Typography>
          </Stack>
        ) : (
          <Stack spacing={-0.5}>
            <Typography>
              <Skeleton width={100} />
            </Typography>
            <Typography component="h2" variant="h4">
              <Skeleton width={300} />
            </Typography>
          </Stack>
        )}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={spacing}>
          {showData ? <DetailsSection appointment={appointmentData} /> : <DetailsSection.Loading />}
          <PatientSection appointment={appointmentData} />
          <NotesSection
            appointmentId={appointmentId || appointmentData?.id}
            readOnly={![UserRole.Admin, UserRole.Provider, UserRole.MA, UserRole.Support].includes(me.role)}
            onCreate={() => invalidateEncounterNotes(appointmentData)}
          />
        </Stack>
      </DialogContent>
    </>
  )
}

const CopyIcon = styled(CopyOutlinedIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 18,
}))
