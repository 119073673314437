import useQuery from '@shared/hooks/src/useQuery'

import PatientEncountersApi, { PatientEncountersKeys } from '@services/PatientEncounters.api'

export default function usePatientEncounter(patientId, encounterId, options = {}) {
  return useQuery({
    queryKey: PatientEncountersKeys.encounter(patientId, encounterId),
    queryFn: () => PatientEncountersApi.details(patientId, encounterId),
    ...options,
  })
}
