import { useCallback } from 'react'
import { useModal } from 'mui-modal-provider'

/**
 * Hook to display a modal dialog.
 *
 * @param {function} component - React component, a predefined dialog. All props for the dialog will be passed in the `props` parameter.
 * @param {function} props - A function with 2 parameters (item and close). This must return an object with any props the "component" needs.
 * @returns {function} - A function used to display the dialog.
 *
 * The props object "must" return onConfirm function.
 * The item parameter passed to props is any value you want available within the dialog. Typically, an object, defaults to null.
 * The close parameter for props is a function that will close the dialog. This should be called last in the onConfirm property of props.
 * if onConfirm returns a promise, loading state will be shown on the dialog until it resolves.
 *
 *
 * @example
 * const confirmAccountDisable = useDialog({
 *   component: DialogConfirmation,
 *   props: ({ item, close }) => ({
 *     title: 'Title',
 *     description: 'Description',
 *     rejectLabel: 'Cancel',
 *     confirmLabel: 'Disable',
 *     onReject: () => close(),
 *     onConfirm: async () => {
 *       await asyncFunc(item)
 *       await new Promise(resolve => setTimeout(resolve, 5000))
 *       close()
 *     },
 *   }),
 * })
 */
export default function useDialog({ props, component }) {
  const { showModal, hideModal, updateModal } = useModal()

  return useCallback(
    (item = undefined) => {
      const propsResult = props({ item, close: () => hideModal(modal.id) })

      const handleConfirm = (...args) => {
        const confirmResult = propsResult.onConfirm(...args)
        if (confirmResult instanceof Promise) {
          updateModal(modal.id, { loading: true })
          confirmResult.finally(() => updateModal(modal.id, { loading: false }))
        }
        return confirmResult
      }

      const modal = showModal(component, { ...propsResult, onConfirm: handleConfirm })
    },
    [component, hideModal, props, showModal, updateModal]
  )
}
