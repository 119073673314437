import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import { imageNotAvailable, keyToString } from '@shared/utils'

import api from './index'

export const PatientKeys = {
  patient: (patientId) => keyToString(['patient', patientId]),
}

export const PatientConsentsKeys = {
  consents: (patientId) => keyToString(['patient-consents', patientId]),
}

export const PatientTreatmentKeys = {
  treatmentTypes: (patientId) => keyToString(['patient-treatment-types', patientId]),
}

export const PatientGileadApplicationKeys = {
  application: (patientId, gileadApplicationId) => keyToString(['patient-gilead-applications', patientId, gileadApplicationId]),
}

export const PatientROIRequestKeys = {
  request: (patientId, roiRequestId) => keyToString(['patient-roi-requests', patientId, roiRequestId]),
}

export const PatientPhotoIdKeys = {
  photoId: (patientId) => keyToString(['patient-photo-id', patientId]),
}

export const PatientCbosKeys = {
  lists: (patientId) => keyToString(['patient-cbos', patientId]),
  list: (patientId, query) => [...PatientCbosKeys.lists(patientId), 'list', query],
}

export const PatientProvidersKeys = {
  lists: (patientId) => keyToString(['patient-providers', patientId]),
  list: (patientId, query) => [...PatientProvidersKeys.lists(patientId), 'list', query],
}

export const PatientEncounterTypesKeys = {
  lists: (patientId) => keyToString(['patient-encounter-types', patientId]),
  list: (patientId, query) => [...PatientEncounterTypesKeys.lists(patientId), 'list', query],
}

export const PatientQuestionnairesKeys = {
  questionnaires: (patientId) => keyToString(['patient-questionnaires', patientId]),
}

export const PatientAssessmentsKeys = {
  // ['patient-assessments']
  all: ['patient-assessments'],

  // ['patient-assessments', patientId]
  patient: (patientId) => keyToString([...PatientAssessmentsKeys.all, patientId]),

  // ['patient-assessments', patientId, 'lists']
  lists: (patientId) => [...PatientAssessmentsKeys.patient(patientId), 'lists'],

  // ['patient-assessments', patientId, 'lists', { ...query }]
  list: (patientId, query) => [...PatientAssessmentsKeys.lists(patientId), query],

  // ['patient-assessments', patientId, 'assessment']
  assessments: (patientId) => [...PatientAssessmentsKeys.patient(patientId), 'assessment'],

  // ['patient-assessments', patientId, 'assessment', assessmentId]
  assessment: (patientId, assessmentId) => keyToString([...PatientAssessmentsKeys.assessments(patientId), assessmentId]),
}

// Documents Query Key factory
export const PatientDocumentsKeys = {
  // ['patient-documents']
  all: ['patient-documents'],

  // ['patient-documents', patientId]
  patient: (patientId) => keyToString([...PatientDocumentsKeys.all, patientId]),

  // ['patient-documents', patientId, 'lists']
  lists: (patientId) => [...PatientDocumentsKeys.patient(patientId), 'lists'],

  // ['patient-documents', patientId, 'lists', { ...query }]
  list: (patientId, query) => [...PatientDocumentsKeys.lists(patientId), query],

  // ['patient-documents', patientId, 'document']
  documents: (patientId) => [...PatientDocumentsKeys.patient(patientId), 'document'],

  // ['patient-documents', patientId, 'document', documentId]
  document: (patientId, documentId) => keyToString([...PatientDocumentsKeys.documents(patientId), documentId]),
}

export const PatientInsurancesKeys = {
  // ['patient-insurances']
  all: ['patient-insurances'],

  // ['patient-insurances', patientId]
  patient: (patientId) => keyToString([...PatientInsurancesKeys.all, patientId]),

  // ['patient-insurances', patientId, 'lists']
  lists: (patientId) => [...PatientInsurancesKeys.patient(patientId), 'lists'],

  // ['patient-insurances', patientId, 'lists', { ...query }]
  list: (patientId, query) => [...PatientInsurancesKeys.lists(patientId), query],

  // ['patient-insurances', patientId, 'insurance']
  insurances: (patientId) => [...PatientInsurancesKeys.patient(patientId), 'insurance'],

  // ['patient-insurances', patientId, 'insurance', insuranceId]
  insurance: (patientId, insuranceId) => keyToString([...PatientInsurancesKeys.insurances(patientId), insuranceId]),

  // ['patient-insurances', patientId, 'summary']
  summary: (patientId) => [...PatientInsurancesKeys.patient(patientId), 'summary'],
}

export const PatientAppointmentsKeys = {
  // ['patient-appointments']
  all: ['patient-appointments'],

  // ['patient-appointments', patientId]
  patient: (patientId) => keyToString([...PatientAppointmentsKeys.all, patientId]),

  // ['patient-appointments', patientId, 'lists']
  lists: (patientId) => [...PatientAppointmentsKeys.patient(patientId), 'lists'],

  // ['patient-appointments', patientId, 'lists', { ...query }]
  list: (patientId, query) => [...PatientAppointmentsKeys.lists(patientId), query],

  // ['patient-appointments', patientId, 'appointment']
  appointments: (patientId) => [...PatientAppointmentsKeys.patient(patientId), 'appointment'],

  // ['patient-appointments', patientId, 'appointment', appointmentId]
  appointment: (patientId, appointmentId) => keyToString([...PatientAppointmentsKeys.appointments(patientId), appointmentId]),
}

export const PatientLabsKeys = {
  // ['patient-labs']
  all: ['patient-labs'],

  // ['patient-labs', patientId]
  patient: (patientId) => keyToString([...PatientLabsKeys.all, patientId]),

  // ['patient-labs', patientId, 'lists']
  lists: (patientId) => [...PatientLabsKeys.patient(patientId), 'lists'],

  // ['patient-labs', patientId, 'lists', { ...query }]
  list: (patientId, query) => [...PatientLabsKeys.lists(patientId), query],

  // ['patient-labs', patientId, 'lab']
  labs: (patientId) => [...PatientLabsKeys.patient(patientId), 'lab'],

  // ['patient-labs', patientId, 'lab', labId]
  lab: (patientId, labId) => keyToString([...PatientLabsKeys.labs(patientId), labId]),

  // ['patient-labs', patientId, 'lab-files']
  labFiles: (patientId) => [...PatientLabsKeys.patient(patientId), 'lab-files'],

  // ['patient-labs', patientId, 'lab-files', fileId]
  labFile: (patientId, fileId) => keyToString([...PatientLabsKeys.labFiles(patientId), fileId]),

  // ['patient-labs', patientId, 'lab', labId, 'notes']
  labNotesLists: (patientId, labId) => keyToString([...PatientLabsKeys.lab(patientId, labId), 'notes']),

  // ['patient-labs', patientId, 'lab', labId, 'notes', { ...query }]
  labNotesList: (patientId, labId, query) => keyToString([...PatientLabsKeys.labNotesLists(patientId, labId), query]),
}

export const PatientPrescriptionsKeys = {
  // ['patient-prescriptions']
  all: ['patient-prescriptions'],

  // ['patient-prescriptions', patientId]
  patient: (patientId) => keyToString([...PatientPrescriptionsKeys.all, patientId]),

  // ['patient-prescriptions', patientId, 'lists']
  lists: (patientId) => [...PatientPrescriptionsKeys.patient(patientId), 'lists'],

  // ['patient-prescriptions', patientId, 'lists', { ...query }]
  list: (patientId, query) => [...PatientPrescriptionsKeys.lists(patientId), query],

  // ['patient-prescriptions', patientId, 'prescription']
  prescriptions: (patientId) => [...PatientPrescriptionsKeys.patient(patientId), 'prescription'],

  // ['patient-prescriptions', patientId, 'prescription', prescriptionId]
  prescription: (patientId, prescriptionId) => keyToString([...PatientPrescriptionsKeys.prescriptions(patientId), prescriptionId]),
}

export const PatientAvailabilitiesKeys = {
  all: ['patient-availabilities'],

  // ['patient-availabilities', patientId]
  patient: (patientId) => keyToString([...PatientAvailabilitiesKeys.all, patientId]),

  // ['patient-availabilities', patientId, 'lists']
  lists: (patientId) => [...PatientAvailabilitiesKeys.patient(patientId), 'lists'],

  // ['patient-availabilities', patientId, 'lists', { ...query }]
  list: (patientId, query) => [...PatientAvailabilitiesKeys.lists(patientId), query],
}

const PatientsApi = {
  // GET
  patient: (patientId) =>
    api.get(`/patients/${patientId}`).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  cbos: (patientId, query, config) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/cbos`, query }), config)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  // ===================== CONSENT =====================
  consents: (patientId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/consents`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  giveConsent: (patientId, data, config) =>
    api
      .patch(`/patients/${patientId}/consents`, data, config)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []), // ===================== APPOINTMENTS =====================
  appointment: (patientId, appointmentId) =>
    api
      .get({ url: `/patients/${patientId}/appointments/${appointmentId}` })
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  appointments: (patientId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/appointments`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  availabilities: (patientId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/availabilities`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  questionnaires: (patientId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/questionnaires`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  // ===================== LABS =====================
  lab: (patientId, labId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/labs/${labId}`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  updateLab: (patientId, labId, data) =>
    api
      .patch(`/patients/${patientId}/labs/${labId}`, { lab: data })
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  labs: (patientId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/labs`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  labFile: (patientId, fileId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/labs_files/${fileId}`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  labFileRename: (patientId, fileId, data) =>
    api
      .patch(`/patients/${patientId}/labs_files/${fileId}`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  labNotes: (patientId, labId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/labs/${labId}/notes`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  createLabNote: (patientId, labId, data) =>
    api
      .post(`/patients/${patientId}/labs/${labId}/notes`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  // ===================== PHOTOS =====================
  photoId: (patientId) =>
    api
      .get(`/patients/${patientId}/photo_id`, { skipHandling: true })
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {})
      .catch(() => Promise.resolve({ id: 'error', photoId: imageNotAvailable })),

  // ===================== PRESCRIPTIONS =====================
  prescription: (patientId, rxId, config) =>
    api
      .get(`/patients/${patientId}/prescriptions/${rxId}`, config)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  prescriptions: (patientId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/prescriptions`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  // ===================== PROVIDERS =====================
  providers: (patientId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/providers`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  // ================= ENCOUNTER TYPES =====================
  encounterTypes: (patientId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/encounter_types`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  // ===================== DOCUMENTS =====================
  document: (patientId, documentId) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/documents/${documentId}` }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  documents: (patientId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/documents`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  // ===================== ASSESSMENTS =====================
  assessment: (patientId, assessmentId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/assessments/${assessmentId}`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  assessments: (patientId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/assessments`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  // ===================== INSURANCES =====================
  insurance: (patientId, insuranceId, config) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/insurances/${insuranceId}` }), config)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  insurances: (patientId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/insurances`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  // ===================== TREATMENT =====================
  treatments: (patientId) =>
    api.get(`/patients/${patientId}/treatment_types`).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  // ====================== GILEAD =======================
  gileadApplication: (patientId, gileadApplicationId) =>
    api
      .get(`/patients/${patientId}/gilead/applications/${gileadApplicationId}`)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  declineGileadApplication: (patientId, gileadApplicationId, data) =>
    api
      .post(`/patients/${patientId}/gilead/applications/${gileadApplicationId}/decline`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  // ====================== ROI =======================
  roiRequest: (patientId, roiRequestId) =>
    api
      .get(`/patients/${patientId}/roi_requests/${roiRequestId}`)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  declineRoiRequest: (patientId, roiRequestId, data) =>
    api
      .post(`/patients/${patientId}/roi_requests/${roiRequestId}/decline`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  updateTreatment: (patientId, data) =>
    api
      .put(`/patients/${patientId}/treatment_types`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  // POST
  create: (data) => api.post('/patients', data).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  createAppointment: (patientId, data) =>
    api.post(`/patients/${patientId}/appointments`, data).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  createInsurance: (patientId, data) =>
    api.post(`/patients/${patientId}/insurances`, data).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  uploadPhotoId: (patientId, data) => api.post(`/patients/${patientId}/photo_id`, data),
  uploadDocument: (patientId, data, config) => api.post(`/patients/${patientId}/documents`, data, config),

  // PATCH
  updateAppointment: (patientId, appointmentId, data) =>
    api
      .patch(`/patients/${patientId}/appointments/${appointmentId}`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  cancelAppointment: (patientId, appointmentId) =>
    api
      .patch(`/patients/${patientId}/appointments/${appointmentId}/cancel`)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  update: (patientId, data) =>
    api.patch(`/patients/${patientId}`, data).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  updateDocuments: (patientId, documentId, data) =>
    api
      .patch(`/patients/${patientId}/documents/${documentId}`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  updateInsurance: (patientId, insuranceId, data) =>
    api
      .patch(`/patients/${patientId}/insurances/${insuranceId}`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  updateQuestionnaires: (patientId, data) =>
    api
      .patch(`/patients/${patientId}/questionnaires`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  // DELETE
  removePhotoId: (patientId, reason) => api.delete(`/patients/${patientId}/photo_id`, { data: { reason } }),
  removeDocument: (patientId, documentId) => api.delete(`/patients/${patientId}/documents/${documentId}`),
  removeInsurance: (patientId, insuranceId) => api.delete(`/patients/${patientId}/insurances/${insuranceId}`),
}

export default PatientsApi
