import { LockOutlinedIcon } from '@icons'
import { Button, Fade, LoadingButton, Paper, Stack, Typography } from '@mui-components'

import { useForm, withBreakGlassCheck } from './BreakGlass.hooks'
import styles from './BreakGlass.styles'
import PasswordField from './components/PasswordField'

/**
 * User must enter their password to continue and their access will be recorded.
 *
 * @param {Number} patientId - The patient object.
 * @param {function} [onSuccess] - Callback function to be called when the user successfully unlocks the patient.
 * @param {function} [onClose] - Callback function to be called when the user closes the form.
 *
 * @example
 * <BreakGlassCard patientId={patientId}>
 *   <Page />
 * </BreakGlassCard>
 */

function BreakGlassCard({ patientId, onSuccess, onClose, sx = {} }) {
  const formik = useForm({ patientId, onSuccess })

  return (
    <Fade in>
      <Paper variant="outlined" sx={[styles.paper, sx]}>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Stack spacing={3} sx={styles.content}>
            <Typography variant="h2" color="primary.700" fontWeight="normal">
              Warning!
              <br />
              Confidential information.
            </Typography>
            <Typography variant="h4" color="primary.700">
              You must enter your password to continue and your access will be recorded.
            </Typography>
            <PasswordField formik={formik} />
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              {onClose && (
                <Button
                  color="error"
                  onClick={() => {
                    formik.resetForm()
                    onClose()
                  }}
                >
                  Cancel
                </Button>
              )}
              <LoadingButton type="submit" loading={formik.isSubmitting} variant="contained" endIcon={<LockOutlinedIcon />}>
                Unlock
              </LoadingButton>
            </Stack>
          </Stack>
        </form>
      </Paper>
    </Fade>
  )
}

export default withBreakGlassCheck(BreakGlassCard)
