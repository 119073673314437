import { deserialize } from 'deserialize-json-api'

import api from '@shared/services'
import { keyToString } from '@shared/utils'

export const UserSettingsKeys = {
  // ['user-settings']
  all: ['user-settings'],

  // ['user-settings', userId]
  user: (userId) => keyToString([...UserSettingsKeys.all, userId]),

  // ['user-settings', userId, 'list']
  list: (userId) => [...UserSettingsKeys.user(userId), 'list'],

  // ['user-settings', userId, 'settings']
  settings: (userId) => [...UserSettingsKeys.user(userId), 'settings'],

  // ['user-settings', userId, 'setting', settingId]
  setting: (userId, settingId) => keyToString([...UserSettingsKeys.settings(userId), settingId]),
}

const UserSettingsApi = {
  all: (userId, config) =>
    api.get(`/users/${userId}/user_settings`, config).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  byId: (userId, settingId, config) =>
    api
      .get(`/users/${userId}/user_settings/${settingId}`, config)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  update: (userId, settingId, data, config) =>
    api
      .patch(`/users/${userId}/user_settings/${settingId}`, data, config)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
}

export default UserSettingsApi
