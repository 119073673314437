import { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'

import { CaretDownOutlinedIcon } from '@icons'
import { Button, Menu, MenuItem, Stack, Typography } from '@mui-components'

SingleSelect.propTypes = {
  /** Label of the button */
  label: PropTypes.string.isRequired,

  /** Callback fired when the value changes */
  onChange: PropTypes.func,

  /** Options to display in the menu */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /** Label of the option */
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

      /** Description of the option */
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

      /** Value of the option */
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
    })
  ).isRequired,

  /** Value of the component */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
}

/**
 * Button with a dropdown menu that allows single selection
 *
 * @example
 * <SingleSelect
 *    label="Type"
 *    value={value}
 *    onChange={handleChange}
 *    options={[{ label: 'CBO', value: 'cbo' }, { label: 'Campaign', value: 'campaign' }]}
 * />
 */
export default function SingleSelect({ label, onChange, options, value, ...other }) {
  const anchorRef = useRef(null)
  const [openMenu, setOpenMenu] = useState(false)

  const handleValueChange = (newValue) => {
    onChange(newValue)
    setOpenMenu(false)
  }

  return (
    <>
      <Button color="inherit" endIcon={<DownIcon />} onClick={() => setOpenMenu(true)} ref={anchorRef} {...other}>
        {label}
      </Button>
      <Menu anchorEl={anchorRef.current} onClose={() => setOpenMenu(false)} open={openMenu} PaperProps={{ style: { width: 250 } }}>
        {options.map((option) => (
          <MenuItem key={option.label} selected={value === option.value} onClick={() => handleValueChange(option.value)}>
            <Stack spacing={-0.5}>
              <Typography>{option.label}</Typography>
              {option.description && (
                <Typography variant="body2" color="text.secondary">
                  {option.description}
                </Typography>
              )}
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

const DownIcon = styled(CaretDownOutlinedIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
}))
