import toast from 'react-hot-toast'

import { useSendReminderMessage } from '@shared/messaging/src/hooks'
import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import { mapCache, MessageReminderCategories, MessageReminderRelatedModels } from '@shared/utils'

import { ProviderAppointmentsKeys } from '@services/Appointments.api'
import { Stack, Typography } from '@mui-components'

import PhotoIdReminderButton from './PhotoIdReminderButton'

export default function PhotoIdReminderOnAppointment({ readOnly = false, patient, appointment }) {
  const me = useMe()
  const sendMessageReminder = useSendReminderMessage(patient.id)

  if (patient.hasPhotoIdentification) return null

  const handleSubmit = (message) => {
    return sendMessageReminder
      .mutateAsync({
        message,
        category: MessageReminderCategories.UploadPhotoId,
        related_model_type: MessageReminderRelatedModels.Appointment,
        related_model_id: appointment.id,
      })
      .then(({ relatedModel }) => {
        toast.success('Message sent')

        queryClient.setQueriesData(
          { queryKey: ProviderAppointmentsKeys.lists(me.provider.id) },
          mapCache((old) => (old.id === relatedModel.id ? { ...old, ...relatedModel } : old))
        )
      })
  }

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
      <Typography color="error">Missing photo ID</Typography>
      {!readOnly && (
        <PhotoIdReminderButton preferredName={patient.preferredName} disabled={appointment.remindedUploadPhotoId} onSubmit={handleSubmit} />
      )}
    </Stack>
  )
}
