import { useMemo } from 'react'
import dayjs from 'dayjs'

import useLoadingState from '@shared/hooks/src/useLoadingState'

import useAvailability from '@hooks/useAvailability'
import useBlackoutPeriods from '@hooks/useBlackoutPeriods'
import { Grid } from '@mui-components'
import AvailabilityDay from '@components/AvailabilityDay'
import Tile from '@components/Tile'

export default function Preview() {
  const period = useMemo(() => {
    return {
      startDate: dayjs().tz(window.timezone).startOf('day'),
      endDate: dayjs().tz(window.timezone).add(13, 'days').endOf('day'),
    }
  }, [])

  const { data: availabilityData, isPending: isAvailabilityPending, isFetching: isAvailabilityFetching } = useAvailability(period)
  const { data: blackoutsData, isPending: areBlackoutsPending, isFetching: areBlackoutsFetching } = useBlackoutPeriods(period)

  const data = useMemo(() => {
    return getDates(0, 14).map((date) => {
      const day = date.format('YYYY-MM-DD')

      const availability = availabilityData?.[day]
      const blackouts = blackoutsData?.[day]

      return { date, availability, blackouts }
    })
  }, [availabilityData, blackoutsData])

  const isPending = useLoadingState(isAvailabilityPending || areBlackoutsPending)
  const isFetching = useLoadingState(isAvailabilityFetching || areBlackoutsFetching)

  return (
    <Tile title="Two week preview" refreshing={isFetching}>
      <Grid container sx={{ py: 2, px: 3 }} spacing={2}>
        <Grid container item xs={12} sm={6} spacing={2}>
          {data.slice(0, 7).map(({ date, availability, blackouts }) => (
            <Grid key={date} item xs={12}>
              <AvailabilityDay date={date} isLoading={isPending} availability={availability} blackouts={blackouts} />
            </Grid>
          ))}
        </Grid>
        <Grid container item xs={12} sm={6} spacing={2}>
          {data.slice(7, 14).map(({ date, availability, blackouts }) => (
            <Grid key={date} item xs={12}>
              <AvailabilityDay date={date} isLoading={isPending} availability={availability} blackouts={blackouts} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Tile>
  )
}

function getDates(start, end) {
  const dates = []
  for (let i = start; i < end; i++) {
    dates.push(dayjs().tz(window.timezone).add(i, 'day'))
  }
  return dates
}
