export const states = [
  { abbreviation: 'AL', id: '01' },
  { abbreviation: 'AK', id: '02' },
  { abbreviation: 'AS', id: '60' },
  { abbreviation: 'AZ', id: '04' },
  { abbreviation: 'AR', id: '05' },
  { abbreviation: 'CA', id: '06' },
  { abbreviation: 'CO', id: '08' },
  { abbreviation: 'CT', id: '09' },
  { abbreviation: 'DE', id: '10' },
  { abbreviation: 'DC', id: '11' },
  { abbreviation: 'FL', id: '12' },
  { abbreviation: 'FM', id: '64' },
  { abbreviation: 'GA', id: '13' },
  { abbreviation: 'GU', id: '66' },
  { abbreviation: 'HI', id: '15' },
  { abbreviation: 'ID', id: '16' },
  { abbreviation: 'IL', id: '17' },
  { abbreviation: 'IN', id: '18' },
  { abbreviation: 'IA', id: '19' },
  { abbreviation: 'KS', id: '20' },
  { abbreviation: 'KY', id: '21' },
  { abbreviation: 'LA', id: '22' },
  { abbreviation: 'ME', id: '23' },
  { abbreviation: 'MH', id: '68' },
  { abbreviation: 'MD', id: '24' },
  { abbreviation: 'MA', id: '25' },
  { abbreviation: 'MI', id: '26' },
  { abbreviation: 'MN', id: '27' },
  { abbreviation: 'MS', id: '28' },
  { abbreviation: 'MO', id: '29' },
  { abbreviation: 'MT', id: '30' },
  { abbreviation: 'NE', id: '31' },
  { abbreviation: 'NV', id: '32' },
  { abbreviation: 'NH', id: '33' },
  { abbreviation: 'NJ', id: '34' },
  { abbreviation: 'NM', id: '35' },
  { abbreviation: 'NY', id: '36' },
  { abbreviation: 'NC', id: '37' },
  { abbreviation: 'ND', id: '38' },
  { abbreviation: 'MP', id: '69' },
  { abbreviation: 'OH', id: '39' },
  { abbreviation: 'OK', id: '40' },
  { abbreviation: 'OR', id: '41' },
  { abbreviation: 'PW', id: '70' },
  { abbreviation: 'PA', id: '42' },
  { abbreviation: 'PR', id: '72' },
  { abbreviation: 'RI', id: '44' },
  { abbreviation: 'SC', id: '45' },
  { abbreviation: 'SD', id: '46' },
  { abbreviation: 'TN', id: '47' },
  { abbreviation: 'TX', id: '48' },
  { abbreviation: 'UM', id: '74' },
  { abbreviation: 'UT', id: '49' },
  { abbreviation: 'VT', id: '50' },
  { abbreviation: 'VA', id: '51' },
  { abbreviation: 'VI', id: '78' },
  { abbreviation: 'WA', id: '53' },
  { abbreviation: 'WV', id: '54' },
  { abbreviation: 'WI', id: '55' },
  { abbreviation: 'WY', id: '56' },
]

export const statesObject = states.reduce((obj, item) => {
  obj[item.id] = item.abbreviation
  return obj
}, {})
