import { useMemo } from 'react'

import useCurrentUserId from '@shared/hooks/src/useCurrentUserId'
import usePubNub from '@shared/hooks/src/usePubNub'
import useQuery from '@shared/hooks/src/useQuery'
import MeSharedProvider from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import UsersSharedApi, { MeKey } from '@shared/services/src/Users.api'
import { UserRole } from '@shared/utils'

import PharmaciesApi, { PharmacyKeys } from '@services/Pharmacies.api'

/**
 * Wraps shared MeProvider with a query to fetch the current user,
 * and subscribes to PubNub events for the current user.
 */
export default function MeProvider({ children }) {
  const userId = useCurrentUserId()

  const { data } = useQuery({
    queryKey: MeKey(userId),
    queryFn: UsersSharedApi.me,
  })
  const { data: pharmacy } = useQuery({
    queryKey: PharmacyKeys.pharmacy(data?.pharmacyId),
    queryFn: () => PharmaciesApi.details(data?.pharmacyId),
    enabled: Boolean(data) && data.role === UserRole.Pharmacy && Boolean(data.pharmacyId),
  })

  const user = useMemo(() => {
    if (data?.role === UserRole.Pharmacy) {
      return { ...data, pharmacy }
    }

    return data
  }, [data, pharmacy])

  usePubNub(
    `user_${user?.id}`,
    ({ action, attributes }) => {
      if (action === 'new_message') {
        return queryClient.setQueryData(MeKey(user?.id), (prev) => ({
          ...prev,
          unreadConversationsCount: attributes.unread_conversations,
        }))
      }

      if (action === 'unreviewed_labs') {
        return queryClient.setQueryData(MeKey(user?.id), (prev) => ({
          ...prev,
          unreviewedLabsCount: attributes.count,
        }))
      }

      if (action === 'update_quarterly_flow_count') {
        return queryClient.setQueryData(MeKey(user?.id), (prev) => ({
          ...prev,
          pendingFlows: attributes.pending_count,
        }))
      }
    },
    { enabled: Boolean(user?.id) }
  )

  return <MeSharedProvider value={user}>{children}</MeSharedProvider>
}
