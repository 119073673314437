import { SvgIcon } from '@mui-components'

export default function AudioMuted(props) {
  return (
    <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <g clipPath="url(#4id72gbd5a)" fill="currentColor">
        <path d="M12.415 7.859V4.966L10.89 6.493v1.366c-.002 1.018-.844 1.85-1.889 1.848a1.925 1.925 0 0 1-1.044-.302L6.87 10.51c.584.45 1.324.724 2.13.724 1.884 0 3.414-1.51 3.414-3.375z" />
        <path d="M15.449 7.675h-1.206a.161.161 0 0 0-.16.16 5.102 5.102 0 0 1-8.418 3.878L4.58 12.797a6.71 6.71 0 0 0 3.596 1.628v2.049H5.264c-.28 0-.5.287-.503.643v.723c.002.088.059.16.12.16h8.197c.065 0 .12-.072.12-.16v-.723c0-.356-.22-.643-.501-.643H9.703v-2.05a6.629 6.629 0 0 0 5.907-6.589.161.161 0 0 0-.161-.16zm.263-7.588-.874-.842a.16.16 0 0 0-.225.002L12.022 1.84A3.42 3.42 0 0 0 8.98 0C7.094 0 5.565 1.513 5.565 3.375v4.5c0 .135.008.268.024.398L4.223 9.64a5.07 5.07 0 0 1-.326-1.804.167.167 0 0 0-.16-.16H2.53a.161.161 0 0 0-.16.16c0 1.065.25 2.07.695 2.962L.314 13.549a.161.161 0 0 0 0 .227l.857.857a.161.161 0 0 0 .227 0L15.716.316a.162.162 0 0 0-.004-.23zM7.092 6.77V3.376c0-1.017.841-1.849 1.888-1.849.924 0 1.69.65 1.854 1.501L7.092 6.771z" />
      </g>
      <defs>
        <clipPath id="4id72gbd5a">
          <path fill="#fff" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}
