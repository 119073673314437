import { useEffect } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'

import { queryClient } from '@shared/providers/src/QueryClientProvider'

/** Clears the react-query cache when the `refresh` query param is present */
export default function useCacheRefresh() {
  const [refresh, setRefresh] = useQueryParam('refresh', StringParam, { updateType: 'replaceIn' })

  useEffect(() => {
    if (!refresh) return

    setRefresh(undefined)
    // timeout depends on throttleTime of createSyncStoragePersister
    setTimeout(() => queryClient.invalidateQueries({ queryKey: undefined }), 500)
  }, [refresh, setRefresh])
}
