import { styled } from '@mui/material/styles'

import usePatient from '@shared/hooks/src/usePatient'
import { useMe } from '@shared/providers/src/MeProvider'
import { UserRole } from '@shared/utils'

import useInsuranceSummary from '@hooks/useInsuranceSummary'
import { QuestionCircleOutlinedIcon } from '@icons'
import { Stack, Typography } from '@mui-components'
import { BreakGlassCard } from '@components/BreakGlass'
import Property, { CBOProperty, PatientProperty, PharmacyProperty, ProviderProperty } from '@components/Details/Property'
import Section from '@components/Details/Section'
import { InsuranceCardPopper } from '@components/InsuranceCard'
import { getIncompleteFields, PhotoIdReminderOnAppointment } from '@components/PhotoIdReminder'

export default function PatientSection({ appointment }) {
  const me = useMe()
  const isProvider = me.role === UserRole.Provider

  const { data: user, isPending } = usePatient(appointment?.userId, {
    enabled: Boolean(appointment?.userId),
    // placeholderData: appointment?.user,
  })

  const providerId = user?.provider?.id
  const address = user?.homeAddress

  return (
    <BreakGlassCard patient={user}>
      <Section paper mini title="Patient">
        {user ? <PatientProperty label="Name" user={user} /> : <PatientProperty.Loading label="Name" />}
        {providerId && providerId !== me.providerId && <ProviderProperty label="Provider" user={user.provider} />}
        {isPending ? (
          <>
            <CBOProperty.Loading label="CBO" />
            <PharmacyProperty.Loading label="Pharmacy" />
          </>
        ) : (
          <>
            <CBOProperty label="CBO" data={user.cbo} />
            <PharmacyProperty label="Pharmacy" data={user.pharmacy} />
          </>
        )}
        <InsuranceProperty patientId={user?.id} />
        {isPending ? (
          <>
            <Property.Loading label="Location" />
            <Property.Loading label="Language" />
            <Property.Loading label="Gender" />
            <Property.Loading label="Born As" />
          </>
        ) : (
          <>
            <Property label="Location">{[address?.city, address?.state].filter(Boolean).join(', ') || '—'}</Property>
            <Property label="Language">{user.language || '—'}</Property>
            <Property label="Gender">{user.gender || '—'}</Property>
            <Property label="Born As">{user.bornAs || '—'}</Property>
            <RequiredFieldsProperty readOnly={!isProvider} appointment={appointment} patient={user} />
          </>
        )}
      </Section>
    </BreakGlassCard>
  )
}

function InsuranceProperty({ patientId }) {
  const { data, isPending } = useInsuranceSummary(patientId)

  if (isPending) return <Property.Loading label="Insurance" />
  if (!data?.lastActiveInsurance) return <Property label="Insurance">—</Property>

  return (
    <Property label="Insurance">
      <InsuranceCardPopper patientId={patientId} insuranceId={data.lastActiveInsurance.id}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography>{data.lastActiveInsurance.company}</Typography>
          <FAQIcon />
        </Stack>
      </InsuranceCardPopper>
    </Property>
  )
}

function RequiredFieldsProperty({ readOnly, patient, appointment }) {
  const errors = getIncompleteFields(patient)

  if (errors.length === 0) return null

  return (
    <Property label="Incomplete">
      <Stack width="100%">
        <PhotoIdReminderOnAppointment readOnly={readOnly} patient={patient} appointment={appointment} />
        {errors.map((error) => (
          <Typography key={error} color="error">
            {error}
          </Typography>
        ))}
      </Stack>
    </Property>
  )
}

const FAQIcon = styled(QuestionCircleOutlinedIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 18,
}))
