import QS from 'query-string'

import api from './index'

const AuthApi = {
  checkLoginType: (query, config) => {
    return api.get(QS.stringifyUrl({ url: '/auth/login', query }), config).then((res) => res.data)
  },
  heartbeat: (config) => {
    const url = import.meta.env.VITE_API_URL.replace('/api', '')
    return api.get(`${url}/heartbeat`, { headers: { Accept: 'application/json' }, ...(config ?? {}) }).then((res) => res.data)
  },
  keepAlive: () => {
    const url = import.meta.env.VITE_API_URL.replace('/api', '')
    return api.post(`${url}/heartbeat`, undefined, { headers: { Accept: 'application/json' } }).then((res) => res.data)
  },
}

export default AuthApi
