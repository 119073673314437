import useQuery from '@shared/hooks/src/useQuery'
import { PatientInsurancesKeys } from '@shared/services/src/Patients.api'

import PatientsApi from '@services/Patients.api'

export default function useInsuranceSummary(patientId, options = {}) {
  return useQuery({
    queryKey: PatientInsurancesKeys.summary(patientId),
    queryFn: () => PatientsApi.insuranceSummary(patientId),
    enabled: Boolean(patientId),
    ...options,
  })
}
