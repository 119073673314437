import { useEffect } from 'react'
import { isSupported } from '@twilio/video-processors'

import TracksProvider, { useTracksContext } from '@shared/twilio/src/providers/TracksProvider'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, Typography } from '@mui-components'

import AudioInputList from './AudioInputList'
import AudioOutputList from './AudioOutputList'
import BackgroundList from './BackgroundList'
import VideoInputList from './VideoInputList'

export default function DeviceSelectionDialog({ open, onClose }) {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} scroll="body">
      <DialogTitle>Audio and Video Settings</DialogTitle>
      <DialogContent dividers>
        <TracksProvider>
          <MediaDevices />
        </TracksProvider>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function MediaDevices() {
  const { getAudioAndVideoTracks } = useTracksContext()

  useEffect(() => {
    getAudioAndVideoTracks()
  }, [getAudioAndVideoTracks])

  return (
    <>
      <Stack spacing={1}>
        <Typography variant="h4">Video</Typography>
        <VideoInputList />
        {isSupported && <BackgroundList />}
      </Stack>
      <Divider sx={{ my: 3 }} />
      <Stack spacing={1}>
        <Typography variant="h4">Audio</Typography>
        <Stack spacing={2}>
          <AudioInputList />
          <AudioOutputList />
        </Stack>
      </Stack>
    </>
  )
}
