import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import api from '@shared/services'

// Blackouts Query Key factory
export const BlackoutPeriodKeys = {
  // ['blackout-periods', 'lists']
  lists: ['blackout-periods', 'lists'],

  // ['blackout-periods', 'lists', { ...query }]
  list: (query) => [...BlackoutPeriodKeys.lists, query],
}

export const BusinessHoursKey = ['business-hours']

const PlatformSettingsApi = {
  toggleFeatureFlag: (flagId) => api.patch(`/feature_flags/${flagId}/toggle`),

  businessHours: () => api.get('/business_hours').then((res) => res.data),

  // ===================== BLACKOUT PERIODS =====================
  blackoutPeriods: (query) => {
    return api
      .get(QS.stringifyUrl({ url: '/blackout_periods', query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? [])
  },

  createBlackoutPeriod: (data) => {
    return api.post('/blackout_periods', data).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {})
  },

  deleteBlackoutPeriod: (id) => {
    return api.delete(`/blackout_periods/${id}`).then((res) => res.data)
  },
}

export default PlatformSettingsApi
