import ReactQuill from 'react-quill'

import { Paper, Skeleton } from '@mui-components'

import 'react-quill/dist/quill.snow.css'

export default function Content({ data }) {
  return <ReactQuill readOnly theme="snow" defaultValue={data.content} modules={{ toolbar: false }} />
}

Content.Loading = function Loading() {
  return (
    <Paper variant="outlined" sx={{ px: 2, py: 3 }}>
      <Skeleton width="100%" variant="rounded" height={300} />
    </Paper>
  )
}
