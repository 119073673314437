import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import api from '@shared/services'
import { keyToString } from '@shared/utils'

// CBOs Query Key factory
export const CBOKeys = {
  // ['cbos']
  all: ['cbos'],

  // ['cbos', cboId]
  cbo: (cboId) => keyToString([...CBOKeys.all, cboId]),

  // ['cbos', 'lists']
  lists: () => [...CBOKeys.all, 'lists'],

  // ['cbos', 'lists', { ...query }]
  list: (query) => [...CBOKeys.lists(), query],
}

const CbosApi = {
  cbos: (query, config) => {
    return api
      .get(QS.stringifyUrl({ url: '/cbos', query }), config)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? [])
  },
  cbo: (cboId, config) =>
    api.get(`/cbos/${cboId}`, config).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  updateCBO: (cboId, data) =>
    api.patch(`/cbos/${cboId}`, data).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
}

export default CbosApi
