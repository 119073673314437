import { FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Unstable_Grid2'

import { useMe } from '@shared/providers/src/MeProvider'
import { UserRole } from '@shared/utils'

import usePatientEncounter from '@hooks/usePatientEncounter'
import { CloseOutlinedIcon } from '@icons'
import { AppBar, Container, Dialog, IconButton, SlideUp, Stack, Toolbar, Typography, useMediaQuery } from '@mui-components'
import DocTitle from '@components/DocTitle'

import { useAppointmentCreate, useAppointmentUpdate } from './AppointmentScheduleModal.hooks'
import { getInitialValues, toAPIData, validationSchema } from './AppointmentScheduleModal.utils'
import CalendarSection from './sections/CalendarSection'
import ProviderSection from './sections/ProviderSection'
import SlotsSection from './sections/SlotsSection'
import SummarySection from './sections/SummarySection'

AppointmentScheduleModal.propTypes = {
  // Determines if the modal is open or not
  open: PropTypes.bool,

  // Determines if the modal should slide in or not
  slide: PropTypes.bool,

  // The appointment object containing appointment details
  // which is required when rescheduling an appointment
  appointment: PropTypes.object,

  // The patient object containing patient details
  patient: PropTypes.object,

  // The encounter object containing encounter details
  encounter: PropTypes.object,

  // The ID of the encounter to fetch the encounter details
  // when no encounter object is provided
  encounterId: PropTypes.number,

  // Function to be called when the modal is closed
  onClose: PropTypes.func.isRequired,
}

export default function AppointmentScheduleModal({ open = false, slide = false, ...props }) {
  return (
    <Dialog
      open={open}
      fullScreen
      disableEscapeKeyDown
      TransitionComponent={slide ? SlideUp : undefined}
      PaperProps={{ sx: { backgroundColor: 'background.default' } }}
      sx={{ '& .MuiDialogContent-root': { p: 0 } }}
    >
      <Content {...props} />
    </Dialog>
  )
}

function Content(props) {
  const { appointment, onClose } = props

  return (
    <>
      <DocTitle title={`${appointment ? 'Reschedule' : 'Schedule'} Appointment`} />
      <AppBar sx={{ position: 'relative', boxShadow: 'none' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseOutlinedIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
            {appointment ? 'Reschedule' : 'Schedule'} Appointment
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="md">
        <ScheduleForm {...props} />
      </Container>
    </>
  )
}

function ScheduleForm({ appointment, patient, encounter, encounterId, onClose }) {
  const me = useMe()
  const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const isProvider = me.role === UserRole.Provider

  const form = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: getInitialValues({ provider: me.provider, appointment }),
  })

  const { data } = usePatientEncounter(patient.id, encounterId, {
    enabled: !encounter && Boolean(encounterId),
  })

  const encounterData = data || encounter

  const create = useAppointmentCreate(patient.id, encounterData?.id)
  const update = useAppointmentUpdate(patient.id, encounterData?.id, appointment?.id)

  const onSubmit = () => {
    const fn = appointment ? update : create
    const data = toAPIData(form.getValues())
    return fn.mutateAsync(data).then(() => {
      toast(`Appointment ${appointment ? 'updated' : 'scheduled'} successfully`)
      onClose()
    })
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Grid container spacing={{ xs: 1, md: 2 }}>
          <Grid xs={12} sm={8}>
            <Stack spacing={{ xs: 1, md: 2 }}>
              {!isProvider && <ProviderSection patient={patient} />}
              {matchDownSM && <CalendarSection />}
              <SlotsSection appointment={appointment} patient={patient} encounter={encounterData} />
            </Stack>
          </Grid>
          <Grid xs={12} sm={4}>
            <Stack spacing={{ xs: 1, md: 2 }}>
              {!matchDownSM && <CalendarSection />}
              <SummarySection appointment={appointment} patient={patient} encounter={encounterData} />
            </Stack>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}
