import { UserRole } from '@shared/utils'

export function checkMessagingAvailability(user) {
  if (!user) return [false]
  if (user.role === UserRole.Patient) {
    if (!user.enrolled) return [false, 'Patient is not enrolled']
    if (user.disabled) return [false, 'Patient is disabled']
    if (!user.consent) return [false, 'Patient has not completed consents']
  }
  return [true]
}
