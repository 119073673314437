const config = {
  defaultPath: '/',
  i18n: 'en',
}

export const drawerWidth = 260
export const appName = 'Q\u00A0Care\u00A0Plus'

/** Time in seconds for inactivity check */
export const inactivityTime = 300

export function rubyLink(to) {
  return `${import.meta.env.VITE_RAILS_DOMAIN}/${to}`
}

export default config
