import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useReadLocalStorage } from 'usehooks-ts'

import usePrompt from '@shared/hooks/src/usePrompt'
import { BuildEnv, handleError, Logger, VoiceCallStatus } from '@shared/utils'

import { useCall, useToken, useTwilioCallState, useTwilioDeviceState } from './VoiceCallProvider.hooks'

const VoiceCallStateContext = createContext(null)
const log = Logger('VoiceCallProvider.js')

export function useVoiceCallState() {
  const context = useContext(VoiceCallStateContext)
  if (!context) {
    throw new Error('useVoiceCallState must be used within the VoiceCallProvider')
  }
  return context
}

export function useVoiceCall() {
  const callState = useVoiceCallState()
  return callState.call.data
}

/**
 * VoiceCallProvider is responsible for creating the Twilio Voice Device and managing the active call.
 * It also provides the device and call information to the rest of the application.
 *
 * @param {String} externalCall - Indication that call is external and outside the application, so no user id or appointment id is needed
 * @param {String} userId - User id to make the call
 * @param {String} appointmentId - Appointment id to make the call
 * @param {Boolean} disableHistory - Disables the call history for the call
 * @param {Boolean} allowInAppNavigation - Allows in-app navigation without a prompt
 * @param {ReactNode} children - React children
 *
 * @returns {JSX.Element} - VoiceCallStateContext.Provider with next information:
 * - makeCall: function to make a call
 * - hangUp: function to end a call
 * - error: any error that occurred
 * - twilio: {
 *   - device: Twilio Voice Device
 *   - call: Twilio Voice Call
 *   - callSid: Twilio Voice Call Sid
 * }
 */
export default function VoiceCallProvider({
  externalCall,
  userId,
  appointmentId,
  disableHistory = false,
  allowInAppNavigation = false,
  children,
}) {
  const initialized = useRef(false)

  const [lastPhoneNumber, setLastPhoneNumber] = useState('')
  const phoneOverride = useReadLocalStorage('voice-phone-override')

  const [device, registerDevice] = useTwilioDeviceState({
    onError: (e) => {
      handleError(e)
      return updateCall({ status: VoiceCallStatus.Failed })
    },
  })
  const [twilioCallSid, twilioCall, setTwilioCall] = useTwilioCallState({
    onError: (e) => {
      handleError(e)
      return updateCall({ status: VoiceCallStatus.Failed })
    },
  })

  const { data: token, error: tokenError, refetch: refetchToken } = useToken({ userId, externalCall })
  const [call, updateCall] = useCall({ userId, appointmentId, externalCall, callSid: twilioCallSid, disableHistory })

  const error = tokenError || call.error

  // responsible for making calls with Twilio Voice SDK
  // can spread additional parameters to the call @example { appointmentId: '123' }
  const makeCall = useCallback(
    async (to, params = {}) => {
      if (error) {
        handleError(error, { message: 'Call could not be completed, please try again later' })
        return updateCall({ status: VoiceCallStatus.Failed })
      }

      if (!device) return

      const phone = import.meta.env.VITE_BUILD_ENV !== BuildEnv.Production && phoneOverride ? phoneOverride : to
      setLastPhoneNumber(phone)

      log.info('Voice Call initiation')
      updateCall({ status: VoiceCallStatus.Initiated, endTime: undefined, startTime: undefined })

      try {
        const call = await device.connect({ params: { To: phone, ...params } })
        log.info('Voice Call initiated', call)
        setTwilioCall(call)
      } catch (e) {
        log.error('Voice Call failed', e)
        updateCall({ status: VoiceCallStatus.Failed })
        if (e.message?.includes('Device has been destroyed')) {
          toast("Voice Media Device has been destroyed, restoring... Please try again now or refresh the page if it doesn't help.")
          await registerDevice(token)
        }
        if (['AccessTokenInvalid', 'AccessTokenExpired'].includes(e.name)) {
          toast("Your Voice Call token has expired, refreshing... Please try again now or refresh the page if it doesn't help.")
          const { data: token } = await refetchToken()
          await registerDevice(token)
        }
      }
    },
    [error, device, phoneOverride, updateCall, setTwilioCall, registerDevice, token, refetchToken]
  )

  const hangUp = useCallback(() => {
    if (call.data?.status === VoiceCallStatus.InProgress) {
      updateCall({ status: VoiceCallStatus.Completed, endTime: new Date().toUTCString() })
    } else {
      updateCall({ status: VoiceCallStatus.NoAnswer })
    }

    if (!twilioCall) return

    twilioCall.disconnect()
    setTwilioCall(null)
  }, [call.data?.status, twilioCall, setTwilioCall, updateCall])

  // Prevent the user from leaving the page while a call is in progress.
  // Might move from here to specific pages.
  usePrompt('A call is in progress, are you sure you want to leave?', Boolean(twilioCall), allowInAppNavigation)

  useEffect(() => {
    if (token && !initialized.current) {
      initialized.current = true
      registerDevice(token)
    }
  }, [registerDevice, token])

  const state = useMemo(
    () => ({
      call,
      makeCall,
      hangUp,
      error,
      lastPhoneNumber,
      twilio: {
        device,
        call: twilioCall,
        callSid: twilioCallSid,
      },
    }),
    [call, makeCall, hangUp, error, lastPhoneNumber, device, twilioCall, twilioCallSid]
  )

  return <VoiceCallStateContext.Provider value={state}>{children}</VoiceCallStateContext.Provider>
}
