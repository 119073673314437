export const styles = {
  container: {
    p: 0,
    '& .MuiTimelineItem-root:before': {
      flex: 0,
      padding: 0,
    },
  },
  dot: {
    p: 0,
    borderWidth: 1,
  },
  message: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    backgroundColor: 'grey.200',
    borderRadius: 1,
    px: 1,
    py: 0.5,
  },
}
