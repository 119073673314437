import { Skeleton, Stack, Typography } from '@mui-components'

export default function Item({ small = false, primary, secondary }) {
  return (
    <Stack spacing={-0.5}>
      {primary && <Typography variant={small ? 'body2' : 'body1'}>{primary}</Typography>}
      {secondary && (
        <Typography variant={small ? 'body2' : 'body1'} color="text.secondary">
          {secondary}
        </Typography>
      )}
    </Stack>
  )
}

Item.Loading = function ({ small }) {
  return (
    <Stack spacing={-0.5}>
      <Typography variant={small ? 'body2' : 'body1'}>
        <Skeleton width={100} />
      </Typography>
      <Typography variant={small ? 'body2' : 'body1'}>
        <Skeleton width={80} />
      </Typography>
    </Stack>
  )
}
