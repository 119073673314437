import toast from 'react-hot-toast'
import { useMutation } from '@tanstack/react-query'
import PubSub from 'pubsub-js'

import { PubSubEvents } from '@shared/hooks/src/usePubSub'
import ConversationsSharedApi from '@shared/services/src/Conversations.api'
import { handleError, includesOneOfErrorMessages } from '@shared/utils'

/**
 * Responsible for sending a message to a conversation
 *
 * We have two flows:
 * - If the conversation already exists, we send the message to it
 * - If the conversation doesn't exist yet, we send the message to user directly
 *   via different api endpoint that creates conversation
 *
 * @param conversationId - conversation id when exist
 * @param userId - if conversation doesn't exist, we need to create it by sending a message to user id
 * @param config - axios config
 */
export default function useSendMessage({ conversationId, userId }, config) {
  return useMutation({
    mutationFn: ({ message, fromUserId, replyTo }) => {
      if (conversationId) {
        return ConversationsSharedApi.sendMessage(
          conversationId,
          {
            message_text: message,
            from_user_id: fromUserId,
            replying_message_id: replyTo,
          },
          config
        )
      }
      return ConversationsSharedApi.sendUserMessage(userId, { message_text: message }, config)
    },
    onSuccess: ({ conversation }) => {
      return PubSub.publish(PubSubEvents.ConversationMessageSent, { userId, conversationId: conversation.id })
    },
    onError: (e) => {
      if (includesOneOfErrorMessages(e, ['Cannot send messages to an archived conversation'])) {
        return toast.error('This conversation has been archived. No new messages can be sent.')
      }
      return catchError(e)
    },
  })
}

/**
 * Responsible for getting conversation id by userId
 */
export function useGetConversationId({ userId }, config) {
  return useMutation({
    mutationFn: () => ConversationsSharedApi.sendUserMessage(userId, null, config),
    onError: catchError,
  })
}

function catchError(e) {
  if (includesOneOfErrorMessages(e, ['does not has permissions'])) {
    return toast.error("You don't have permissions to send a message to this user.")
  }
  return handleError(e, { message: 'Unable to send message. Please try again later.' })
}
