import { Link } from 'react-router-dom'
import { ButtonBase } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'

import { RoleGuard } from '@shared/providers/src/MeProvider'
import { UserRole } from '@shared/utils'

import { resolveLogo } from '@utils/Helper'
import { Box, Stack } from '@mui-components'
import LogoMark from '@assets/logos/logo-mark.svg'

import PharmacySelector from '../PharmacySelector'

export default function Header({ open }) {
  const theme = useTheme()

  return (
    <Stack>
      <DrawerHeaderStyled theme={theme} open={open}>
        <ButtonBase disableRipple component={Link} to="/app" sx={{ width: open ? 'auto' : 35, height: 35 }}>
          <Box component="img" src={open ? resolveLogo() : LogoMark} alt="logo" sx={{ width: '100%', height: 'auto' }} />
        </ButtonBase>
      </DrawerHeaderStyled>
      <RoleGuard allowed={[UserRole.Pharmacy]}>
        <PharmacySelector minified={!open} />
      </RoleGuard>
    </Stack>
  )
}

const DrawerHeaderStyled = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  ...theme.mixins.toolbar,
  display: 'flex',
  alignItems: 'center',
  justifyContent: open ? 'flex-start' : 'center',
  paddingLeft: theme.spacing(open ? 3 : 0),
}))
