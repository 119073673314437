import { useLayoutEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { reportAxiosError } from '@shared/providers/src/HoneybadgerProvider'
import api, { Service } from '@shared/services'
import { AuthUtils, getErrorCode, getErrorMessage, includesOneOfErrorMessages, timeoutCode } from '@shared/utils'

import Error from '@pages/Misc/Error'
import NotAuthorized from '@pages/Misc/NotAuthorized'
import NotFound from '@pages/Misc/NotFound'

export default function AxiosErrorHandler({ children }) {
  const navigate = useNavigate()

  const [error, setError] = useState(undefined)
  const errorMessage = useMemo(() => getErrorMessage(error), [error])
  const errorCode = useMemo(() => getErrorCode(error), [error])

  useLayoutEffect(() => {
    const interceptor = api.interceptors.response.use(
      (response) => response,
      (error) => {
        reportAxiosError(error)

        if (error.config.skipHandling) throw error

        const status = error.response?.status
        if (error.code === timeoutCode) setError(error)
        if (status === 404 || status >= 500) setError(error)
        if (status === 403 && includesOneOfErrorMessages(error, ['does not has permissions on this endpoint'])) setError(error)
        if (status === 401 && includesOneOfErrorMessages(error, ['You are not authorized', 'User not authenticated'])) {
          Service.abortAndReinitialize('Operation canceled due to logout.')
          AuthUtils.logout()
          navigate('/login')
        }

        throw error
      }
    )

    return () => api.interceptors.response.eject(interceptor)
  }, [navigate])

  if (errorCode === 401) {
    return <NotAuthorized error={errorMessage} resetErrorBoundary={() => setError(undefined)} />
  }

  if (errorCode === 404) {
    return <NotFound error={errorMessage} resetErrorBoundary={() => setError(undefined)} />
  }

  if (errorMessage) return <Error code={errorCode} error={errorMessage} resetErrorBoundary={() => setError(undefined)} />

  return children
}
