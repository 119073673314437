import { RoleGuard } from '@shared/providers/src/MeProvider'
import { UserRole } from '@shared/utils'

import Appointments from './Appointments'
import Files from './Files'
import OutOfOffice from './OutOfOffice'
import ROIMedicalRecordGenerated from './ROIMedicalRecordGenerated'
import UserNotifications from './UserNotifications'

export default function Notifications() {
  return (
    <>
      <UserNotifications />
      <Files />
      <RoleGuard allowed={[UserRole.Provider]}>
        <Appointments />
      </RoleGuard>
      <OutOfOffice />
      <RoleGuard allowed={[UserRole.MA]}>
        <ROIMedicalRecordGenerated />
      </RoleGuard>
    </>
  )
}
