import useQuery from '@shared/hooks/src/useQuery'

import UsersApi, { UserCardKeys } from '@services/Users.api'

export function useUser(userId) {
  return useQuery({
    queryKey: UserCardKeys.user(userId),
    queryFn: () => UsersApi.userCard(userId, { skipHandling: true }),
    enabled: Boolean(userId),
  })
}
