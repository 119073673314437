import { Avatar as AvatarMui, Paper, Stack, Typography } from '@mui-components'

export default function Section({ mini = false, paper = false, title, action, icon, children }) {
  const content = paper ? <Paper variant="outlined">{children}</Paper> : children

  if (mini) {
    return (
      <Stack spacing={1}>
        <Title mini title={title} icon={icon} action={action} />
        {content}
      </Stack>
    )
  }

  return (
    <Paper variant="outlined">
      <Stack spacing={{ xs: 1, lg: 2 }} p={{ xs: 2, lg: 3 }}>
        <Title title={title} icon={icon} action={action} />
        {content}
      </Stack>
    </Paper>
  )
}

export function Title({ mini = false, title, icon, action }) {
  if (!title) return null

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
      <Stack direction="row" alignItems="center" spacing={1}>
        {icon && <AvatarMui sx={{ backgroundColor: 'background.paper', color: 'text.primary' }}>{icon}</AvatarMui>}
        <Typography variant={mini ? 'h5' : 'h4'}>{title}</Typography>
      </Stack>
      {action}
    </Stack>
  )
}
