import { useMemo } from 'react'
import { styled, useTheme } from '@mui/material/styles'

import { useLayoutState } from '@providers/LayoutStateProvider'
import { Box, Drawer, useMediaQuery } from '@mui-components'
import { drawerWidth } from '@config'

import Header from './Header'
import Menu from './Menu'

const styles = {
  temporaryDrawer: {
    display: { xs: 'block', lg: 'none' },
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      width: drawerWidth,
      borderRight: (theme) => `1px solid ${theme.palette.divider}`,
      backgroundImage: 'none',
      boxShadow: 'inherit',
    },
  },
}

export default function Sidebar() {
  // eslint-disable-next-line no-unused-vars
  const [sidebarOpen, setSidebarOpen, setSidebarClosed] = useLayoutState()

  const theme = useTheme()
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'))

  const menu = useMemo(() => <Menu />, [])
  const header = useMemo(() => <Header open={sidebarOpen} />, [sidebarOpen])

  return (
    <SidebarStyled component="nav" sx={styles.menu} aria-label="mailbox folders">
      {matchDownLG ? (
        <Drawer
          variant="temporary"
          open={sidebarOpen}
          onClose={() => setSidebarClosed()}
          ModalProps={{ keepMounted: true }}
          sx={styles.temporaryDrawer}
        >
          {header}
          {menu}
        </Drawer>
      ) : (
        <MiniDrawerStyled variant="permanent" open={sidebarOpen}>
          {header}
          {menu}
        </MiniDrawerStyled>
      )}
    </SidebarStyled>
  )
}

const openedMixin = (theme) => ({
  width: drawerWidth,
  borderRight: `1px solid ${theme.palette.divider}`,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  boxShadow: theme.palette.mode === 'dark' ? theme.customShadows.z1 : 'none',
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: theme.spacing(7.5),
  borderRight: 'none',
  boxShadow: theme.customShadows.z1,
})

const MiniDrawerStyled = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

const SidebarStyled = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer - 1,
  flexShrink: { md: 0 },
}))
