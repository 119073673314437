import { useMemo } from 'react'

import usePopover from '@shared/hooks/src/usePopover'

import { NotificationsPopover } from '@pages/Notifications'
import { useUserNotifications } from '@pages/Notifications/Notifications.hooks'
import { BellOutlinedIcon } from '@icons'
import { Badge, Box, IconButton } from '@mui-components'

export default function Notifications() {
  const popover = usePopover()
  const { data: unreadNotificationsCache } = useUserNotifications({ read: false }, { enabled: false })
  const { data: allNotificationsCache } = useUserNotifications({ read: undefined }, { enabled: false })

  const hasUnread = useMemo(() => {
    return [...(unreadNotificationsCache || []), ...(allNotificationsCache || [])].some((notification) => !notification.read)
  }, [allNotificationsCache, unreadNotificationsCache])

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        id="notifications-button"
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        color="secondary"
        aria-label="Notifcations"
        data-testid="header-notifcations"
        sx={{ color: 'text.primary' }}
      >
        <Badge color="error" variant="dot" invisible={!hasUnread}>
          <BellOutlinedIcon style={{ fontSize: 19 }} />
        </Badge>
      </IconButton>
      <NotificationsPopover anchorEl={popover.anchorRef.current} open={popover.open} onClose={popover.handleClose} />
    </Box>
  )
}
