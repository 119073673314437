import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react'

import { isDOMMutationError, isDynamicLoadError } from '@shared/utils'

const config = {
  apiKey: import.meta.env.VITE_HONEYBADGER_API_KEY,
  environment: import.meta.env.VITE_BUILD_ENV,
  revision: import.meta.env.VITE_GIT_HASH,
  maxErrors: 30,
  // Ignore errors from local
  developmentEnvironments: ['local'],
}

export const honeybadger = Honeybadger.configure(config).beforeNotify((notice) => {
  if (isDynamicLoadError(notice)) return false
  if (isDOMMutationError(notice)) {
    notice.name = 'GoogleTranslateError'
    notice.message = notice.url
  }
  return true
})

window.honeybadger = honeybadger

// Leaving as it is if we would like some common intercepting logic here
export default function HoneybadgerProvider({ children }) {
  return <HoneybadgerErrorBoundary honeybadger={honeybadger}>{children}</HoneybadgerErrorBoundary>
}
