import { useState } from 'react'
import dayjs from 'dayjs'

import { MoreOutlinedIcon, PillIcon } from '@icons'
import {
  Avatar as AvatarMui,
  Box,
  Divider,
  List,
  ListItemButton,
  LoadingButton,
  Popover,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui-components'
import Avatar from '@components/Avatar'

import { useMarkAllRead, useNotificationOnClick, useUserNotifications } from './Notifications.hooks'
import { NotificationType } from './Notifications.utils'

export default function NotificationsPopover({ anchorEl, open = false, onClose }) {
  const [tab, setTab] = useState('unread')

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useUserNotifications({
    read: tab === 'unread' ? false : undefined,
  })

  const markAllRead = useMarkAllRead()

  const showEmpty = !data || data?.length === 0
  const showData = !showEmpty && data?.length > 0

  return (
    <Popover
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      slotProps={{ paper: { sx: { width: '440px' } } }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      <Stack direction="row" spacing={2} sx={{ alignItems: 'center', justifyContent: 'space-between', px: 3, py: 2 }}>
        <Typography variant="h4">Notifications</Typography>
        <LoadingButton
          onClick={() => markAllRead.mutateAsync()}
          loading={markAllRead.isPending}
          size="small"
          sx={{ textTransform: 'none' }}
        >
          Mark all as read
        </LoadingButton>
      </Stack>
      <Tabs value={tab} onChange={(e, tab) => setTab(tab)} sx={{ px: 2 }}>
        <Tab label="Unread" value="unread" />
        <Tab label="All" value="all" />
      </Tabs>
      <Divider />
      {showEmpty && (
        <Box sx={{ p: 2 }}>
          <Typography variant="h5" color="text.secondary" sx={{ height: 50, fontWeight: 'normal', textAlign: 'center' }}>
            Nothing to see here!
          </Typography>
        </Box>
      )}
      {showData && (
        <Box sx={{ maxHeight: '360px', overflowY: 'auto' }}>
          <List disablePadding>
            {data.map((notification, index) => (
              <NotificationItem key={notification.id} divider={index < data.length - 1} notification={notification} onClose={onClose} />
            ))}
            {hasNextPage && (
              <LoadingButton
                fullWidth
                loading={isFetchingNextPage}
                onClick={() => fetchNextPage()}
                endIcon={<MoreOutlinedIcon rotate={90} />}
                loadingPosition="end"
                sx={{ textTransform: 'none' }}
              >
                more
              </LoadingButton>
            )}
          </List>
        </Box>
      )}
    </Popover>
  )
}

function NotificationItem({ divider, notification, onClose }) {
  const onClick = useNotificationOnClick(notification)

  return (
    <ListItemButton
      divider={divider}
      onClick={() => {
        onClick()
        onClose()
      }}
      sx={{ alignItems: 'flex-start', justifyContent: 'space-between', opacity: notification.read ? 0.5 : 1 }}
    >
      <NotificationContent notification={notification} />
    </ListItemButton>
  )
}

export function NotificationContent({ notification }) {
  if (!notification) return null

  if (notification.type === NotificationType.Message && notification.properties?.patientId) {
    return (
      <Stack direction="row" spacing={1}>
        {notification.author && (
          <Box sx={{ pt: 0.5 }}>
            <Avatar variant="circular" user={notification.author} />
          </Box>
        )}
        <div>
          <Typography variant="body2">
            <b>{notification.author?.fullName || 'User'}</b>{' '}
            {notification.properties.replyingMessage ? 'responded to you' : 'mentioned you'} in the patient's internal chat (MRN{' '}
            {notification.properties.patientId})
          </Typography>
          {notification.createdAt && (
            <Typography color="text.secondary" variant="caption">
              {dayjs(notification.createdAt).format('MMM D, hh:mm A')}
            </Typography>
          )}
        </div>
      </Stack>
    )
  }
  if (notification.type === NotificationType.DoseSpot) {
    return (
      <Stack direction="row" spacing={1}>
        <Box sx={{ pt: 0.5 }}>
          <AvatarMui sx={{ backgroundColor: 'background.default', boxShadow: (theme) => theme.customShadows.z2, color: 'text.primary' }}>
            <PillIcon />
          </AvatarMui>
        </Box>
        <div>
          <Typography variant="body2">You have a new DoseSpot notification, click to open</Typography>
          {notification.createdAt && (
            <Typography color="text.secondary" variant="caption">
              {dayjs(notification.createdAt).format('MMM D, hh:mm A')}
            </Typography>
          )}
        </div>
      </Stack>
    )
  }
}
