import { Paper, Skeleton, Stack, Typography } from '@mui-components'

export default function Title({ data }) {
  return (
    <Paper variant="outlined" sx={{ px: 2, py: 3 }}>
      <Stack>
        <Typography variant="h3">{data.title}</Typography>
        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Typography>
            Policy Section <strong>{data.policy}</strong>
          </Typography>
          <Typography>
            Category <strong>{data.categories?.join(', ') || 'n/a'}</strong>
          </Typography>
          <Typography>
            Version <strong>{data.version || 'n/a'}</strong>
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  )
}

Title.Loading = function Loading() {
  return (
    <Paper variant="outlined" sx={{ px: 2, py: 3 }}>
      <Stack>
        <Typography variant="h3">
          <Skeleton width={200} />
        </Typography>
        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography>Policy Section</Typography>
            <Skeleton width={80} />
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography>Category</Typography>
            <Skeleton width={80} />
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography>Version</Typography>
            <Skeleton width={80} />
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  )
}
