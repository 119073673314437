import Grid from '@mui/material/Unstable_Grid2'

import { Link, Skeleton } from '@mui-components'
import Property from '@components/Property'

import { Section } from '../CBORuleSet.utils'

export default function Program340BDetails({ data, isLoading }) {
  return (
    <Section title="340B Details">
      <Grid container spacing={1}>
        <Grid xs={6}>
          <Property noWrap label="340B HRSA ID Number" value={isLoading ? <Skeleton width={150} /> : data?.grant340BInformation?.hrsaId} />
        </Grid>
        <Grid xs={6}>
          <Property
            noWrap
            label="Entity EMR System"
            value={isLoading ? <Skeleton width={150} /> : data?.grant340BInformation?.entityEMRSystem}
          />
        </Grid>
        <Grid xs={6}>
          <Property
            noWrap
            label="HRSA Grant Number"
            value={isLoading ? <Skeleton width={150} /> : data?.grant340BInformation?.hrsaGrantNumber}
          />
        </Grid>
        <Grid xs={6}>
          <Property
            noWrap
            label="Grant Patient Definition"
            value={isLoading ? <Skeleton width={150} /> : data?.grant340BInformation?.grantPatientDefinition}
          />
        </Grid>
        <Grid xs={6}>
          <Property
            noWrap
            label="Notice of Funding Number"
            value={isLoading ? <Skeleton width={150} /> : data?.grant340BInformation?.noticeOfFundingNumber}
          />
        </Grid>
        <Grid xs={6}>
          <Property
            noWrap
            label="HRSA Details"
            value={
              isLoading ? (
                <Skeleton width={150} />
              ) : (
                <Link noWrap href={data?.grant340BInformation?.hrsaUrl} target="_blank" rel="noopener">
                  {data?.grant340BInformation?.hrsaUrl}
                </Link>
              )
            }
          />
        </Grid>
      </Grid>
    </Section>
  )
}
