import { useCallback, useEffect, useState } from 'react'
import Video from 'twilio-video'
import { useLocalStorage } from 'usehooks-ts'

import usePubSub from '@shared/hooks/src/usePubSub'

import {
  DEFAULT_VIDEO_CONSTRAINTS,
  getDeviceInfo,
  isPermissionDenied,
  SELECTED_AUDIO_INPUT_KEY,
  SELECTED_VIDEO_INPUT_KEY,
} from '../../../utils'

export default function useLocalTracks() {
  const [audioTrack, setAudioTrack] = useState()
  const [videoTrack, setVideoTrack] = useState()
  const [isAcquiringLocalTracks, setIsAcquiringLocalTracks] = useState(false)
  const [isAcquiringLocalTracksCompleted, setIsAcquiringLocalTracksCompleted] = useState(false)

  const [selectedVideoDeviceId, setSelectedVideoDeviceId] = useLocalStorage(SELECTED_VIDEO_INPUT_KEY, '')
  const [selectedAudioDeviceId] = useLocalStorage(SELECTED_AUDIO_INPUT_KEY, '')

  const getLocalAudioTrack = useCallback((deviceId) => {
    const options = {}

    if (deviceId) {
      options.deviceId = { exact: deviceId }
    }

    return Video.createLocalAudioTrack(options).then((newTrack) => {
      setAudioTrack(newTrack)
      return newTrack
    })
  }, [])

  const getLocalVideoTrack = useCallback(async () => {
    const { videoInputDevices } = await getDeviceInfo()

    const hasSelectedVideoDevice = videoInputDevices.some((device) => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId)

    const options = {
      ...DEFAULT_VIDEO_CONSTRAINTS,
      name: `camera-${Date.now()}`,
      ...(hasSelectedVideoDevice && { deviceId: { exact: selectedVideoDeviceId } }),
    }

    return Video.createLocalVideoTrack(options).then((newTrack) => {
      setVideoTrack(newTrack)
      return newTrack
    })
  }, [selectedVideoDeviceId])

  const removeLocalAudioTrack = useCallback(() => {
    if (audioTrack) {
      audioTrack.stop()
      setAudioTrack(undefined)
    }
  }, [audioTrack])

  const removeLocalVideoTrack = useCallback(() => {
    if (videoTrack) {
      videoTrack.stop()
      setVideoTrack(undefined)
    }
  }, [videoTrack])

  // Clean up the audio track on unmount
  useEffect(() => {
    if (audioTrack) {
      return () => audioTrack.stop()
    }
  }, [audioTrack])

  // Clean up the video track on unmount
  useEffect(() => {
    if (videoTrack) {
      return () => videoTrack.stop()
    }
  }, [videoTrack])

  const getAudioAndVideoTracks = useCallback(async () => {
    const { audioInputDevices, videoInputDevices, hasAudioInputDevices, hasVideoInputDevices } = await getDeviceInfo()

    if (!hasAudioInputDevices && !hasVideoInputDevices) return Promise.resolve()
    if (isAcquiringLocalTracks || audioTrack || videoTrack) return Promise.resolve()

    setIsAcquiringLocalTracks(true)

    const hasSelectedAudioDevice = audioInputDevices.some((device) => selectedAudioDeviceId && device.deviceId === selectedAudioDeviceId)
    const hasSelectedVideoDevice = videoInputDevices.some((device) => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId)

    // In Chrome, it is possible to deny permissions to only audio or only video.
    // If that has happened, then we don't want to attempt to acquire the device.
    const isCameraPermissionDenied = await isPermissionDenied('camera')
    const isMicrophonePermissionDenied = await isPermissionDenied('microphone')

    const shouldAcquireVideo = hasVideoInputDevices && !isCameraPermissionDenied
    const shouldAcquireAudio = hasAudioInputDevices && !isMicrophonePermissionDenied

    const localTrackConstraints = {
      video: shouldAcquireVideo && {
        ...DEFAULT_VIDEO_CONSTRAINTS,
        name: `camera-${Date.now()}`,
        ...(hasSelectedVideoDevice && {
          deviceId: {
            exact: selectedVideoDeviceId,
          },
        }),
      },
      audio:
        shouldAcquireAudio &&
        (hasSelectedAudioDevice
          ? {
              deviceId: {
                exact: selectedAudioDeviceId,
              },
            }
          : hasAudioInputDevices),
    }

    return Video.createLocalTracks(localTrackConstraints)
      .then((tracks) => {
        const newVideoTrack = tracks.find((track) => track.kind === 'video')
        const newAudioTrack = tracks.find((track) => track.kind === 'audio')
        if (newVideoTrack) {
          setVideoTrack(newVideoTrack)
          // Save the deviceId, so it can be picked up by the VideoInputList component. This only matters
          // in cases where the user's video is disabled.
          setSelectedVideoDeviceId(newVideoTrack.mediaStreamTrack.getSettings().deviceId ?? '')
        }
        if (newAudioTrack) {
          setAudioTrack(newAudioTrack)
        }

        // These custom errors will be picked up by the MediaErrorSnackbar component.
        if (isCameraPermissionDenied && isMicrophonePermissionDenied) {
          const error = new Error()
          error.name = 'NotAllowedError'
          throw error
        }

        if (isCameraPermissionDenied) {
          throw new Error('CameraPermissionsDenied')
        }

        if (isMicrophonePermissionDenied) {
          throw new Error('MicrophonePermissionsDenied')
        }

        return 'success'
      })
      .finally(() => {
        setIsAcquiringLocalTracks(false)
        setIsAcquiringLocalTracksCompleted(true)
      })
  }, [isAcquiringLocalTracks, audioTrack, videoTrack, selectedVideoDeviceId, selectedAudioDeviceId, setSelectedVideoDeviceId])

  const getAudioTracks = useCallback(async () => {
    const { audioInputDevices, hasAudioInputDevices } = await getDeviceInfo()

    if (!hasAudioInputDevices) return Promise.resolve()
    if (isAcquiringLocalTracks || audioTrack) return Promise.resolve()

    setIsAcquiringLocalTracks(true)

    // In Chrome, it is possible to deny permissions to only audio or only video.
    // If that has happened, then we don't want to attempt to acquire the device.
    const isMicrophonePermissionDenied = await isPermissionDenied('microphone')
    const shouldAcquireAudio = hasAudioInputDevices && !isMicrophonePermissionDenied
    const hasSelectedAudioDevice = audioInputDevices.some((device) => selectedAudioDeviceId && device.deviceId === selectedAudioDeviceId)

    const localTrackConstraints = {
      audio: shouldAcquireAudio && (hasSelectedAudioDevice ? { deviceId: { exact: selectedAudioDeviceId } } : hasAudioInputDevices),
    }

    return Video.createLocalTracks(localTrackConstraints)
      .then((tracks) => {
        const newAudioTrack = tracks.find((track) => track.kind === 'audio')
        if (newAudioTrack) setAudioTrack(newAudioTrack)
        if (isMicrophonePermissionDenied) throw new Error('MicrophonePermissionsDenied')
        return 'success'
      })
      .finally(() => {
        setIsAcquiringLocalTracks(false)
        setIsAcquiringLocalTracksCompleted(true)
      })
  }, [isAcquiringLocalTracks, audioTrack, selectedAudioDeviceId])

  // Track video device change and restart the track
  usePubSub(
    SELECTED_VIDEO_INPUT_KEY,
    (newDeviceId) => {
      videoTrack?.restart({ ...DEFAULT_VIDEO_CONSTRAINTS, deviceId: { exact: newDeviceId } })
    },
    { enabled: Boolean(videoTrack) }
  )

  // Track audio input device change and restart the track
  usePubSub(
    SELECTED_AUDIO_INPUT_KEY,
    (newDeviceId) => {
      audioTrack?.restart({ deviceId: { exact: newDeviceId } })
    },
    { enabled: Boolean(audioTrack) }
  )

  const localTracks = [audioTrack, videoTrack].filter((track) => track !== undefined)

  return {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalTracks,
    isAcquiringLocalTracksCompleted,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
    getAudioTracks,
  }
}
