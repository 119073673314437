import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import api from '@shared/services'
import { keyToString } from '@shared/utils'

// Sops Query Key factory
export const SOPKeys = {
  // ['sops']
  all: ['sops'],

  // ['sops', 'lists']
  lists: ['sops', 'lists'],

  // ['sops', 'lists', { ...query }]
  list: (query) => [...SOPKeys.lists, query],

  // ['sops', 'sop']
  sops: ['sops', 'sop'],

  // ['sops', 'sop', sopId]
  sop: (sopId) => keyToString([...SOPKeys.sops, sopId]),
}

const SOPsApi = {
  SOPs: (query) =>
    api
      .get(QS.stringifyUrl({ url: '/sops', query }, { arrayFormat: 'bracket' }))
      .then((resp) => deserialize(resp.data, { transformKeys: 'camelCase' })?.data ?? []),

  SOP: (sopId) => api.get(`/sops/${sopId}`).then((resp) => deserialize(resp.data, { transformKeys: 'camelCase' })?.data ?? {}),

  create: (data) =>
    api.post('/sops', data, { skipHandling: true }).then((resp) => deserialize(resp.data, { transformKeys: 'camelCase' })?.data ?? {}),

  edit: (sopId, data) =>
    api
      .patch(`/sops/${sopId}`, data, { skipHandling: true })
      .then((resp) => deserialize(resp.data, { transformKeys: 'camelCase' })?.data ?? {}),

  delete: (sopId) => api.delete(`/sops/${sopId}`).then((resp) => deserialize(resp.data, { transformKeys: 'camelCase' })?.data ?? {}),
}

export default SOPsApi
