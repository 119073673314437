import toast from 'react-hot-toast'
import { useMutation } from '@tanstack/react-query'
import PubSub from 'pubsub-js'

import { PubSubEvents } from '@shared/hooks/src/usePubSub'
import ConversationsSharedApi from '@shared/services/src/Conversations.api'
import { handleError, includesOneOfErrorMessages } from '@shared/utils'

export default function useSendReminderMessage(userId) {
  return useMutation({
    mutationFn: (data) => ConversationsSharedApi.sendUserReminder(userId, data),
    onSuccess: ({ message }) => {
      return PubSub.publish(PubSubEvents.ConversationMessageSent, {
        userId,
        conversationId: message.conversation.id,
      })
    },
    onError: (e) => {
      if (includesOneOfErrorMessages(e, ['Cannot send messages to an archived conversation'])) {
        return toast.error('This conversation has been archived. No new messages can be sent.')
      }
      return catchError(e)
    },
  })
}

function catchError(e) {
  if (includesOneOfErrorMessages(e, ['does not has permissions'])) {
    return toast.error("You don't have permissions to send a message to this user.")
  }
  return handleError(e, { message: 'Unable to send message. Please try again later.' })
}
