import useQuery from '@shared/hooks/src/useQuery'
import PatientsApi, { PatientProvidersKeys } from '@shared/services/src/Patients.api'

export default function usePatientAvailableProviders(patientId, query, options = {}) {
  return useQuery({
    queryKey: PatientProvidersKeys.list(patientId, query),
    queryFn: () => PatientsApi.providers(patientId, query),
    ...options,
  })
}
