import { CloseOutlinedIcon } from '@icons'
import { AppBar, Container, Dialog, Fade, IconButton, Toolbar, Typography } from '@mui-components'
import DocTitle from '@components/DocTitle'

import { useSOPsFilters } from '../SOPs.hooks'
import { toAPIFormat } from '../SOPsDialog/SOPsUpdate/SOPsUpdate.utils'
import { useSOP, useSOPCreate, useSOPDelete, useSOPEdit } from './SOPsDialog.hooks'
import Update from './SOPsUpdate'
import View from './SOPsView'

export default function SOPsDialog({ open, onClose }) {
  return (
    <Dialog fullScreen open={open} onClose={onClose} PaperProps={{ sx: { backgroundColor: 'background.default' } }}>
      <DocTitle title="SOP" />
      <AppBar>
        <Toolbar>
          <Typography sx={{ flex: 1 }} variant="h4">
            Standard Operating Process
          </Typography>
          <IconButton shape="rounded" color="inherit" onClick={onClose} data-testid="close-calendar">
            <CloseOutlinedIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Content />
    </Dialog>
  )
}

function Content() {
  const [{ sops, sopId }, updateFilters] = useSOPsFilters()

  const { data, isPending } = useSOP(sopId, { enabled: Boolean(sopId) })

  const create = useSOPCreate()
  const edit = useSOPEdit(data?.id)
  const remove = useSOPDelete(data?.id)

  const handleCreate = (values) => {
    return create.mutateAsync(toAPIFormat(values)).then((data) => updateFilters({ sops: 'view', sopId: data.id }))
  }

  const handleEdit = (values) => {
    return edit.mutateAsync(toAPIFormat(values)).then(() => updateFilters({ sops: 'view' }))
  }

  const handleDelete = () => {
    return remove.mutateAsync().then(() => updateFilters({ sops: 'list', sopId: undefined }))
  }

  return (
    <Fade key={sops} in>
      <Container maxWidth="md" sx={{ mt: (theme) => `${theme.mixins.toolbar.height}px` }}>
        {sops === 'view' && (
          <View data={data} isLoading={isPending} onEdit={() => updateFilters({ sops: 'edit' })} onDelete={handleDelete} />
        )}
        {sops === 'edit' && <Update data={data} onSubmit={handleEdit} onClose={() => updateFilters({ sops: 'view' })} />}
        {sops === 'create' && <Update onSubmit={handleCreate} onClose={() => updateFilters({ sops: 'list' })} />}
      </Container>
    </Fade>
  )
}
