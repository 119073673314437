import { Paper, Stack } from '@mui-components'

import Content from './components/Content'
import Description from './components/Description'
import Header from './components/Header'
import Title from './components/Title'

export default function View({ data, isLoading, onEdit, onDelete }) {
  if (isLoading) return <Loading />

  return (
    <Wrap>
      <Header data={data} onEdit={onEdit} onDelete={onDelete} />
      <Title data={data} />
      <Description data={data} />
      <Content data={data} />
    </Wrap>
  )
}

function Wrap({ children }) {
  return (
    <Paper variant="outlined" sx={{ p: 3 }}>
      <Stack spacing={2}>{children}</Stack>
    </Paper>
  )
}

function Loading() {
  return (
    <Wrap>
      <Header.Loading />
      <Title.Loading />
      <Description.Loading />
      <Content.Loading />
    </Wrap>
  )
}
