import { useMutation } from '@tanstack/react-query'
import { useEffectOnce, useInterval } from 'usehooks-ts'

import AuthApi from '@shared/services/src/Auth.api'
import { handleErrorSilently } from '@shared/utils'

/**
 * Handles the user auth session and keeping it alive if required.
 */
export default function KeepSessionAlive() {
  const inactivityTimeout = 300 * 1000 // 5 min
  const keepAlive = useMutation({
    mutationFn: () => AuthApi.keepAlive(),
  })

  const handleKeepAlive = () => keepAlive.mutateAsync().catch(handleErrorSilently)

  // Call the prolongate session endpoint when mounted
  useEffectOnce(() => {
    handleKeepAlive()
  })

  // Call the prolongate session endpoint every interval
  useInterval(() => {
    handleKeepAlive()
  }, inactivityTimeout)

  return null
}
