import { useState } from 'react'
import { useInterval } from 'usehooks-ts'

/**
 * Executes a callback every interval and saves the result in state
 *
 * @param {Function} callback - The callback to execute
 * @param {Number} interval - The interval in milliseconds
 * @returns - The result of the callback
 *
 * @example how to calculate the wait time every 1000ms
 * const waitTime = useIntervalResult(() => dayjs().from(dayjs(date), true))
 */
export default function useIntervalResult(callback, interval = 1000) {
  const [result, setResult] = useState(callback)

  useInterval(() => setResult(callback), interval)

  return result
}
