import PubNubClient from 'pubnub'
import PubSub from 'pubsub-js'

import { BuildEnv } from '../Constants'
import { Logger } from '../Logger'

const log = Logger('PubNub.js')

/**
 * MUCH MORE PREDICTABLE PUBNUB UTILITY
 *
 * Wrapper around PubSub and PubNub to make easier to work
 * with pubnub since it can have only 1 listener
 */
export class PubNub {
  constructor() {
    /**
     * subscribed channels with count
     * {
     *   channel1: 2,
     *   channel2: 1,
     *   channel3: 3,
     * }
     */
    this.channels = {}

    // pub nub client
    this.client = undefined
  }

  // Initialize PubNub client and redirect all messages to PubSub
  init(uuid) {
    const client = new PubNubClient({
      publishKey: import.meta.env.VITE_PUBNUB_PUBLISH_KEY,
      subscribeKey: import.meta.env.VITE_PUBNUB_SUBSCRIBE_KEY,
      uuid: uuid,
    })

    client.addListener({
      message: ({ channel, message }) => {
        const parsedMessage = JSON.parse(message)

        if (import.meta.env.VITE_BUILD_ENV !== BuildEnv.Production) {
          log.debug(`[Event] - ${channel}, action: ${parsedMessage?.action}, attributes: \n`, parsedMessage?.attributes)
        }

        PubSub.publish(`pubnub-${channel}`, parsedMessage)
      },
    })

    // Sync channels with pubnub
    client.subscribe({ channels: Object.keys(this.channels) })

    this.client = client
  }

  // Subscribe to a channel and return unsubscribe token
  on(channel, subscriber) {
    this._pubnubSubscribe(channel)

    return PubSub.subscribe(`pubnub-${channel}`, (msg, data) => subscriber(data))
  }

  // unsubscribe from a channel
  off(id, channel) {
    this._pubnubUnsubscribe(channel)

    return PubSub.unsubscribe(id)
  }

  // emit message to a channel
  emit(channel, message) {
    if (!this.client) return
    return this.client.publish({ channel, message })
  }

  // keep this.channels and pubnub subscriptions in sync
  _pubnubSubscribe(channel) {
    this.channels[channel] = this.channels[channel] + 1 || 1

    if (!this.client) return

    const currentSubscriptions = this.client.getSubscribedChannels() || []
    const subscribeChannels = Object.keys(this.channels).filter((c) => !currentSubscriptions.includes(c))

    if (subscribeChannels.length) {
      this.client.subscribe({ channels: subscribeChannels })
    }
  }

  // keep this.channels and pubnub subscriptions in sync
  _pubnubUnsubscribe(channel) {
    if (this.channels[channel] > 1) {
      this.channels[channel] -= 1
    } else {
      delete this.channels[channel]
    }

    if (!this.client) return

    const currentSubscriptions = this.client.getSubscribedChannels() || []
    const unsubscribeChannels = currentSubscriptions.filter((c) => !this.channels.hasOwnProperty(c))

    if (unsubscribeChannels.length) {
      this.client.unsubscribe({ channels: [channel] })
    }
  }
}

const pubNub = new PubNub()

export default pubNub
