import { useState } from 'react'
import dayjs from 'dayjs'
import { useEffectOnce } from 'usehooks-ts'

/**
 * Calculates did a given date passed
 *
 * @param date - The date to check
 * @returns {boolean} - Whether the date passed
 */
export default function useIsDatePassed(date) {
  const dayjsDate = dayjs(date)

  const [passed, setPassed] = useState(() => dayjs().isAfter(dayjsDate))

  useEffectOnce(() => {
    if (!passed) {
      const millisecondsToUpdate = dayjsDate.diff(dayjs(), 'milliseconds')
      const t = setTimeout(() => setPassed(true), millisecondsToUpdate)
      return () => clearTimeout(t)
    }
  })

  return passed
}
