import { useCallback, useEffect } from 'react'
import { GaussianBlurBackgroundProcessor, ImageFit, isSupported, VirtualBackgroundProcessor } from '@twilio/video-processors'
import { useLocalStorage } from 'usehooks-ts'

import CarePlus from '../../../images/QCarePlus.jpg'
import { BACKGROUND_FILTER_VIDEO_CONSTRAINTS, DEFAULT_VIDEO_CONSTRAINTS, SELECTED_BACKGROUND_SETTINGS_KEY } from '../../../utils'

const imageNames = ['Q Care Plus']
const rawImagePaths = [CarePlus]

const isDesktopChrome = /Chrome/.test(navigator.userAgent)
let imageElements = new Map()

const getImage = (index) => {
  return new Promise((resolve, reject) => {
    if (imageElements.has(index)) {
      return resolve(imageElements.get(index))
    }
    const img = new Image()
    img.onload = () => {
      imageElements.set(index, img)
      resolve(img)
    }
    img.onerror = reject
    img.src = rawImagePaths[index]
  })
}

export const backgroundConfig = { imageNames }

const virtualBackgroundAssets = '/virtualbackground'
let blurProcessor
let virtualBackgroundProcessor

export default function useBackgroundSettings(videoTrack, room, disabled) {
  const [backgroundSettings, setBackgroundSettings] = useLocalStorage(SELECTED_BACKGROUND_SETTINGS_KEY, {
    type: 'none',
    index: 0,
  })

  const setCaptureConstraints = useCallback(async () => {
    const { mediaStreamTrack, processor } = videoTrack ?? {}
    const { type } = backgroundSettings
    if (type === 'none' && processor) {
      return mediaStreamTrack?.applyConstraints(DEFAULT_VIDEO_CONSTRAINTS)
    } else if (type !== 'none' && !processor) {
      return mediaStreamTrack?.applyConstraints(BACKGROUND_FILTER_VIDEO_CONSTRAINTS)
    }
  }, [backgroundSettings, videoTrack])

  const removeProcessor = useCallback(() => {
    if (videoTrack && videoTrack.processor) {
      videoTrack.removeProcessor(videoTrack.processor)
    }
  }, [videoTrack])

  const addProcessor = useCallback(
    (processor) => {
      if (!videoTrack || videoTrack.processor === processor) {
        return
      }
      removeProcessor()
      videoTrack.addProcessor(processor, {
        inputFrameBufferType: 'video',
        outputFrameBufferContextType: 'webgl2',
      })
    },
    [videoTrack, removeProcessor]
  )

  useEffect(() => {
    if (!isSupported || disabled) {
      return
    }
    // make sure localParticipant has joined room before applying video processors
    // this ensures that the video processors are not applied on the LocalVideoPreview
    const handleProcessorChange = async () => {
      if (!blurProcessor) {
        blurProcessor = new GaussianBlurBackgroundProcessor({
          assetsPath: virtualBackgroundAssets,
          blurFilterRadius: 15,
          maskBlurRadius: 5,
          // Disable debounce only on desktop Chrome as other browsers either
          // do not support WebAssembly SIMD or they degrade performance.
          debounce: !isDesktopChrome,
        })
        await blurProcessor.loadModel()
      }
      if (!virtualBackgroundProcessor) {
        virtualBackgroundProcessor = new VirtualBackgroundProcessor({
          assetsPath: virtualBackgroundAssets,
          backgroundImage: await getImage(0),
          fitType: ImageFit.Cover,
          maskBlurRadius: 5,
          // Disable debounce only on desktop Chrome as other browsers either
          // do not support WebAssembly SIMD or they degrade performance.
          debounce: !isDesktopChrome,
        })
        await virtualBackgroundProcessor.loadModel()
      }
      if (!room?.localParticipant) {
        return
      }

      // Switch to 640x480 dimensions on desktop Chrome or browsers that
      // do not support WebAssembly SIMD to achieve optimum performance.
      const processor = blurProcessor || virtualBackgroundProcessor
      // @ts-ignore
      if (!processor._isSimdEnabled || isDesktopChrome) {
        await setCaptureConstraints()
      }

      if (backgroundSettings.type === 'blur') {
        addProcessor(blurProcessor)
      } else if (backgroundSettings.type === 'image' && typeof backgroundSettings.index === 'number') {
        virtualBackgroundProcessor.backgroundImage = await getImage(backgroundSettings.index)
        addProcessor(virtualBackgroundProcessor)
      } else {
        removeProcessor()
      }
    }
    handleProcessorChange()
  }, [backgroundSettings, videoTrack, room, addProcessor, removeProcessor, disabled, setCaptureConstraints])

  return [backgroundSettings, setBackgroundSettings]
}
