import { useTheme } from '@mui/material/styles'
import MUITooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

/**
 * Wrapper for MUI Tooltip.
 *
 * Note: I tried to do this with a theme override but it would not work :(
 */

export default function Tooltip(props) {
  const theme = useTheme()
  const { title } = props

  const titleContent = title ? <Typography fontWeight={theme.typography.fontWeightBold}>{title}</Typography> : ''

  return (
    <MUITooltip {...props} title={titleContent}>
      {props.children}
    </MUITooltip>
  )
}
