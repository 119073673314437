import { useMemo } from 'react'
import PropTypes from 'prop-types'

import { ExclamationOutlined } from '@icons'
import { Avatar as AvatarMui, Badge, Stack, Tooltip } from '@mui-components'
import { CBOCardPopper } from '@components/CBOCard'
import { UserCardPopper } from '@components/UserCard'
import LogoAvatar from '@assets/logos/logo-avatar.svg'

import { getSizeStyle, mapCBO, mapUser } from './Avatar.utils'

Avatar.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    nickname: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    fullName: PropTypes.string,
  }),
  variant: PropTypes.oneOf(['circular', 'rounded', 'square']),
  size: PropTypes.oneOf(['badge', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
  hover: PropTypes.oneOf(['none', 'simple', 'card']),
}

export default function Avatar({ user, variant = 'rounded', size = 'md', hover = 'none', sx, ...others }) {
  const { backgroundColor, initials } = useMemo(() => mapUser(user), [user])

  const content = (
    <AvatarMui
      alt="profile picture"
      src={user?.profilePictureThumbUrl}
      variant={variant}
      sx={[getSizeStyle(size), { color: 'primary.contrastText', backgroundColor }, sx]}
      {...others}
    >
      {initials}
    </AvatarMui>
  )

  // Use userId when it is available because Provider's id is not the same as userId
  const id = user?.userId || user?.id

  if (hover === 'none' || !id) return content

  if (hover === 'card') return <UserCardPopper userId={id}>{content}</UserCardPopper>

  if (hover === 'simple') return <Tooltip title={user?.fullName}>{content}</Tooltip>
}

Avatar.CBO = function CBO({ data, variant = 'rounded', size = 'md', sx, card = false, ...others }) {
  const { backgroundColor, initials } = useMemo(() => mapCBO(data), [data])

  const isAcceptingNewPatients = data?.acceptingNewPatients ?? true

  const avatar = (
    <AvatarMui
      alt="cbo logo"
      src={data?.logo}
      variant={variant}
      sx={[getSizeStyle(size), { color: 'primary.contrastText', backgroundColor }, sx]}
      {...others}
    >
      {initials}
    </AvatarMui>
  )

  let content = avatar

  if (!isAcceptingNewPatients) {
    content = (
      <Badge
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
          <Stack
            sx={{
              backgroundColor: 'error.main',
              borderRadius: '50%',
              color: 'text.contrast',
              border: '1px solid',
              borderColor: 'error.main',
            }}
          >
            <ExclamationOutlined style={{ fontSize: '12px' }} />
          </Stack>
        }
      >
        {avatar}
      </Badge>
    )
  }

  if (card && data?.id) {
    return <CBOCardPopper cboId={data.id}>{content}</CBOCardPopper>
  }

  return content
}

Avatar.Company = function AvatarCompany({ variant = 'circular', size = 'md', ...others }) {
  return (
    <Avatar
      variant={variant}
      size={size}
      user={{ profilePictureThumbUrl: LogoAvatar, fullName: 'Q P', firstName: 'Q', lastName: 'P' }}
      sx={{ backgroundColor: 'background.default' }}
      {...others}
    />
  )
}
Avatar.Icon = function AvatarIcon({ size, ...others }) {
  return <AvatarMui sx={[getSizeStyle(size), { color: 'primary.contrastText', backgroundColor: 'grey.500' }]} {...others} />
}
