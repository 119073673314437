import { useRef } from 'react'
import PropTypes from 'prop-types'
import { useHover } from 'usehooks-ts'
import { styled } from '@mui/material/styles'

import { CloudDownloadOutlinedIcon } from '@icons'
import { Box, Fade, Stack } from '@mui-components'

Overlay.propTypes = {
  /** The content to be displayed on hover */
  content: PropTypes.node.isRequired,

  /** The children to be displayed */
  children: PropTypes.node.isRequired,

  /** The function to be called on click */
  onClick: PropTypes.func,
}

const styles = {
  container: {
    position: 'relative',
    cursor: 'pointer',
  },
  overlay: {
    position: 'absolute',
    inset: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'background.overlay',
  },
}

/**
 * Allows to wrap any component with an overlay that will be displayed on hover.
 */
export default function Overlay({ children, content, onClick }) {
  const ref = useRef(null)
  const isHovered = useHover(ref)

  return (
    <Box ref={ref} sx={styles.container} onClick={onClick}>
      {children}
      <Fade in={isHovered}>
        <Stack sx={styles.overlay}>{content}</Stack>
      </Fade>
    </Box>
  )
}

const DownloadIcon = styled(CloudDownloadOutlinedIcon)(({ theme }) => ({
  color: theme.palette.text.contrast,
  fontSize: 50,
}))

Overlay.Download = function ({ children, onClick }) {
  return (
    <Overlay
      onClick={onClick}
      content={
        <Stack sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
          <DownloadIcon />
        </Stack>
      }
    >
      {children}
    </Overlay>
  )
}
