import { Skeleton, Stack, Typography } from '@mui-components'
import Avatar from '@components/Avatar'

import Property from './Property'

export default function ProviderProperty({ label, user }) {
  if (!user) return <Property label={label}>—</Property>

  const subtitle = [user.city, user.state].filter(Boolean).join(', ')

  return (
    <Property label={label}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Avatar user={user} hover="card" />
        <Stack spacing={-0.5}>
          <Typography>{user.fullName}</Typography>
          {subtitle && <Typography color="text.secondary">{subtitle}</Typography>}
        </Stack>
      </Stack>
    </Property>
  )
}

ProviderProperty.Loading = function ({ label }) {
  return (
    <Property label={label}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Skeleton variant="rounded" width={40} height={40} />
        <Stack spacing={-0.5}>
          <Typography>
            <Skeleton width={150} />
          </Typography>
          <Typography>
            <Skeleton width={100} />
          </Typography>
        </Stack>
      </Stack>
    </Property>
  )
}
