import { useMutation } from '@tanstack/react-query'

import useQuery from '@shared/hooks/src/useQuery'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import { filterCache, handleError, mapCache } from '@shared/utils'

import SOPsApi, { SOPKeys } from '@services/SOPs.api'

export function useSOP(id, options = {}) {
  return useQuery({
    queryKey: SOPKeys.sop(id),
    queryFn: () => SOPsApi.SOP(id),
    ...options,
  })
}

export function useSOPCreate() {
  return useMutation({
    mutationFn: (data) => SOPsApi.create(data),
    onSuccess: (sop) => {
      queryClient.setQueryData(SOPKeys.sop(sop.id), sop)
      queryClient.invalidateQueries({ queryKey: SOPKeys.lists })
    },
    onError: handleError,
  })
}

export function useSOPEdit(id) {
  return useMutation({
    mutationFn: (data) => SOPsApi.edit(id, data),
    onSuccess: (updatedSop) => {
      queryClient.setQueryData(SOPKeys.sop(id), updatedSop)
      queryClient.setQueriesData(
        { queryKey: SOPKeys.lists },
        mapCache((sop) => (sop.id === id ? updatedSop : sop))
      )
    },
    onError: handleError,
  })
}

export function useSOPDelete(id) {
  return useMutation({
    mutationFn: () => SOPsApi.delete(id),
    onSuccess: () =>
      queryClient.setQueriesData(
        { queryKey: SOPKeys.lists },
        filterCache((sop) => sop.id !== id)
      ),
    onError: handleError,
  })
}
