import PropTypes from 'prop-types'
import MUITabList from '@mui/lab/TabList'
import { useTheme } from '@mui/material'

TabList.propTypes = {
  /** Can force tabs to remain displayed when scrolling the content. */
  sticky: PropTypes.bool,
}

export default function TabList({ sticky = false, sx, ...rest }) {
  const theme = useTheme()
  const stickyStyles = sticky ? { position: 'sticky', top: 0, backgroundColor: theme.palette.background.paper, zIndex: 500 } : {}

  return <MUITabList {...rest} sx={{ ...sx, ...stickyStyles }} />
}
