import { useInfiniteQuery } from '@tanstack/react-query'
import { createEnumDelimitedArrayParam, createEnumParam, StringParam, withDefault } from 'use-query-params'

import useFiltering from '@shared/hooks/src/useFiltering'
import { Lookup, useLookup } from '@shared/providers/src/DropdownOptionsProvider'
import { flatten, pageParam } from '@shared/utils'

import SOPsApi, { SOPKeys } from '@services/SOPs.api'

export const LIMIT = 25

export function useSOPs(query) {
  return useInfiniteQuery({
    queryKey: SOPKeys.list(query),
    queryFn: ({ pageParam }) => SOPsApi.SOPs({ ...query, offset: pageParam * LIMIT }),
    select: flatten,
    initialPageParam: 0,
    getNextPageParam: pageParam(LIMIT),
  })
}

const ViewParam = createEnumParam(['list', 'view', 'edit', 'create'])

export function useSOPsFilters() {
  const policies = useLookup(Lookup.SOPPolicies)
  const categories = useLookup(Lookup.SOPCategories)

  return useFiltering({
    sops: ViewParam,
    sopId: StringParam,
    sopsSearch: StringParam,
    sopsPolicy: createEnumParam(policies),
    sopsCategories: withDefault(createEnumDelimitedArrayParam(categories), []),
  })
}
