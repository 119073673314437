import { createContext, useContext, useState } from 'react'

import VoiceWidget from '@components/_twilio/VoiceWidget'

const VoiceWidgetStateContext = createContext(undefined)

export const useVoiceWidgetState = () => useContext(VoiceWidgetStateContext)

export default function VoiceWidgetStateProvider({ children }) {
  const [open, setOpen] = useState(false)
  const [phone, setPhone] = useState('')

  const handleOpen = (phone) => {
    setOpen(true)
    if (phone) setPhone(phone)
  }

  const handleClose = () => {
    setOpen(false)
    setTimeout(() => setPhone(''), 300)
  }

  const state = {
    isOpen: open,
    open: handleOpen,
    close: handleClose,
    toggle: open ? handleClose : handleOpen,
    phone,
    setPhone,
  }

  return (
    <VoiceWidgetStateContext.Provider value={state}>
      {children}
      <VoiceWidget open={open} onClose={() => setOpen(false)} />
    </VoiceWidgetStateContext.Provider>
  )
}
