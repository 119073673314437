import { Stack, Typography } from '@mui-components'

const styles = {
  container: {
    backgroundColor: '#f6f8fa',
    height: (theme) => theme.mixins.footer.height,
    borderTop: '1px solid rgba(0,0,0,0.07)',
    color: '#7a878e',
    fontSize: 13,
    fontWeight: 'normal',
    pt: '6px',
    pl: '15px',
  },
}

export default function Footer() {
  return (
    <Stack direction="row" alignItems="center" sx={styles.container}>
      <Typography>© {new Date().getFullYear()} QCare Plus</Typography>
    </Stack>
  )
}
