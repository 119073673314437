import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDebounce } from 'usehooks-ts'
import { useTheme } from '@mui/material/styles'

import { UserRole } from '@shared/utils'

import { CloseOutlinedIcon, SearchOutlinedIcon } from '@icons'
import {
  Box,
  CircularProgress,
  Divider,
  Fade,
  IconButton,
  InputAdornment,
  Paper,
  Popper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui-components'
import Avatar from '@components/Avatar'
import { useUsers } from '@components/SelectUser/SelectUser.hooks'

const styles = {
  width: {
    width: '100%',
    maxWidth: { xs: 220, md: 320 },
  },
  popper: {
    zIndex: 1200,
    mt: (theme) => `${theme.spacing(1)} !important`,
  },
  input: {
    '& .MuiInputAdornment-root': { ml: 0 },
    '& .MuiOutlinedInput-root': { pr: 0 },
  },
}

export default function Search() {
  const ref = useRef()
  const navigate = useNavigate()
  const [inputValue, setInputValue] = useState('')

  const search = useDebounce(inputValue.trim(), 500)

  const { data, isFetching, isPending } = useUsers(
    { search, role: UserRole.Patient, limit: 5 },
    {
      staleTime: 60 * 1000,
      enabled: search.length > 0,
    }
  )

  const handleClick = (id) => {
    setInputValue('')
    navigate(`/app/patients/${id}`)
  }

  const showLoading = isPending
  const showEmpty = !showLoading && data?.length === 0
  const showData = !showEmpty && data?.length > 0

  return (
    <Box id="header-patient-search" ref={ref} sx={[styles.width, { ml: 1 }]}>
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        placeholder="Search for a patient"
        autoComplete="off"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Adornment isLoading={isFetching} isEmpty={!inputValue} onReset={() => setInputValue('')} />
            </InputAdornment>
          ),
        }}
        sx={styles.input}
      />
      <Popper
        transition
        id="patient-results"
        open={Boolean(inputValue)}
        anchorEl={ref.current}
        placement="bottom-start"
        sx={[styles.width, styles.popper]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper elevation={4} sx={{ p: { xs: 1, md: 2 } }}>
              {showLoading && (
                <Stack alignItems="center" justifyContent="center" height={300}>
                  <CircularProgress />
                </Stack>
              )}
              {showEmpty && (
                <Stack spacing={1}>
                  <Typography color="text.secondary" align="center">
                    We couldn’t find any patients that match. Try searching with a different term.
                  </Typography>
                  <Typography color="text.secondary" align="center">
                    You can search by name, email, or MRN number.
                  </Typography>
                </Stack>
              )}
              {showData && (
                <Stack spacing={1}>
                  {data.map((patient) => (
                    <Patient key={patient.id} data={patient} onClick={() => handleClick(patient.id)} />
                  ))}
                  <Divider />
                  <Typography variant="body2" color="text.secondary" align="center" sx={{ maxWidth: 240, alignSelf: 'center' }}>
                    You can search patients by their name, email or MRN number.
                  </Typography>
                </Stack>
              )}
            </Paper>
          </Fade>
        )}
      </Popper>
    </Box>
  )
}

function Patient({ data, onClick }) {
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Stack direction="row" alignItems="center" spacing={1} onClick={onClick} sx={{ cursor: 'pointer' }}>
      <Avatar user={data} size="xl" />
      <Box sx={{ display: 'grid' }}>
        <Typography noWrap variant={matchDownMd ? 'h5' : 'h4'}>
          {data.fullName}
        </Typography>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="body2" color="text.secondary" fontWeight="bold">
            MRN
          </Typography>
          <Typography>{data.id}</Typography>
        </Stack>
        <Typography color="text.secondary" variant="body2">
          {data.pronouns}
        </Typography>
      </Box>
    </Stack>
  )
}

function Adornment({ isLoading, isEmpty, onReset }) {
  if (isLoading) {
    return (
      <IconButton size="small" color="inherit">
        <CircularProgress size={20} />
      </IconButton>
    )
  }

  if (isEmpty) {
    return (
      <IconButton size="small" color="inherit" sx={{ p: 0 }}>
        <SearchOutlinedIcon />
      </IconButton>
    )
  }

  return (
    <IconButton size="small" color="inherit" onClick={onReset} sx={{ p: 0 }}>
      <CloseOutlinedIcon />
    </IconButton>
  )
}
