import React, { useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import MentionExtensionBase from '@tiptap/extension-mention'
import { mergeAttributes, NodeViewWrapper, ReactNodeViewRenderer, ReactRenderer } from '@tiptap/react'
import { useDebounce } from 'usehooks-ts'

import { userRoleToLabel } from '@shared/utils'

import SystemUsersApi, { SystemUsersKeys } from '@services/SystemUsers.api'
import { Box, Fade, List, ListItemButton, ListItemText, Paper, Popper, Stack, Typography } from '@mui-components'
import Avatar from '@components/Avatar'
import { UserCardPopper } from '@components/UserCard'

// Custom node view component
const Mention = (props) => (
  <NodeViewWrapper style={{ display: 'inline' }}>
    <Box component="span" sx={{ display: 'inline-flex', backgroundColor: 'primary.200', px: 0.5, borderRadius: 1 }}>
      <UserCardPopper userId={props.node.attrs.id}>
        <Typography component="span" color="primary.main">
          @{props.node.attrs.label}
        </Typography>
      </UserCardPopper>
    </Box>
  </NodeViewWrapper>
)

// Custom mention popup component
const UsersList = React.forwardRef(({ clientRect, command, query: search }, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const referenceEl = useMemo(() => (clientRect ? { getBoundingClientRect: clientRect } : null), [clientRect])
  const debouncedSearch = useDebounce(search, 300)

  const query = { status: 'active', search_term: debouncedSearch, limit: 10 }

  const { data } = useQuery({
    queryKey: SystemUsersKeys.list(query),
    queryFn: () => SystemUsersApi.list(query),
    placeholderData: keepPreviousData,
  })

  // Reset selectedIndex when data changes
  useEffect(() => setSelectedIndex(0), [data])

  const selectItem = (index) => {
    const item = data?.[index]
    if (item) command({ id: item.id, label: item.fullName })
  }

  const upHandler = () => setSelectedIndex((selectedIndex + data?.length - 1) % data?.length)
  const downHandler = () => setSelectedIndex((selectedIndex + 1) % data?.length)
  const enterHandler = () => selectItem(selectedIndex)

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        downHandler()
        return true
      }

      if (event.key === 'Enter') {
        enterHandler()
        return true
      }

      return false
    },
  }))

  return (
    <Popper
      id="check"
      placement="top-start"
      open={Boolean(clientRect) && data?.length > 0}
      anchorEl={referenceEl}
      transition
      sx={{ zIndex: 1301 }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps}>
          <Paper>
            <List dense disablePadding sx={{ width: '100%', maxWidth: 400 }}>
              {data?.map((user, index) => (
                <ListItemButton key={index} divider selected={selectedIndex === index} onClick={() => selectItem(index)}>
                  <Avatar user={user} size="xs" />
                  <ListItemText sx={{ pl: 1 }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="flex-end" spacing={2}>
                      <Typography color="text.primary" noWrap>
                        {user.fullName}
                      </Typography>
                      <Typography component="span" color="text.secondary" variant="body2">
                        {userRoleToLabel[user.role]}
                      </Typography>
                    </Stack>
                  </ListItemText>
                </ListItemButton>
              ))}
            </List>
          </Paper>
        </Fade>
      )}
    </Popper>
  )
})

const renderUsersPopup = () => {
  let component

  return {
    onStart: (props) => {
      component = new ReactRenderer(UsersList, {
        props,
        editor: props.editor,
      })
    },

    onUpdate(props) {
      component?.updateProps(props)
    },

    onKeyDown(props) {
      if (props.event.key === 'Escape') {
        component?.destroy()
        return true
      }

      return component.ref?.onKeyDown(props)
    },

    onExit() {
      component.destroy()
    },
  }
}

// Extend and configure the mention extension
const MentionExtension = MentionExtensionBase.extend({
  addNodeView() {
    return ReactNodeViewRenderer(Mention)
  },
  parseHTML() {
    return [{ tag: 'mention-component' }]
  },
  renderHTML({ HTMLAttributes }) {
    return ['mention-component', mergeAttributes(HTMLAttributes)]
  },
}).configure({
  deleteTriggerWithBackspace: true,
  suggestion: { allowSpaces: true, render: renderUsersPopup },
})

export default MentionExtension
