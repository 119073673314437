import { useLocation, useNavigate } from 'react-router-dom'
import { keepPreviousData, useInfiniteQuery, useMutation } from '@tanstack/react-query'

import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import { flatten, mapCache, pageParam } from '@shared/utils'

import UserNotificationsApi, { UserNotificationsKeys } from '@services/UserNotifications'
import { NotificationType } from '@pages/Notifications/Notifications.utils'

const LIMIT = 20

export function useUserNotifications(params = {}, options = {}) {
  const me = useMe()
  const query = { ...params, limit: LIMIT }
  return useInfiniteQuery({
    queryKey: UserNotificationsKeys.list(me.id, query),
    queryFn: ({ pageParam }) => UserNotificationsApi.list(me.id, { ...query, offset: pageParam * LIMIT }),
    placeholderData: keepPreviousData,
    select: flatten,
    initialPageParam: 0,
    getNextPageParam: pageParam(LIMIT),
    ...options,
  })
}

export function useNotificationUpdate() {
  const me = useMe()
  return useMutation({
    mutationFn: ({ id }) => UserNotificationsApi.update(me.id, id),
    onSuccess: (notification, { id }) => {
      return queryClient.setQueriesData(
        { queryKey: UserNotificationsKeys.lists(me.id) },
        mapCache((item) => (item.id === id ? { ...item, ...notification } : item))
      )
    },
  })
}

export function useMarkAllRead() {
  const me = useMe()
  return useMutation({
    mutationFn: () => UserNotificationsApi.markAllRead(me.id),
    onSuccess: () => {
      return queryClient.setQueriesData(
        { queryKey: UserNotificationsKeys.lists(me.id) },
        mapCache((item) => ({ ...item, read: true }))
      )
    },
  })
}

export function useNotificationOnClick(notification) {
  const navigate = useNavigate()
  const currentPath = useLocation().pathname
  const update = useNotificationUpdate()

  const isVisitPage = currentPath.startsWith('/app/visits')

  return () => {
    if (!notification) return

    if (notification.read === false) update.mutate({ id: notification.id })

    if (notification.type === NotificationType.Message && notification.properties?.patientId) {
      const chatPath = `/app/patients/${notification.properties.patientId}?patientTab=chat`
      if (isVisitPage) {
        window.open(`${import.meta.env.VITE_URL}${chatPath}`, '_blank')
      } else {
        navigate(chatPath)
      }
    }
    if (notification.type === NotificationType.DoseSpot && notification.properties?.url) {
      window.open(notification.properties.url, '_blank')
    }
  }
}
