import { createContext, useContext, useMemo } from 'react'
import { deserialize } from 'deserialize-json-api'
import { chain } from 'lodash'
import { useLocalStorage } from 'usehooks-ts'

import useQuery from '@shared/hooks/src/useQuery'
import { useQueryEvents } from '@shared/hooks/src/useQueryEvents'
import SettingsSharedApi from '@shared/services/src/Settings.api'
import { featureFlags as featureFlagsMock } from '@shared/services/src/Settings.mocks'

/**
 * Provide a context for the apps infrastructure feature flags.
 */
export default function FeatureFlagsProvider({ children }) {
  const [, setPersistedCache] = useLocalStorage('cache-persisted', true)

  const query = useQuery({
    queryKey: ['feature-flags'],
    queryFn: SettingsSharedApi.featureFlags,
  })

  useQueryEvents(query, {
    onSuccess: (data) => {
      const cachePersistedFlag = data.find((f) => f.key === FeatureFlag.ReactCachePersisted)
      if (cachePersistedFlag) {
        setPersistedCache(cachePersistedFlag.value)
      }
    },
  })

  return (
    <FeatureFlagsContext.Provider value={{ flags: query.data, isRefreshing: query.isRefreshing, isFetching: query.isFetching }}>
      {children}
    </FeatureFlagsContext.Provider>
  )
}

export function FeatureFlagsMockerProvider({ children, flags = featureFlagsMock }) {
  const flagsMapped = deserialize(flags, { transformKeys: 'camelCase' })?.data ?? []

  return (
    <FeatureFlagsContext.Provider value={{ flags: flagsMapped, isRefreshing: false, isFetching: false }}>
      {children}
    </FeatureFlagsContext.Provider>
  )
}

const FeatureFlagsContext = createContext(undefined)

export const useFeatureFlagsQuery = () => useContext(FeatureFlagsContext)

/**
 * Returns a map of feature flags with the key as the flag name and the boolean as the flag value.
 *
 * @example:
 * const flags = useFeatureFlags()
 * const isFeature1Enabled = flags[FeatureFlag.Feature1]
 * const isFeature2Enabled = flags[FeatureFlag.Feature2]
 *
 * @returns {{ [p: string]: boolean }}
 */
export const useFeatureFlags = () => {
  const { flags } = useContext(FeatureFlagsContext)

  return useMemo(() => {
    return chain(flags)
      .groupBy('key')
      .mapValues((v) => v[0].value)
      .value()
  }, [flags])
}

/**
 * Returns a boolean value for the given feature flag.
 *
 * @example:
 * const isFeature1Enabled = useFeatureFlag(FeatureFlag.Feature1)
 *
 * @param key - The feature flag key
 * @returns {boolean} - The feature flag value
 */
export const useFeatureFlag = (key) => {
  const flags = useFeatureFlags()
  return flags[key] || false
}

export const FeatureFlag = {
  PatientSelectTreatment: 'patient_select_treatment',
  ReactCachePersisted: 'react_cache_persisted',
  ContractedPharmacies: 'contracted_pharmacies',
  DoseSpotEnabled: 'dosespot_enabled',
  CBOsOnReact: 'cbos_on_react',
  SystemUsersOnReact: 'system_users_on_react',
  ProvidersOnReact: 'providers_on_react',
}
