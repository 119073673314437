import { Fade, Grid } from '@mui-components'

import Edit from './Edit'
import Preview from './Preview'
import Range from './Range'

export default function Availability() {
  return (
    <Fade in>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Range />
            </Grid>
            <Grid item xs={12}>
              <Edit />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Preview />
        </Grid>
      </Grid>
    </Fade>
  )
}
