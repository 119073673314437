import { keepPreviousData, useInfiniteQuery, useMutation } from '@tanstack/react-query'

import useQuery from '@shared/hooks/src/useQuery'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import AppointmentsApi, { AppointmentKey, AppointmentNotesKeys } from '@shared/services/src/Appointments.api'
import { flatten, handleError, pageParam } from '@shared/utils'

import { PatientEncountersKeys } from '@services/PatientEncounters.api'

export function useAppointment(appointmentId, options = {}) {
  return useQuery({
    queryKey: AppointmentKey(appointmentId),
    queryFn: () => AppointmentsApi.appointment(appointmentId),
    ...options,
  })
}

const NOTES_LIMIT = 5

export function useAppointmentNotes(appointmentId) {
  const query = { limit: NOTES_LIMIT }
  return useInfiniteQuery({
    queryKey: AppointmentNotesKeys.list(appointmentId, query),
    queryFn: ({ pageParam }) => AppointmentsApi.notes(appointmentId, { ...query, offset: pageParam * NOTES_LIMIT }),
    placeholderData: keepPreviousData,
    enabled: Boolean(appointmentId),
    select: flatten,
    initialPageParam: 0,
    getNextPageParam: pageParam(NOTES_LIMIT),
  })
}

export function useAppointmentNoteCreation(appointmentId, onCreate) {
  return useMutation({
    mutationFn: (message) => AppointmentsApi.createNote(appointmentId, message),
    onSuccess: () => {
      onCreate?.()
      return queryClient.invalidateQueries({ queryKey: AppointmentNotesKeys.lists(appointmentId) })
    },
    onError: handleError,
  })
}

export const invalidateEncounterNotes = (appointment) => {
  if (!appointment) return

  const patientId = appointment?.userId
  const encounterId = appointment?.encounterId

  if (!patientId || !encounterId) return

  queryClient.invalidateQueries({ queryKey: PatientEncountersKeys.notesLists(patientId, encounterId) })
}
