import { Link as RouterLink } from 'react-router-dom'
import dayjs from 'dayjs'

import usePagesHistory, { PageHistoryType } from '@hooks/usePagesHistory'
import { Box, Button, List, ListItemButton, Popover, Stack, Typography } from '@mui-components'
import Avatar from '@components/Avatar'

export default function PagesHistoryPopover({ anchorEl, open = false, onClose }) {
  const { history, clear } = usePagesHistory()

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      onClose={onClose}
      open={open}
      slotProps={{ paper: { sx: { width: '360px' } } }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      <Stack direction="row" spacing={2} sx={{ alignItems: 'center', justifyContent: 'space-between', px: 3, py: 2 }}>
        <Typography variant="h4">History</Typography>
        <Button onClick={clear} size="small" sx={{ textTransform: 'none' }}>
          Clear
        </Button>
      </Stack>
      {history.length === 0 ? (
        <Box sx={{ p: 2 }}>
          <Typography variant="h5" color="text.secondary" sx={{ height: 50, fontWeight: 'normal', textAlign: 'center' }}>
            Nothing to see here!
          </Typography>
        </Box>
      ) : (
        <Box sx={{ maxHeight: '360px', overflowY: 'auto' }}>
          <List disablePadding>
            {history.map((item, index) => (
              <HistoryItem key={item.id} divider={index < history.length - 1} item={item} onClose={onClose} />
            ))}
          </List>
        </Box>
      )}
    </Popover>
  )
}

function HistoryItem({ divider, item, onClose }) {
  if (item.type === PageHistoryType.Patient) {
    return (
      <ListItemButton
        divider={divider}
        component={RouterLink}
        to={`/app/patients/${item.data.id}`}
        onClick={onClose}
        sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <Avatar user={item.data} size="lg" />
          <Stack spacing={-0.5} sx={{ display: 'grid' }}>
            <Typography noWrap>{item.data.fullName}</Typography>
            <Typography component="span" color="text.secondary" variant="body2">
              Patient {item.data.id}
            </Typography>
            <Typography color="text.secondary" variant="caption">
              Last Opened: {dayjs(item.createdAt).format('MMM D, hh:mm A')}
            </Typography>
          </Stack>
        </Stack>
      </ListItemButton>
    )
  }

  return null
}
