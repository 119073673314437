import { useCallback, useState } from 'react'
import { useEventListener } from 'usehooks-ts'

/**
 * Returns true if the user has interacted with the page.
 * This is useful to prevent playing sounds before the user has interacted with the page.
 */
export default function useHasInteracted() {
  const [hasInteracted, setHasInteracted] = useState(false)

  const listener = useCallback(() => {
    if (!hasInteracted) {
      setHasInteracted(true)
    }
  }, [hasInteracted])

  useEventListener('click', listener, undefined, { once: true })
  useEventListener('touchstart', listener, undefined, { once: true })
  useEventListener('touchend', listener, undefined, { once: true })
  useEventListener('keydown', listener, undefined, { once: true })

  return hasInteracted
}
