import { useCallback, useEffect, useRef } from 'react'
import { Howl } from 'howler'
import debounce from 'lodash/debounce'
import { useLocalStorage } from 'usehooks-ts'

import useHasInteracted from './useHasInteracted'

/**
 * Plays a sound only if the user has interacted with the page
 * because browsers don't allow audio to play until that.
 *
 * If the user hasn't interacted, the sound will NOT be queued but missed.
 *
 * @param howlProps - Howl props, @see - https://github.com/goldfire/howler.js#documentation
 * @param debounceOptions - Debounce options, @see - https://lodash.com/docs/4.17.15#debounce
 * @param debounceWait - Debounce wait time, @see - https://lodash.com/docs/4.17.15#debounce
 *
 * @returns {function(): void} - Play sound function
 */
export default function useSound({ howlProps, debounceOptions = { leading: true, trailing: false }, debounceWait = 500 }) {
  const audioRef = useRef()
  const hasInteracted = useHasInteracted()
  const [notificationsSound] = useLocalStorage('notifications-sound', true)

  useEffect(() => {
    if (!hasInteracted) return

    // Create a new Howl instance only after the user has interacted
    let audio = new Howl(howlProps)
    audioRef.current = audio

    return () => audio.unload()
  }, [hasInteracted]) // eslint-disable-line react-hooks/exhaustive-deps

  // Debounce the play function to avoid playing the sound multiple times
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    debounce(
      () => {
        if (!notificationsSound) return
        return audioRef.current?.play()
      },
      debounceWait,
      debounceOptions
    ),
    [notificationsSound]
  )
}
