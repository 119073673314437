import usePopover from '@shared/hooks/src/usePopover'

import { PagesHistoryPopover } from '@pages/PagesHistory'
import { HistoryOutlinedIcon } from '@icons'
import { Box, IconButton } from '@mui-components'

export default function PagesHistory() {
  const popover = usePopover()
  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        id="pages-history-button"
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        color="secondary"
        aria-label="Pages History"
        data-testid="header-pages-history"
        sx={{ color: 'text.primary' }}
      >
        <HistoryOutlinedIcon />
      </IconButton>
      <PagesHistoryPopover anchorEl={popover.anchorRef.current} onClose={popover.handleClose} open={popover.open} />
    </Box>
  )
}
