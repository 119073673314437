import ReactQuill, { Quill } from 'react-quill'
import ImageCompress from 'quill-image-compress'

import 'react-quill/dist/quill.snow.css'
import './Content.css'

import { useController } from 'react-hook-form'

import { FormHelperText, Stack } from '@mui-components'

Quill.register('modules/imageCompress', ImageCompress)

export default function Content({ isSubmitting }) {
  const { field, fieldState } = useController({ name: 'content' })

  return (
    <Stack spacing={1}>
      <ReactQuill
        theme="snow"
        ref={field.ref}
        readOnly={isSubmitting}
        value={field.value}
        onChange={field.onChange}
        onBlur={field.onBlur}
        modules={modules}
        formats={formats}
      />
      {fieldState.isTouched && Boolean(fieldState.error) && <FormHelperText error>{fieldState.error.message}</FormHelperText>}
    </Stack>
  )
}

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    ['link'],
    ['blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }, { align: ['', 'center', 'right', 'justify'] }],
    [{ indent: '-1' }, { indent: '+1' }],
    ['image'],
  ],
  imageCompress: {
    quality: 0.8,
    maxWidth: 1500,
    maxHeight: 1500,
  },
}

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'list',
  'align',
  'bullet',
  'indent',
  'link',
  'image',
  'blockquote',
  'code-block',
]
