import dayjs from 'dayjs'
import { chain } from 'lodash'
import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  timezone: Yup.string().required('Required'),
  provider: Yup.object().nullable(),
  type: Yup.string().required('Required'),
  date: Yup.object().required('Required'),
  timeslot: Yup.object().required('Required').nullable(),
})

export const getInitialValues = ({ provider, appointment }) => {
  const slot = appointmentToSlot(appointment)

  return {
    timezone: 'provider',
    provider,
    type: appointment?.type || 'video',
    date: dayjs(slot?.date),
    timeslot: slot,
  }
}

export function toAPIData(values) {
  return {
    schedule_type: 'scheduled',
    appointment_type: values.type,
    provider_id: values.timeslot.providerId,
    availability_id: values.timeslot.availabilityId,
    time: values.timeslot.timeSlot,
  }
}

export const appointmentToSlot = (appointment) => {
  if (!appointment) return null

  const date = dayjs(appointment?.start).tz(appointment?.provider?.timezone)
  return {
    date: date.format('YYYY-MM-DD'),
    timeSlot: date.format(),
    provider: appointment?.provider,
    providerId: appointment?.provider?.id,
    current: true,
  }
}

const MORNING_END_HOUR = 12
const AFTERNOON_END_HOUR = 18

const getTimeSlotPeriod = (time, timezone) => {
  const hour = dayjs(time).tz(timezone).hour()
  if (hour < MORNING_END_HOUR) return 'morning'
  if (hour < AFTERNOON_END_HOUR) return 'afternoon'
  return 'evening'
}

export const splitTimesIntoPeriods = (slots, timezone) => {
  return slots.reduce(
    (periods, slot) => {
      const period = getTimeSlotPeriod(slot.timeSlot, timezone)
      periods[period].push(slot)
      return periods
    },
    { morning: [], afternoon: [], evening: [] }
  )
}

export const mapData = (data) => {
  const slotsMapByProviderId = chain(data)
    .filter((slot) => dayjs().isBefore(dayjs(slot.timeSlot)))
    .map((slot) => ({ ...slot, id: `${slot.providerId}_${slot.availabilityId}_${slot.timeSlot}` }))
    .sort((a, b) => a.timeSlot.localeCompare(b.timeSlot))
    .groupBy('providerId')
    .value()

  return chain(data)
    .map('provider')
    .uniqBy('id')
    .map((provider) => ({ ...provider, slots: slotsMapByProviderId[provider.id] }))
    .filter((provider) => provider.slots?.length)
    .value()
}
