import dayjs from 'dayjs'
import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  title: Yup.string().max(50, 'Cannot be longer than 50 characters').required('Title is required'),
  policy: Yup.string().required('Policy is required'),
  categories: Yup.array().of(Yup.string()),
  version: Yup.string().max(10, 'Cannot be longer than 10 characters').required('Version is required'),
  supersedesDate: Yup.lazy(() =>
    Yup.date().min('2018-01-01', `Must be after 01/01/2018`).max(new Date(), `Must be today or older`).nullable().typeError('Invalid Date')
  ),
  approvalDate: Yup.lazy(() =>
    Yup.date().min('2018-01-01', `Must be after 01/01/2018`).max(new Date(), `Must be today or older`).nullable().typeError('Invalid Date')
  ),
  originalDate: Yup.lazy(() =>
    Yup.date().min('2018-01-01', `Must be after 01/01/2018`).max(new Date(), `Must be today or older`).nullable().typeError('Invalid Date')
  ),
  approvers: Yup.array(),
  content: Yup.string()
    .required('Content is required')
    .test('content', 'Content is required', (value) => value !== '<p><br></p>'),
})

export function getInitialValues(data) {
  return {
    title: data?.title || '',
    policy: data?.policy || '',
    categories: data?.categories || [],
    version: data?.version || '',
    supersedesDate: data?.supersedesDate ? dayjs(data.supersedesDate) : null,
    approvalDate: data?.approvalDate ? dayjs(data.approvalDate) : null,
    originalDate: data?.originalDate ? dayjs(data.originalDate) : null,
    approvers: data?.approvers || [],
    content: data?.content || '',
  }
}

export function toAPIFormat(values) {
  return {
    title: values.title,
    policy: values.policy,
    categories: values.categories,
    version: values.version,
    supersedes_date: values.supersedesDate ? dayjs(values.supersedesDate).format('YYYY-MM-DD') : null,
    approval_date: values.approvalDate ? dayjs(values.approvalDate).format('YYYY-MM-DD') : null,
    original_date: values.originalDate ? dayjs(values.originalDate).format('YYYY-MM-DD') : null,
    approvers: values.approvers.map((approver) => approver.id),
    content: values.content,
  }
}
