import { useMemo } from 'react'

import { Skeleton, Stack, Typography } from '@mui-components'

import { Section } from '../CBORuleSet.utils'

export default function Services({ data, isLoading }) {
  return (
    <Section paper title="Services Offered at CBO">
      <Stack spacing={1}>
        <BooleanProperty
          isLoading={isLoading}
          label="Hep C screen & treat"
          value={data?.additionalServices?.hepCScreenAndTreat?.value}
          info={data?.additionalServices?.hepCScreenAndTreat?.info}
        />
        <BooleanProperty
          isLoading={isLoading}
          label="Longitudinal HIV Care"
          value={data?.additionalServices?.longitudinalHIVCare?.value}
          info={data?.additionalServices?.longitudinalHIVCare?.info}
        />
        <BooleanProperty
          isLoading={isLoading}
          label="In person clinic/health center"
          value={data?.additionalServices?.inPersonHealthCenter?.value}
          info={data?.additionalServices?.inPersonHealthCenter?.info}
        />
        <BooleanProperty
          isLoading={isLoading}
          label="Labs & lab results in person"
          value={data?.additionalServices?.labsInPerson?.value}
          info={data?.additionalServices?.labsInPerson?.info}
        />
        <BooleanProperty
          isLoading={isLoading}
          label="Behavior Health"
          value={data?.additionalServices?.behaviorHealth?.value}
          info={data?.additionalServices?.behaviorHealth?.info}
        />
        <BooleanProperty
          isLoading={isLoading}
          label="Mental Health"
          value={data?.additionalServices?.mentalHealth?.value}
          info={data?.additionalServices?.mentalHealth?.info}
        />
        <BooleanProperty
          isLoading={isLoading}
          label="Housing"
          value={data?.additionalServices?.housing?.value}
          info={data?.additionalServices?.housing?.info}
        />
        <BooleanProperty
          isLoading={isLoading}
          label="Nutrition & Food Insecurity"
          value={data?.additionalServices?.foodInsecurity?.value}
          info={data?.additionalServices?.foodInsecurity?.info}
        />
      </Stack>
    </Section>
  )
}

function BooleanProperty({ label, value, info, isLoading }) {
  const content = useMemo(() => {
    if (isLoading) return <Skeleton width={120} />
    if (value === true) return 'Yes'
    if (value === false) return 'No'
    return '-'
  }, [value, isLoading])

  return (
    <Stack direction="row">
      <Typography variant="h5" fontWeight="medium" sx={{ minWidth: 235 }}>
        {label}
      </Typography>
      <Typography variant="h5" fontWeight="medium" color={value ? 'text.primary' : 'text.secondary'} sx={{ minWidth: 50 }}>
        {content}
      </Typography>
      {info && (
        <Typography noWrap variant="h5" fontWeight="medium">
          {info}
        </Typography>
      )}
    </Stack>
  )
}
