import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import api from '@shared/services'
import { keyToString } from '@shared/utils'

export const UserKeys = {
  // ['users', 'lists']
  lists: ['users', 'lists'],

  // ['users', 'lists', { ...query }]
  list: (query) => [...UserKeys.lists, query],

  // ['user', userId]
  user: (userId) => keyToString(['user', userId]),
}

export const MeKey = (userId) => keyToString(['me', userId])

const UsersApi = {
  me: (config) => api.get('/me', config).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data),
  user: (userId, config) => api.get(`/users/${userId}`, config).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data),
  users: (query, config) =>
    api
      .get(QS.stringifyUrl({ url: '/users', query }), config)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
}

export default UsersApi
