import { useMemo, useRef } from 'react'
import dayjs from 'dayjs'

export const FilterType = {
  SELECT: 'select',
  MULTI_SELECT: 'multi-select',
  DATE_RANGE: 'date-range',
  DATE: 'date',
  TEXT_HIDDEN: 'text-hidden',
  // NUMBER: 'number',
  // NUMBER_RANGE: 'number-range',
}

export function useChips({ schema, values, onChange }) {
  const handleChange = useRef(onChange).current

  return useMemo(() => {
    return schema.reduce((acc, { key, label, type, options, defaultValue, textTransform }) => {
      const value = values[key]
      if ([null, undefined, ''].includes(value) || (Array.isArray(value) && value.length === 0)) return acc

      if (type === FilterType.SELECT) {
        const isDefault = value === defaultValue
        const item = options.find((o) => o.value === value)
        if (item.hidden) return acc
        return [
          ...acc,
          {
            key,
            label,
            onDelete: !isDefault ? () => handleChange({ [key]: undefined }) : undefined,
            value: item?.label ?? value,
            disabled: !item,
          },
        ]
      }
      if (type === FilterType.MULTI_SELECT) {
        return [
          ...acc,
          ...value.map((v) => {
            const item = options.find((o) => o.value === v)
            return {
              key,
              label,
              onDelete: () => {
                const newArray = value.filter((vv) => vv !== v)
                return handleChange({ [key]: newArray.length > 0 ? newArray : undefined })
              },
              value: item?.label ?? v,
              disabled: !item,
            }
          }),
        ]
      }
      if (type === FilterType.DATE_RANGE) {
        const isDefault = JSON.stringify(value) === JSON.stringify(defaultValue)
        const areDifferentYear = value[0].split('-')[0] !== value[1].split('-')[0]
        return [
          ...acc,
          {
            key,
            label,
            onDelete: !isDefault ? () => handleChange({ [key]: undefined }) : undefined,
            value: value.map((v) => dayjs(v, 'YYYY-MM-DD').format(areDifferentYear ? 'll' : 'MMM D')).join(' to '),
          },
        ]
      }
      if (type === FilterType.DATE) {
        return [
          ...acc,
          {
            key,
            label,
            onDelete: () => handleChange({ [key]: undefined }),
            value: dayjs(value, 'YYYY-MM-DD').format('L'),
          },
        ]
      }
      if (type === FilterType.TEXT_HIDDEN) {
        const isDefault = value === defaultValue
        return [
          ...acc,
          {
            key,
            label,
            onDelete: !isDefault ? () => handleChange({ [key]: undefined }) : undefined,
            value,
            textTransform,
          },
        ]
      }

      return acc
    }, [])
  }, [handleChange, schema, values])
}
