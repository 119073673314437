import { includesOneOfErrorMessages, sanitizeObject, sanitizeUrl } from '@shared/utils'

const blacklist = ['password']

export function reportAxiosError(error) {
  if (!window.honeybadger) return
  if (!error || !error.request || !error.config) return
  if (error.name === 'CanceledError') return

  // skip notifications for these errors
  if (includesOneOfErrorMessages(error, ['You are not authorized', 'User not authenticated'])) return
  if (includesOneOfErrorMessages(error, ['SOAP content validation failed'], { strict: true })) return

  const notification = mapAxiosErrorToNotificationObject(error)
  window.honeybadger.notify(error, notification)
}

function mapAxiosErrorToNotificationObject({ name, message, response, config }) {
  const method = config.method?.toUpperCase()
  const endpoint = `${method} ${config.url}`
  const status = [response?.status, response?.statusText].join(' ')
  const isHtmlResponse = typeof response?.data === 'string' && response?.data.startsWith('<!DOCTYPE html>')

  let fingerprint = undefined
  try {
    const url = new URL(config.baseURL + config.url)
    fingerprint = sanitizeUrl(`${method} ${url.pathname}`)
  } catch (e) {
    fingerprint = sanitizeUrl(endpoint)
  }

  let payload = undefined
  try {
    const parsedData = JSON.parse(config.data)
    payload = sanitizeObject(parsedData, blacklist)
  } catch {
    payload = config.data
  }

  return {
    name: name || 'AxiosError',
    message: `${fingerprint} -> ${response ? status : message}`,
    fingerprint,
    context: {
      name,
      status: response ? status : 'Response was not received',
      endpoint,
      message,
      payload,
      response: isHtmlResponse ? 'HTML Response skipped' : response?.data,
    },
  }
}
