import { useEffect } from 'react'

import { useLatestRef } from './useLatestRef'

/**
 * Hook to handle query events like onSuccess and onError
 *
 * @read https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose
 */
export function useQueryEvents(query, { onSuccess, onError }) {
  const onSuccessRef = useLatestRef(onSuccess)
  const onErrorRef = useLatestRef(onError)

  useEffect(() => {
    if (query.isSuccess && onSuccessRef.current) {
      onSuccessRef.current(query.data)
    }
  }, [onSuccessRef, query.data, query.isSuccess])

  useEffect(() => {
    if (query.isError && onErrorRef.current) {
      onErrorRef.current(query.error)
    }
  }, [onErrorRef, query.error, query.isError])
}
