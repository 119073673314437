import { useCallback, useEffect } from 'react'
import { useEventListener, useLocalStorage } from 'usehooks-ts'

import { Logger } from '@shared/utils'

const LIMIT = 10
const log = Logger('usePagesHistory')

export const PageHistoryType = {
  Patient: 'patient',
}

export default function usePagesHistory() {
  const [history, setHistory] = useLocalStorage('pages-history-v1', [])

  const add = useCallback(
    (type, data) => {
      setHistory((prev) => {
        const id = `${type}-${data.id}`
        const createdAt = new Date().toISOString()
        const newItem = { id, createdAt, type, data }

        const history = [newItem, ...prev.filter((item) => item.id !== id)]

        if (history.length > LIMIT) history.pop()

        return history
      })
    },
    [setHistory]
  )

  const clear = useCallback(() => {
    setHistory([])
  }, [setHistory])

  return { history, add, clear }
}

export function usePagesHistoryListener(type, data) {
  const { add } = usePagesHistory()

  useEffect(() => {
    if (data) {
      log.debug('Add to pages history as effect', { type, data })
      add(type, data)
    }
  }, [add, data, type])

  // add item to the history when returns back to the page
  useEventListener('visibilitychange', () => {
    const isVisible = !document.hidden
    if (isVisible) {
      log.debug('Add to pages history as visibilitychange', { type, data })
      add(type, data)
    }
  })
}
