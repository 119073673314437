import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import api from '@shared/services'

export const AdminCBOsKeys = {
  // ['admin-cbos']
  all: ['admin-cbos'],

  // ['admin-cbos', 'lists']
  lists: () => [...AdminCBOsKeys.all, 'lists'],

  // ['admin-cbos', 'lists', { ...query }]
  list: (query) => [...AdminCBOsKeys.lists(), query],
}

const AdminCBOsApi = {
  list: (query) =>
    api
      .get(QS.stringifyUrl({ url: `/admin/cbos`, query }, { arrayFormat: 'bracket' }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  updateStatus: (cboId, data) =>
    api
      .patch(`/admin/cbos/${cboId}/update_status`, data)
      .then((resp) => deserialize(resp.data, { transformKeys: 'camelCase' })?.data ?? {}),
}

export default AdminCBOsApi
