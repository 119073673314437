const styles = {
  paper: {
    textAlign: 'center',
    height: '100%',
    backgroundColor: 'primary.100',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    m: { xs: 1, sm: 3 },
    p: 2,
  },
  dialogContent: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: 'primary.100',
    pb: 4,
  },
  content: {
    maxWidth: 420,
  },
}

export default styles
