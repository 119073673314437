import PatientsSharedApi, { PatientKeys } from '@shared/services/src/Patients.api'

import useQuery from './useQuery'

export default function usePatient(id, options = {}) {
  return useQuery({
    queryKey: PatientKeys.patient(id),
    queryFn: () => PatientsSharedApi.patient(id),
    ...options,
  })
}
