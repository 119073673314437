import { useController } from 'react-hook-form'
import { DateCalendar } from '@mui/x-date-pickers-pro'

import { Paper } from '@mui-components'

export default function CalendarSection() {
  const dateField = useController({ name: 'date' })
  const timeslotField = useController({ name: 'timeslot' })

  return (
    <Paper variant="outlined" sx={{ display: 'flex', justifyContent: 'center' }}>
      <DateCalendar
        disablePast
        value={dateField.field.value}
        onChange={(v) => {
          dateField.field.onChange(v)
          // reset previously selected timeslot
          timeslotField.field.onChange(null)
        }}
        views={['day']}
      />
    </Paper>
  )
}
