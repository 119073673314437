export const getColors = (theme, color) => {
  switch (color) {
    case 'secondary':
      return theme.palette.secondary
    case 'error':
      return theme.palette.error
    case 'warning':
      return theme.palette.warning
    case 'info':
      return theme.palette.info
    case 'success':
      return theme.palette.success
    default:
      return theme.palette.primary
  }
}

export const getShadow = (theme, shadow) => {
  switch (shadow) {
    case 'secondary':
      return theme.customShadows.secondary
    case 'error':
      return theme.customShadows.error
    case 'warning':
      return theme.customShadows.warning
    case 'info':
      return theme.customShadows.info
    case 'success':
      return theme.customShadows.success
    case 'primaryButton':
      return theme.customShadows.primaryButton
    case 'secondaryButton':
      return theme.customShadows.secondaryButton
    case 'errorButton':
      return theme.customShadows.errorButton
    case 'warningButton':
      return theme.customShadows.warningButton
    case 'infoButton':
      return theme.customShadows.infoButton
    case 'successButton':
      return theme.customShadows.successButton
    default:
      return theme.customShadows.primary
  }
}

export const outlineSearchStyling = {
  '& .MuiOutlinedInput-root:not(.Mui-focused)': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
    '&:hover:not(.Mui-disabled)': {
      backgroundColor: 'grey.300',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
    },
  },
}

export const outlineRichTextEditorStyling = {
  m: 1,
  p: 1,
  border: '1px solid',
  borderColor: 'divider',
  borderRadius: 1,
  '&:focus-within': {
    outline: '1px solid',
    outlineColor: 'primary.light',
    borderColor: 'primary.light',
  },
}
