import { useMemo } from 'react'

import useUserSetting from '@shared/hooks/src/useUserSetting'
import { UserSettingName } from '@shared/utils'

export const useDateTimeLocale = (user) => {
  const timeFormatSetting = useUserSetting(user, UserSettingName.TimeFormat)
  const is24HourFormat = timeFormatSetting?.value === '24h'

  return useMemo(() => {
    const timeFormat = is24HourFormat ? 'HH:mm' : 'h:mm A'

    return {
      formats: {
        LT: timeFormat,
        LTS: is24HourFormat ? 'HH:mm:ss' : 'h:mm:ss A',
        L: 'MM/DD/YYYY',
        LL: 'MMMM D, YYYY',
        LLL: `MMMM D, YYYY ${timeFormat}`,
        LLLL: `dddd, MMMM D, YYYY ${timeFormat}`,
        // lowercase/short, optional formats for localization
        l: 'D/M/YYYY',
        ll: 'D MMM, YYYY',
        lll: `D MMM, YYYY ${timeFormat}`,
        llll: `ddd, MMM D, YYYY ${timeFormat}`,
      },
    }
  }, [is24HourFormat])
}
