import { useMutation } from '@tanstack/react-query'

import useQuery from '@shared/hooks/src/useQuery'
import { queryClient } from '@shared/providers/src/QueryClientProvider'

import CbosApi, { CBOKeys } from '@services/Cbos.api'

export function useCBORuleSets(cboId, options = {}) {
  return useQuery({
    queryKey: CBOKeys.ruleSets(cboId),
    queryFn: () => CbosApi.ruleSets(cboId),
    ...options,
  })
}

export function useCBORuleSetsUpdate(cboId) {
  return useMutation({
    mutationFn: (data) => CbosApi.updateRuleSets(cboId, data),
    onSuccess: (data) => queryClient.setQueryData(CBOKeys.ruleSets(cboId), data),
  })
}
