import { useState } from 'react'
import dayjs from 'dayjs'
import { PickersDay, StaticDatePicker } from '@mui/x-date-pickers-pro'

import { Stack } from '@mui-components'
import Tile from '@components/Tile'

import { useDateAvailability, useMonthAvailability } from '../Availability.hooks'
import AvailableItems from '../AvailableItems'
import { useAvailabilityCreate, useAvailabilityEdit, useAvailabilityRemove } from './Edit.hooks'

export default function Edit() {
  const [currentMonth, setCurrentMonth] = useState(dayjs().tz(window.timezone))
  const [selectedDate, setSelectedDate] = useState(dayjs().tz(window.timezone))

  const { data, isPending } = useMonthAvailability({
    month: currentMonth.month() + 1,
    year: currentMonth.year(),
  })

  const {
    data: dayData,
    isPending: isDayPending,
    isFetching: isDayFetching,
    isRefreshing: isDayRefreshing,
  } = useDateAvailability(selectedDate)

  const createAvailability = useAvailabilityCreate()
  const editAvailability = useAvailabilityEdit()
  const removeAvailability = useAvailabilityRemove()

  const handleAdd = ([startTime, endTime]) => {
    const data = {
      date_formatted: selectedDate.format('YYYY-MM-DD'),
      start_time_formatted: startTime,
      end_time_formatted: endTime,
    }
    return createAvailability(data)
  }

  const handleEdit = (id, [startTime, endTime]) => {
    const data = {
      id,
      date_formatted: selectedDate.format('YYYY-MM-DD'),
      start_time_formatted: startTime,
      end_time_formatted: endTime,
    }
    return editAvailability(data)
  }

  const CustomDay = (props) => {
    const day = props.day.format('YYYY-MM-DD')
    const hasAvailability = Boolean(data?.[day])
    return <PickersDay {...props} sx={hasAvailability ? { backgroundColor: (theme) => theme.palette.primary[100] } : {}} />
  }

  return (
    <Tile title="Edit daily availability" refreshing={isPending || isDayFetching || isDayRefreshing}>
      <Stack spacing={3} alignItems="center" sx={{ p: 2, pt: 4 }}>
        <StaticDatePicker
          disablePast
          disabled={isPending}
          onMonthChange={(date) => setCurrentMonth(date)}
          value={selectedDate}
          onChange={(newValue) => setSelectedDate(newValue)}
          displayStaticWrapperAs="desktop"
          views={['day']}
          slots={{ day: CustomDay }}
        />
        <AvailableItems
          key={selectedDate}
          dateRange={[selectedDate, selectedDate]}
          disabled={isDayRefreshing}
          loading={isDayPending}
          availability={dayData}
          onAdd={handleAdd}
          onEdit={handleEdit}
          onRemove={removeAvailability}
        />
      </Stack>
    </Tile>
  )
}
