/**
 * Get a test id for a component. This will attempt to determine if a testid was passed to the component.
 * A fallback value can be provided if not testid is present in the props
 *
 * @param {object} props - The props object for the component
 * @param {string} fallback - A value that will be returned if no testid is present in props
 * @return {string} The testid for the component
 */
export const getTestIdError = 'getTestId requires an object for the props argument'
export const getTestId = (props, fallback) => {
  if (!props || typeof props !== 'object') throw new Error(getTestIdError)

  if ('data-testid' in props) {
    const value = props['data-testid']
    // If we find the testid, it needs to be removed
    // If not and {...rest} is added to the component, the rest value will overwrite any retrieved via this function
    delete props['data-testid']
    return value
  }

  return fallback || ''
}

/**
 * Convert a hash to an array of objects
 * @param {object} hash - The hash to convert
 * @return {array} The array of objects
 * @example
 * const hash = {
 *   1: 'one',
 *   2: 'two',
 *   3: 'three',
 * }
 * const array = hashToArray(hash)
 * // array = [
 * //   { id: 1, value: 'one' },
 * //   { id: 2, value: 'two' },
 * //   { id: 3, value: 'three' },
 * // ]
 *
 */
export const hashToArrayError = 'hashToArray requires an object for the hash argument.'
export const hashToArray = (hash) => {
  if (!hash || typeof hash !== 'object') throw new Error(hashToArrayError)

  return Object.keys(hash).map((key) => {
    return {
      id: key,
      value: hash[key],
    }
  })
}
