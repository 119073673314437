import { useCallback, useMemo, useRef } from 'react'
import { bindHover } from 'material-ui-popup-state'

import { Avatar as AvatarMui } from '@mui-components'
import { getSizeStyle, mapUser } from '@components/Avatar/Avatar.utils'

// Disable eslint as a workaround to achieve the desired behavior to overwrite the event handlers of bindHover
/* eslint-disable react-hooks/rules-of-hooks */
export function bindDelayedHover(popupState, delayMs = 200) {
  const { onTouchStart, onMouseOver, onMouseLeave, ...hoverAriaProps } = bindHover(popupState)

  const timeout = useRef(null)

  const delayedMouseOver = useCallback(
    (e) => {
      if (timeout.current) clearTimeout(timeout.current)

      // material-ui-popup-state's event handler uses currentTarget to set the anchorEl, but
      // currentTarget is only defined while the initial event is firing. Save the original
      // and set it again before calling the delayed event handler
      const { currentTarget } = e
      timeout.current = setTimeout(() => {
        e.currentTarget = currentTarget
        onMouseOver(e)
      }, delayMs)
    },
    [delayMs, onMouseOver]
  )

  const handleMouseLeave = useCallback(
    (e) => {
      if (timeout.current) clearTimeout(timeout.current)
      onMouseLeave(e)
    },
    [onMouseLeave]
  )

  return {
    onTouchStart,
    onMouseOver: delayedMouseOver,
    onMouseLeave: handleMouseLeave,
    ...hoverAriaProps,
  }
}

/* eslint-enabled react-hooks/rules-of-hooks */

// Don't use @components/Avatar to prevent circular dependency
export function Avatar({ user, size }) {
  const { backgroundColor, initials } = useMemo(() => mapUser(user), [user])
  const sx = [getSizeStyle(size), { color: 'primary.contrastText', backgroundColor }]

  return (
    <AvatarMui src={user?.profilePictureThumbUrl} variant="rounded" sx={sx}>
      {initials}
    </AvatarMui>
  )
}
