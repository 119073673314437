import { SOPsDialog, SOPsDrawer, useSOPsFilters } from '@pages/StandardOperatingProcess'
import { PolicyOutlineIcon } from '@icons'
import { Box, IconButton } from '@mui-components'

export default function SOPs() {
  const [{ sops }, updateFilters] = useSOPsFilters()

  return (
    <>
      <Box sx={{ flexShrink: 0, ml: 0.75 }}>
        <IconButton
          id="sop-button"
          color="secondary"
          onClick={() => updateFilters({ sops: 'list' })}
          aria-label="SOPs toggler"
          data-testid="header-sops"
          sx={{ color: 'text.primary' }}
        >
          <PolicyOutlineIcon />
        </IconButton>
      </Box>
      <SOPsDrawer open={Boolean(sops)} onClose={() => updateFilters({ sops: undefined })} />
      <SOPsDialog open={['view', 'edit', 'create'].includes(sops)} onClose={() => updateFilters({ sops: 'list', sopId: undefined })} />
    </>
  )
}
