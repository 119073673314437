import useTheme from '@mui/styles/useTheme'

import { Button, Fade, Grid, Stack, Typography, useMediaQuery } from '@mui-components'

export default function AppUpdated() {
  const theme = useTheme()
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Fade in>
      <Grid
        container
        spacing={10}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh', p: 1, overflow: 'hidden' }}
      >
        <Grid item xs={12}>
          <Stack spacing={6} justifyContent="center" alignItems="center">
            <Stack spacing={2} justifyContent="center" alignItems="center">
              <Typography variant={matchDownSM ? 'h2' : 'h1'} align="center">
                New Updates Deployed!
              </Typography>
              <Typography color="textSecondary" align="center">
                We've made some improvements.
                <br />
                Reload the page to experience the latest features.
              </Typography>
            </Stack>
            <Button variant="contained" onClick={() => window.location.reload()}>
              Reload
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Fade>
  )
}
