import { deserialize } from 'deserialize-json-api'

export const all = {
  data: [
    {
      id: '1',
      type: 'dropdown_options',
      attributes: {
        id: 1,
        key: 'reasons_for_changing_status',
        value: [
          'Clinically Ineligible',
          'Duplicate Account',
          'No longer interested',
          'Out of network',
          'Out of service Area',
          'Outside HIV Care',
          'Patient not responsive',
          'Pharmacy Transferred to External',
          'Uninsured',
          'Violated Terms of Use',
        ],
      },
    },
    {
      id: '2',
      type: 'dropdown_options',
      attributes: {
        id: 2,
        key: 'no_longer_interested',
        value: [
          'Desires / requires injectable',
          'Local care provider',
          'Monogamous relationship',
          'Not sexually active',
          'Other telePrEP provider',
          'Patient dissatisfied',
        ],
      },
    },
    {
      id: '3',
      type: 'dropdown_options',
      attributes: {
        id: 3,
        key: 'incomplete_appt_reasons',
        value: ['Incomplete Visit', 'Ineligible', 'Patient Unavailable'],
      },
    },
    {
      id: '4',
      type: 'dropdown_options',
      attributes: {
        id: 4,
        key: 'technical_issues_reasons',
        value: ['Patient will reschedule', 'Seeks unavailable service', 'Technical Issues'],
      },
    },
    {
      id: '5',
      type: 'dropdown_options',
      attributes: {
        id: 5,
        key: 'provider_suffixes',
        value: ['AAHIVS', 'APRN', 'Care Coord', 'CNM', 'DO', 'LHC-MA', 'MD', 'NP', 'PA', 'RN'],
      },
    },
    {
      id: '6',
      type: 'dropdown_options',
      attributes: {
        id: 6,
        key: 'reasons_for_returning_incomplete_labs',
        value: [
          'Labs already resulted',
          'Labs in transit already',
          'Labs were returned to sender',
          'Other team member already reminded patient to send labs back',
          'Patient opted for in person labs/change in lab order',
          'Patient taking a PrEP break',
          'Pt communicated known delay in getting labs back',
          'Pt uploaded own labs',
          'Too soon to remind patient',
        ],
      },
    },
    {
      id: '8',
      type: 'dropdown_options',
      attributes: {
        id: 8,
        key: 'appointment_communication_types',
        value: ['CBO', 'Email', 'No Communication', 'Phone Call', 'Portal Message'],
      },
    },
    {
      id: '9',
      type: 'dropdown_options',
      attributes: {
        id: 9,
        key: 'patient_genders',
        value: ['Female', 'Gender Queer', 'Intersex', 'Male', 'Nonconforming', 'Transgender Female', 'Transgender Male'],
      },
    },
    {
      id: '10',
      type: 'dropdown_options',
      attributes: {
        id: 10,
        key: 'born_as',
        value: ['Female', 'Intersex', 'Male'],
      },
    },
    {
      id: '11',
      type: 'dropdown_options',
      attributes: {
        id: 11,
        key: 'timezones',
        value: [
          'Alaska',
          'America/Adak',
          'America/Anchorage',
          'America/Boise',
          'America/Detroit',
          'America/Indiana/Knox',
          'America/Indiana/Marengo',
          'America/Indiana/Petersburg',
          'America/Indiana/Tell_City',
          'America/Indiana/Vevay',
          'America/Indiana/Vincennes',
          'America/Indiana/Winamac',
          'America/Kentucky/Louisville',
          'America/Kentucky/Monticello',
          'America/Menominee',
          'America/Metlakatla',
          'America/Nome',
          'America/North_Dakota/Beulah',
          'America/North_Dakota/Center',
          'America/North_Dakota/New_Salem',
          'America/Sitka',
          'America/Yakutat',
          'Arizona',
          'Central Time (US \u0026 Canada)',
          'Eastern Time (US \u0026 Canada)',
          'Hawaii',
          'Indiana (East)',
          'Mountain Time (US \u0026 Canada)',
          'Pacific Time (US \u0026 Canada)',
        ],
      },
    },
    {
      id: '12',
      type: 'dropdown_options',
      attributes: {
        id: 12,
        key: 'encounter_types',
        value: {
          'Hep C': 4,
          'HIV Adherence': 8,
          'HIV Enrollment': 5,
          'HIV Follow Up': 7,
          'HIV Initial': 6,
          'Injectable PrEP': 10,
          PEP: 11,
          'PrEP Follow Up': 3,
          'PrEP Initial': 1,
          'PrEP Quarterly': 2,
          'STI Follow Up': 9,
        },
      },
    },
    {
      id: '13',
      type: 'dropdown_options',
      attributes: {
        id: 13,
        key: 'provider_types',
        value: [
          [1, 'PrEP'],
          [2, 'Hep C'],
          [3, 'HIV'],
          [4, 'STI-PEP'],
          [5, 'STI'],
          [6, 'Injectable PrEP'],
          [7, 'PEP'],
        ],
      },
    },
    {
      id: '14',
      type: 'dropdown_options',
      attributes: {
        id: 14,
        key: 'service_lines',
        value: [
          [1, 'PrEP'],
          [2, 'Hep C'],
          [3, 'HIV'],
          [5, 'STI'],
          [6, 'Injectable PrEP'],
          [7, 'PEP'],
        ],
      },
    },
    {
      id: '15',
      type: 'dropdown_options',
      attributes: {
        id: 15,
        key: 'patient_event_categories',
        value: [
          {
            kind: 'voice',
            label: 'Voice Call',
          },
          {
            kind: 'opt-in',
            label: 'Opt-in',
          },
          {
            kind: 'consent',
            label: 'Consent',
          },
          {
            kind: 'update',
            label: 'Update',
          },
          {
            kind: 'ma-task',
            label: 'MA Task',
          },
          {
            kind: 'cs-task',
            label: 'Support Task',
          },
          {
            kind: 'p-task',
            label: 'Provider Task',
          },
          {
            kind: 'ph-task',
            label: 'Pharmacy Task',
          },
          {
            kind: 'treatment-type',
            label: 'Treatment Type',
          },
          {
            kind: 'customer-support',
            label: 'Customer Support',
          },
          {
            kind: 'patient-assistance',
            label: 'Patient Assistance',
          },
          {
            kind: 'break-glass',
            label: 'Break Glass',
          },
          {
            kind: 'merge',
            label: 'Merge',
          },
          {
            kind: 'download',
            label: 'Download',
          },
          {
            kind: 'fax',
            label: 'Fax',
          },
        ],
      },
    },
    {
      id: '16',
      type: 'dropdown_options',
      attributes: {
        id: 16,
        key: 'lab_review_statuses',
        value: [
          ['Needs Follow-up', 'Needs Follow-up'],
          ['Needs Review', 'Needs Review'],
          ['Nurse Reviewed', 'Nurse Reviewed'],
          ['Ready for Patient', 'Ready'],
        ],
      },
    },
    {
      id: '17',
      type: 'dropdown_options',
      attributes: {
        id: 17,
        key: 'allergy_severities',
        value: ['intolerance', 'mild', 'moderate', 'not_sure', 'severe'],
      },
    },
    {
      id: '18',
      type: 'dropdown_options',
      attributes: {
        id: 18,
        key: 'vitals',
        value: {
          pulse_oximetry: '%',
          temperature: 'F',
          height: 'in',
          weight: 'lb',
          blood_glucose: 'mg/dL',
          bp: 'mmHg',
        },
      },
    },
    {
      id: '19',
      type: 'dropdown_options',
      attributes: {
        id: 19,
        key: 'lab_statuses',
        value: [
          'Cancelled',
          'Delivered To Patient',
          'Failed Delivery To Patient',
          'In Transit to Lab',
          'In Transit to Patient',
          'Lab Processing',
          'Lab Rejected',
          'Needs Follow-up',
          'Needs Review',
          'Nurse Reviewed',
          'Order Failed',
          'Ordered',
          'Pending',
          'Ready',
          'Received by Lab',
          'Requested',
          'Returned to Lab',
          'Shipment Pending',
          'Shipped',
        ],
      },
    },
    {
      id: '20',
      type: 'dropdown_options',
      attributes: {
        id: 20,
        key: 'rx_statuses_for_pharmacy',
        value: [
          ['Delivered', 'delivered'],
          ['Fill Scheduled', 'Fill Scheduled'],
          ['Filled at Other Pharmacy', 'Filled at Other Pharmacy'],
          ['Need Insurance Info', 'Need Insurance Info'],
          ['Need to Transfer Rx', 'Need to Transfer Rx'],
          ['On hold', 'On hold'],
          ['Patient Assistance Pending', 'Patient Assistance Pending'],
          ['Patient Calls in Progress', 'Patient Calls in Progress'],
          ['Pharmacy Follow-up', 'Pharmacy Follow-up'],
          ['Prior Authorization Required', 'Prior Authorization Required'],
          ['Rx Clarification Needed', 'Rx Clarification Needed'],
          ['Unable to reach patient', 'Unable to reach patient'],
          ['Uninsured', 'Uninsured'],
        ],
      },
    },
    {
      id: '21',
      type: 'dropdown_options',
      attributes: {
        id: 21,
        key: 'prior_auth_templates',
        value: ['Adherence', 'Bone Risk', 'General', 'Lactose', 'Low GFR', 'Renal Risk', 'TDF Intolerance'],
      },
    },
    {
      id: '22',
      type: 'dropdown_options',
      attributes: {
        id: 22,
        key: 'soap_lab_results_positive_for_sti',
        value: ['Chlamydia', 'Gonorrhea', 'Hepatitis B', 'Hepatitis C', 'HIV', 'Syphilis'],
      },
    },
    {
      id: '23',
      type: 'dropdown_options',
      attributes: {
        id: 23,
        key: 'ma_tasks',
        value: [
          {
            kind: 'Prior Auth',
            label: 'Prior Auth',
          },
          {
            kind: 'STI Report',
            label: 'STI Report',
          },
          {
            kind: 'RX Fill issue',
            label: 'Rx Fills',
          },
          {
            kind: 'Lab returns',
            label: 'Lab returns',
          },
          {
            kind: 'Lab Rejected',
            label: 'Lab Rejected',
          },
          {
            kind: 'Lab Failed',
            label: 'Lab Failed',
          },
        ],
      },
    },
    {
      id: '24',
      type: 'dropdown_options',
      attributes: {
        id: 24,
        key: 'cs_tasks',
        value: [
          {
            kind: 'Financial Assistance',
            label: 'Financial',
          },
          {
            kind: 'Contact Issue',
            label: 'Contact',
          },
          {
            kind: 'Insurance Exception',
            label: 'Insurance',
          },
          {
            kind: 'Address Change',
            label: 'Address',
          },
          {
            kind: 'IT Issue',
            label: 'IT',
          },
          {
            kind: 'Reschedule Appointment',
            label: 'Appt',
          },
          {
            kind: 'Rx Transfer',
            label: 'Rx Xfer',
          },
        ],
      },
    },
    {
      id: '25',
      type: 'dropdown_options',
      attributes: {
        id: 25,
        key: 'provider_tasks',
        value: [
          {
            kind: 'Gilead AA Application',
            label: 'Gilead AA Application',
          },
          {
            kind: 'Incomplete Labs',
            label: 'Incomplete Labs',
          },
          {
            kind: 'Rx Clarification',
            label: 'Rx Clarification',
          },
          {
            kind: 'Pending Quest Order',
            label: 'Pending Quest Order',
          },
        ],
      },
    },
    {
      id: '26',
      type: 'dropdown_options',
      attributes: {
        id: 26,
        key: 'pharmacy_tasks',
        value: [
          {
            kind: 'Prior Auth',
            label: 'Prior Auth',
          },
          {
            kind: 'Financial Assistance',
            label: 'Financial',
          },
          {
            kind: 'Contact Issue',
            label: 'Contact',
          },
          {
            kind: 'Insurance Exception',
            label: 'Insurance',
          },
          {
            kind: 'Address Change',
            label: 'Address',
          },
          {
            kind: 'Rx Transfer',
            label: 'Rx Xfer',
          },
          {
            kind: 'Task Comment',
            label: 'Task Comment',
          },
        ],
      },
    },
    {
      id: '27',
      type: 'dropdown_options',
      attributes: {
        id: 27,
        key: 'rx_required_tasks',
        value: [
          {
            kind: 'Prior Auth',
            label: 'Prior Auth',
          },
          {
            kind: 'Financial Assistance',
            label: 'Financial',
          },
          {
            kind: 'Insurance Exception',
            label: 'Insurance',
          },
          {
            kind: 'Rx Transfer',
            label: 'Rx Xfer',
          },
        ],
      },
    },
    {
      id: '28',
      type: 'dropdown_options',
      attributes: {
        id: 28,
        key: 'prior_auth_done_reasons',
        value: ['Appeal - approved', 'Appeal - denied', 'Approved', 'Denied', 'Denied - appealed', 'PA Not Needed', 'Unable to submit'],
      },
    },
    {
      id: '29',
      type: 'dropdown_options',
      attributes: {
        id: 29,
        key: 'reasons_pending_quest_order',
        value: ['Placed lab order in Quest', 'Unable to place lab order in Quest'],
      },
    },
    {
      id: '30',
      type: 'dropdown_options',
      attributes: {
        id: 30,
        key: 'par_done_reasons',
        value: [
          'No new flow sent - Other',
          'No new flow sent - Patient already received a new q flow',
          'No new flow sent - Patient not responsive',
          'No new flow sent - Patient taking a PrEP break',
          'Sent new QHA',
          'Sent new quarterly flow',
        ],
      },
    },
    {
      id: '31',
      type: 'dropdown_options',
      attributes: {
        id: 31,
        key: 'sop_categories',
        value: ['CBO', 'Customer Service', 'Hep C', 'Lab', 'LHC', 'Medical Assistant', 'PrEP', 'STI'],
      },
    },
    {
      id: '32',
      type: 'dropdown_options',
      attributes: {
        id: 32,
        key: 'sop_policies',
        value: ['Clinical', 'Operational', 'Support'],
      },
    },
    {
      id: '33',
      type: 'dropdown_options',
      attributes: {
        id: 33,
        key: 'task_expired_reasons',
        value: [
          'Duplicate task',
          'Patient has already provided insurance to the pharmacy',
          'Patient is inactive',
          'Patient uninsured ; CBO not accepting',
          'Patient will continue with transfer pharmacy',
          'Patient will resume with partner pharmacy next month',
          'Script has already been filled',
          'Task has already been completed',
        ],
      },
    },
    {
      id: '34',
      type: 'dropdown_options',
      attributes: {
        id: 34,
        key: 'prescription_statuses',
        value: [
          'Delivered',
          'Fill Scheduled',
          'Need Insurance Info',
          'Need to Transfer Rx',
          'On Hold',
          'Patient Assistance Pending',
          'Patient Calls in Progress',
          'Pending',
          'Prior Authorization Required',
          'Refills Needed',
          'Refills Remaining',
          'Rx Clarification Needed',
          'Sent to pharmacy',
          'Shipped',
          'Transferred',
          'Unable to reach patient',
          'With errors',
        ],
      },
    },
    {
      id: '35',
      type: 'dropdown_options',
      attributes: {
        id: 35,
        key: 'soap_reasons_for_medications',
        value: [
          'Concerns regarding side effects of alternatives',
          'Continuation of current therapy',
          'Contraindicated Truvada due to eGFR',
          'Costs concerns',
          'Drug interaction precluding use of Descovy',
          'Drug interaction precluding use of Truvada or generic',
          'Elevated risk of bone density loss',
          'Elevated risk of renal disease',
          'Insurance mandate',
          'Lactose intolerance',
          'Medical condition precluding use of Descovy',
          'Medical condition precluding use of Truvada or generic',
          'Past trial of Descovy with patient intolerance',
          'Past trial of Truvada with patient intolerance',
          'Patient born female',
          'Patient preference',
          'Patient requests 2-1-1 or on-demand PrEP dosing',
          'Tobacco Use',
          'Urgent need for nPEP',
        ],
      },
    },
  ],
  jsonapi: {
    version: '1.0',
  },
}
export const allDeserialized = deserialize(all, { transformKeys: 'camelCase' }).data
