import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import api from '@shared/services'

export const PhoneCallsKeys = {
  // ['phone-calls']
  all: ['phone-calls'],

  // ['phone-calls', 'lists']
  lists: ['phone-calls', 'lists'],

  // ['phone-calls', 'lists', { ...query }]
  list: (query) => [...PhoneCallsKeys.lists, query],

  // ['phone-calls', 'phone-token', { ...query }]
  token: (query) => ['phone-calls', 'phone-token', query],
}

const PhoneCallsApi = {
  list: (query) =>
    api.get(QS.stringifyUrl({ url: `/phone_calls`, query })).then((res) => {
      return deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []
    }),
  token: (query) => api.get(QS.stringifyUrl({ url: `/phone_calls/phone_token`, query })).then((resp) => resp.data),
}

export default PhoneCallsApi
