import useQuery from '@shared/hooks/src/useQuery'
import { useQueryEvents } from '@shared/hooks/src/useQueryEvents'

import ProvidersApi, { ProviderCBOsKey } from '@services/Providers.api'
import UsersApi, { UserCardKeys } from '@services/Users.api'

export function useUserCard(userId) {
  const query = useQuery({
    queryKey: UserCardKeys.user(userId),
    queryFn: () => UsersApi.userCard(userId, { skipHandling: true }),
    enabled: Boolean(userId),
  })

  useQueryEvents(query, {
    onSuccess: () => {
      // This is a hack to force the window to resize so that the user card can reposition itself
      // Useful when Popper is near the edge of the screen
      window.dispatchEvent(new Event('resize'))
    },
  })

  return query
}

export function useProviderCBOs(providerId, state, options = {}) {
  return useQuery({
    queryKey: ProviderCBOsKey(providerId, state),
    queryFn: () => ProvidersApi.cbos(providerId, { state }, { skipHandling: true }),
    ...options,
  })
}
