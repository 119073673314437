import { useLayoutEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { StringParam, useQueryParam } from 'use-query-params'

import useQuery from '@shared/hooks/src/useQuery'
import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import { MeKey } from '@shared/services/src/Users.api'

import { PharmacyKeys } from '@services/Pharmacies.api'
import UsersApi, { UserPharmaciesKey } from '@services/Users.api'

export function usePharmacies(userId, options = {}) {
  return useQuery({
    queryKey: UserPharmaciesKey(userId),
    queryFn: () => UsersApi.pharmacies(userId),
    ...options,
  })
}

export function usePharmacyUpdate() {
  const me = useMe()
  const navigate = useNavigate()

  return useMutation({
    mutationFn: (pharmacyId) => UsersApi.updatePharmacy(me.id, pharmacyId),
    onSuccess: (pharmacy) => {
      // Update the pharmacy and the user's pharmacyId in the cache
      // Then navigate to the app and invalidate the queries
      // !!! Navigation is required to make sure user is not located on restricted page with new pharmacy
      queryClient.setQueryData(PharmacyKeys.pharmacy(pharmacy.id), pharmacy)
      queryClient.setQueryData(MeKey(me.id), (prev) => ({ ...prev, pharmacyId: pharmacy.id }))
      navigate('/app', { replace: true })
      setTimeout(() => queryClient.invalidateQueries({ queryKey: undefined }), 0)
    },
  })
}

// Opens the pharmacy selector when the pharmacy query param is present
export function useSelectorState() {
  const [param, setParam] = useQueryParam('pharmacy', StringParam, { updateType: 'replaceIn' })

  const state = useState(false)
  const [, setOpen] = state

  useLayoutEffect(() => {
    if (Boolean(param)) {
      setParam(undefined)
      setOpen(true)
    }
  }, [param, setOpen, setParam])

  return state
}
