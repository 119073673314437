import { useMemo } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import { toTitleCase } from '@shared/utils'

import { LeftOutlinedIcon } from '@icons'
import { Link, Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui-components'

const NO_LINK_ROUTES = ['encounters']
const SKIP_PARENT_ROUTES = ['admin']

// TODO: This is a temporary solution until we do that through the routing
export default function Breadcrumbs({ content = null }) {
  const location = useLocation()

  const breadcrumbs = useMemo(() => {
    const paths = location.pathname.split('/').slice(2, -1)

    // Skip generating breadcrumbs for some root routes
    if (SKIP_PARENT_ROUTES.includes(paths[0])) return []

    return paths.map((route, index, routes) => {
      // Key numbers as numbers
      const name = /^\d+$/.test(route) ? route : toTitleCase(route)
      const fullPath = `/app/${routes.slice(0, index + 1).join('/')}`

      return {
        name,
        ...(NO_LINK_ROUTES.includes(route) ? { type: 'text' } : { to: fullPath }),
        'data-testid': `breadcrumb=${fullPath}`,
      }
    })
  }, [location.pathname])

  if (!breadcrumbs.length) return content

  return (
    <MuiBreadcrumbs aria-label="breadcrumb" separator={<LeftOutlinedIcon />} sx={{ pr: 2, color: 'text.primary' }}>
      <CustomLink component={RouterLink} to="/app">
        Home
      </CustomLink>
      {breadcrumbs.map(({ name, ...props }) => (
        <CustomLink key={name} {...props}>
          {name}
        </CustomLink>
      ))}
      {content}
    </MuiBreadcrumbs>
  )
}

function CustomLink({ type = 'link', ...args }) {
  if (type !== 'link') return <Typography variant="h3" fontWeight="medium" {...args} />
  return <Link variant="h3" sx={{ textDecoration: 'none', fontWeight: 'medium' }} component={RouterLink} {...args} />
}
