import { getFirstLetter, getInitials } from '@shared/utils'

export function getSizeStyle(size) {
  switch (size) {
    case 'badge':
      return {
        border: '2px solid',
        fontSize: '0.675rem',
        width: 20,
        height: 20,
        borderColor: 'background.default',
      }
    case 'xs':
      return {
        fontSize: '0.75rem',
        width: 24,
        height: 24,
      }
    case 'sm':
      return {
        fontSize: '0.875rem',
        width: 32,
        height: 32,
      }
    case 'lg':
      return {
        fontSize: '1.2rem',
        width: 52,
        height: 52,
      }
    case 'xl':
      return {
        fontSize: '1.5rem',
        width: 64,
        height: 64,
      }
    case 'xxl':
      return {
        fontSize: '1.5rem',
        width: 84,
        height: 84,
      }
    case 'md':
    default:
      return {
        fontSize: '1rem',
        width: 40,
        height: 40,
      }
  }
}

export function mapUser(user) {
  if (!user || !user.fullName) return { backgroundColor: 'background.default', initials: '' }

  const fullName = user.fullName
  const initials = getInitials(user)

  return { initials, backgroundColor: stringToColor(fullName) }
}

export function mapCBO(cbo) {
  if (!cbo || !cbo.name) return { backgroundColor: 'background.default', initials: '' }

  return { initials: getFirstLetter(cbo.name), backgroundColor: stringToColor(cbo.name) }
}

function stringToColor(string) {
  let hash = 0
  let i

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    let value = (hash >> (i * 8)) & 0xff
    // Limit the maximum value to avoid bright colors
    value = Math.floor(value * 0.65) // 0.65 is a factor to darken the color, adjust as needed
    color += `00${value.toString(16)}`.slice(-2)
  }

  return color
}
