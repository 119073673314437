import useQuery from '@shared/hooks/src/useQuery'

import PlatformSettingsApi, { BusinessHoursKey } from '@services/PlatformSettings.api'

export default function useBusinessHours() {
  return useQuery({
    queryKey: BusinessHoursKey,
    queryFn: PlatformSettingsApi.businessHours,
  })
}
