import { useMemo } from 'react'
import Grid from '@mui/material/Unstable_Grid2'

import { Skeleton, Stack, Typography } from '@mui-components'
import Property from '@components/Property'

import { CycleOptions, Section } from '../CBORuleSet.utils'

export default function Financials({ data, isLoading }) {
  const copayPrograms = useMemo(() => {
    if (isLoading) return <Skeleton width={50} />
    if (data?.financials?.copayAssistancePrograms) return 'Yes'
    if (data?.financials?.copayAssistancePrograms === false) return 'No'
    return '-'
  }, [data?.financials?.copayAssistancePrograms, isLoading])

  const cashSaleProgram340b = useMemo(() => {
    if (isLoading) return <Skeleton width={50} />
    if (data?.financials?.cashSaleProgram340b) return 'Yes'
    if (data?.financials?.cashSaleProgram340b === false) return 'No'
    return '-'
  }, [data?.financials?.cashSaleProgram340b, isLoading])

  const finCapInsured = useMemo(() => {
    if (isLoading) return <Skeleton width={50} />
    if (data?.financials?.financialCapAmount) return `$ ${data?.financials?.financialCapAmount}`
    return '-'
  }, [data?.financials?.financialCapAmount, isLoading])

  const finCapUninsured = useMemo(() => {
    if (isLoading) return <Skeleton width={50} />
    if (data?.financials?.financialCapUninsuredAmount) return `$ ${data?.financials?.financialCapUninsuredAmount}`
    return '-'
  }, [data?.financials?.financialCapUninsuredAmount, isLoading])

  return (
    <Section title="Financials">
      <Grid container spacing={1}>
        <Grid xs={4}>
          <Stack spacing={1}>
            <Property label="Copay programs" value={copayPrograms} />
            <Property label="340B Cash sale" value={cashSaleProgram340b} />
            <Property label="Fin cap insured" value={finCapInsured} />
            <Property label="Fin cap uninsured" value={finCapUninsured} />
          </Stack>
        </Grid>
        <Grid xs={8}>
          <Stack spacing={1}>
            <Typography fontWeight="bold">When Pharmacy is Tx outside of CBO pharmacy network, CBO will pay for:</Typography>
            <Property
              label="Patient Requests Transfer"
              value={isLoading ? <Skeleton width={100} /> : CycleOptions[data?.financials?.patientTransfers]}
            />
            <Property
              label="Payor Requires Transfer"
              value={isLoading ? <Skeleton width={100} /> : CycleOptions[data?.financials?.payorRequiresTransfer]}
            />
            <Property
              label="Fee for Service (Medicaid)"
              value={isLoading ? <Skeleton width={100} /> : CycleOptions[data?.financials?.feeForService]}
            />
          </Stack>
        </Grid>
      </Grid>
    </Section>
  )
}
