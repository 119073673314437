import { useCallback } from 'react'
import { useQueryParams } from 'use-query-params'

export default function useFiltering(params, { resetPageOnChange = false, pageKey = 'page', updateType = 'replaceIn' } = {}) {
  const [queryParams, updateQueryParams] = useQueryParams(params)

  const handleUpdate = useCallback(
    (changes, updateTypeOverride = updateType, resetPageOnChangeOverride = resetPageOnChange) => {
      const newState = resetPageOnChangeOverride ? { [pageKey]: undefined, ...changes } : changes
      return updateQueryParams(newState, updateTypeOverride)
    },
    [pageKey, resetPageOnChange, updateQueryParams, updateType]
  )

  return [queryParams, handleUpdate]
}
