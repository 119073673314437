export const isMobile = (() => {
  if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
    return false
  }
  return /Mobile/.test(navigator.userAgent)
})()

// Recursively removes any object keys with a value of undefined
export function removeUndefineds(obj) {
  if (!isPlainObject(obj)) return obj

  const target = {}

  for (const key in obj) {
    const val = obj[key]
    if (typeof val !== 'undefined') {
      target[key] = removeUndefineds(val)
    }
  }

  return target
}

export async function getDeviceInfo() {
  const devices = await navigator.mediaDevices.enumerateDevices()

  return {
    audioInputDevices: devices.filter((device) => device.kind === 'audioinput'),
    videoInputDevices: devices.filter((device) => device.kind === 'videoinput'),
    audioOutputDevices: devices.filter((device) => device.kind === 'audiooutput'),
    hasAudioInputDevices: devices.some((device) => device.kind === 'audioinput'),
    hasVideoInputDevices: devices.some((device) => device.kind === 'videoinput'),
  }
}

// This function will return 'true' when the specified permission has been denied by the user.
// If the API doesn't exist, or the query function returns an error, 'false' will be returned.
export async function isPermissionDenied(name) {
  if (navigator.permissions) {
    try {
      const result = await navigator.permissions.query({ name })
      return result.state === 'denied'
    } catch {
      return false
    }
  } else {
    return false
  }
}

function isObject(val) {
  return val != null && typeof val === 'object' && Array.isArray(val) === false
}

function isObjectObject(o) {
  return isObject(o) === true && Object.prototype.toString.call(o) === '[object Object]'
}

function isPlainObject(o) {
  let ctor, prot

  if (isObjectObject(o) === false) return false

  // If has modified constructor
  ctor = o.constructor
  if (typeof ctor !== 'function') return false

  // If has modified prototype
  prot = ctor.prototype
  if (isObjectObject(prot) === false) return false

  // If constructor does not have an Object-specific method
  if (prot.hasOwnProperty('isPrototypeOf') === false) {
    return false
  }

  // Most likely a plain Object
  return true
}

export function getMediaErrorDescription(error, hasAudio = true, hasVideo = true) {
  let title = ''
  let content = ''

  // eslint-disable-next-line default-case
  switch (true) {
    // These custom errors are thrown by the useLocalTracks hook. They are thrown when the user explicitly denies
    // permission to only their camera, or only their microphone.
    case error?.message === 'CameraPermissionsDenied':
      title = 'Unable to Access Media'
      content = 'The user has denied permission to use video. Please grant permission to the browser to access the camera.'
      break
    case error?.message === 'MicrophonePermissionsDenied':
      title = 'Unable to Access Media'
      content = 'The user has denied permission to use audio. Please grant permission to the browser to access the microphone.'
      break

    // This error is emitted when the user or the user's system has denied permission to use the media devices
    case error?.name === 'NotAllowedError':
      title = 'Unable to Access Media'

      if (error.message === 'Permission denied by system') {
        // Chrome only
        content =
          'The operating system has blocked the browser from accessing the microphone or camera. Please check your operating system settings.'
      } else {
        content =
          'The user has denied permission to use audio and video. Please grant permission to the browser to access the microphone and camera.'
      }

      break

    // This error is emitted when input devices are not connected or disabled in the OS settings
    case error?.name === 'NotFoundError':
      title = 'Cannot Find Microphone or Camera'
      content = 'The browser cannot access the microphone or camera. Please make sure all input devices are connected and enabled.'
      break

    // Other getUserMedia errors are less likely to happen in this app. Here we will display
    // the system's error message directly to the user.
    case Boolean(error):
      title = 'Error Acquiring Media'
      content = `${error.name} ${error.message}`
      break

    case !hasAudio && !hasVideo:
      title = 'No Camera or Microphone Detected'
      content = 'Other participants in the room will be unable to see and hear you.'
      break

    case !hasVideo:
      title = 'No Camera Detected'
      content = 'Other participants in the room will be unable to see you.'
      break

    case !hasAudio:
      title = 'No Microphone Detected'
      content = 'Other participants in the room will be unable to hear you.'
  }

  return { title, content }
}
