import { useMemo } from 'react'
import { useQuery as useQueryDefault } from '@tanstack/react-query'

/**
 * Hook to override or extend the react-query behaviour
 */
export default function useQuery(...args) {
  const result = useQueryDefault(...args)

  return useMemo(() => {
    return {
      ...result,
      isRefreshing: !result.isPending && result.isFetching,
    }
  }, [result])
}
