export const messages = {
  "I'll be with you shortly": {
    type: 'doc',
    content: [{ type: 'paragraph', content: [{ type: 'text', text: "I'll be with you shortly - thanks for your patience." }] }],
  },
  'Upload ID': {
    type: 'doc',
    content: [
      {
        type: 'paragraph',
        content: [
          {
            type: 'text',
            text: "We are required to have a copy of your photo ID (Driver's License, Passport, or Work/School ID) on file to provide your care via telehealth. Please upload a photo ID now or have one with you at the time of our visit.",
          },
        ],
      },
      { type: 'paragraph' },
      {
        type: 'paragraph',
        content: [{ type: 'text', text: 'Instructions on uploading an ID:' }],
      },
      {
        type: 'orderedList',
        attrs: { start: 1, type: null },
        content: [
          {
            type: 'listItem',
            content: [{ type: 'paragraph', content: [{ type: 'text', text: 'Please log in to your account.' }] }],
          },
          {
            type: 'listItem',
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    type: 'text',
                    text: 'Once logged in, click the three lines in the upper right-hand corner to open the menu and click "My Profile".',
                  },
                ],
              },
            ],
          },
          {
            type: 'listItem',
            content: [
              {
                type: 'paragraph',
                content: [{ type: 'text', text: 'Once there, you should see the option to upload your ID at the bottom of the page.' }],
              },
            ],
          },
        ],
      },
      { type: 'paragraph' },
      { type: 'paragraph', content: [{ type: 'text', text: "See you in a bit!'" }] },
    ],
  },
  'Upload Insurance': {
    type: 'doc',
    content: [
      {
        type: 'paragraph',
        content: [
          {
            type: 'text',
            text: 'Please upload a copy of your insurance card or have one available with you at the time of your visit.',
          },
        ],
      },
      {
        type: 'paragraph',
        content: [{ type: 'hardBreak' }, { type: 'text', text: 'Instructions on uploading your insurance:' }],
      },
      {
        type: 'orderedList',
        attrs: { start: 1, type: null },
        content: [
          {
            type: 'listItem',
            content: [{ type: 'paragraph', content: [{ type: 'text', text: 'Please log in to your account.' }] }],
          },
          {
            type: 'listItem',
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    type: 'text',
                    text: 'Once logged in, click the three lines in the upper right-hand corner to open the menu and click "My Profile".',
                  },
                ],
              },
            ],
          },
          {
            type: 'listItem',
            content: [
              {
                type: 'paragraph',
                content: [{ type: 'text', text: 'Once there, you should see the “Insurance” option to upload your insurance card.' }],
              },
            ],
          },
        ],
      },
      { type: 'paragraph' },
      { type: 'paragraph', content: [{ type: 'text', text: 'See you in a bit!' }] },
    ],
  },
  'Upload ID and Insurance': {
    type: 'doc',
    content: [
      {
        type: 'paragraph',
        content: [
          {
            type: 'text',
            text: "I'll be with you shortly - if able, please upload a form of ID (Driver's License, etc.) and your insurance card (if any) in the portal.",
          },
        ],
      },
    ],
  },
  'Running Late': {
    type: 'doc',
    content: [
      {
        type: 'paragraph',
        content: [
          {
            type: 'text',
            text: 'Sorry I am running later than usual - I appreciate your patience. If we need to reschedule due to this delay, please let me know.',
          },
        ],
      },
    ],
  },
  Reschedule: {
    type: 'doc',
    content: [
      {
        type: 'paragraph',
        content: [
          {
            type: 'text',
            text: 'Thanks for waiting for your appointment. Unfortunately the existing appointment will time out at 12 minutes, and I cannot override this limitation, but I am eager to see you! I will make every effort to accommodate you as soon as I finish with my current patient.',
          },
        ],
      },
    ],
  },
}
