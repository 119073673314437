import ErrorBase from '@components/ErrorBase'

export default function NotFound({ error, resetErrorBoundary }) {
  return (
    <ErrorBase
      title="Well that’s not good."
      subtitle="We can’t find that page, but don’t worry, there is plenty more on the home page!"
      code={404}
      details={error}
      reset={resetErrorBoundary}
    />
  )
}
