import { useCallback } from 'react'
import { NumberParam, useQueryParam, withDefault } from 'use-query-params'
import { useLocalStorage, useReadLocalStorage } from 'usehooks-ts'

export const DEFAULT_PER_PAGE = 25

/**
 * Hook to easily manage pagination query parameters. The hook will return
 * the page number, rows per page, and an updater to set new page and rowsPerPage.
 *
 * The perPage value is stored in local storage.
 *
 * @param id - The id of the query parameter. This is useful when you have multiple pagination on the same page.
 * @returns {Array} - The page, perPage, and updater function
 *
 * @example
 * const [page, perPage, setPageParams] = usePageParams()
 *
 * // Using the updater
 * const handlePageChange = () => setPageParams({ page: 2, perPage: 20 })
 */
export default function usePageParams({ id } = {}) {
  const [page, setPage] = useQueryParam(id ? `${id}-page` : 'page', withDefault(NumberParam, 1), { updateType: 'replaceIn' })
  const [perPage, setPerPage] = useLocalStorage('pagination-per-page', DEFAULT_PER_PAGE)

  const setPageParams = useCallback(
    (params) => {
      'page' in params && setPage(params.page)
      'perPage' in params && setPerPage(params.perPage)
    },
    [setPerPage, setPage]
  )

  return [page, perPage, setPageParams]
}

export const useReadPerPage = () => useReadLocalStorage('pagination-per-page') || DEFAULT_PER_PAGE
