import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import api from '@shared/services'
import { keyToString } from '@shared/utils'

// System Users Query Key factory
export const SystemUsersKeys = {
  // ['system-users']
  all: ['system-users'],

  // ['system-users', 'users'],
  users: ['system-users', 'users'],

  // ['system-users', 'users', userId],
  user: (userId) => keyToString([...SystemUsersKeys.users, userId]),

  // ['system-users', 'lists']
  lists: ['system-users', 'lists'],

  // ['system-users', 'lists', { ...query }]
  list: (query) => [...SystemUsersKeys.lists, query],
}

const SystemUsersApi = {
  list: (query) =>
    api
      .get(QS.stringifyUrl({ url: '/system_users', query }, { arrayFormat: 'bracket' }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data),
}

export default SystemUsersApi
