import { useState } from 'react'
import { Box } from '@mui/material'

import { useMe } from '@shared/providers/src/MeProvider'
import { BuildEnv, toTitleCase } from '@shared/utils'

import { Slide, Typography } from '@mui-components'

const envToColor = {
  [BuildEnv.Staging]: 'success',
  [BuildEnv.QA]: 'warning',
  [BuildEnv.Local]: 'info',
}

const envToLabel = {
  [BuildEnv.Staging]: 'Staging',
  [BuildEnv.QA]: 'QA',
  [BuildEnv.Local]: 'Local',
}

const styles = {
  container: {
    position: 'absolute',
    height: '100%',
    top: 0,
    left: '40%',
    transform: 'translateX(-50%)',
  },
  env: (color) => ({
    backgroundColor: `${color}.lighter`,
    py: 0.5,
    px: 3,
    boxShadow: (theme) => theme.customShadows.z2,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  }),
}

export default function Environment() {
  const [isShown, setIsShown] = useState(true)

  const env = import.meta.env.VITE_BUILD_ENV
  const me = useMe()

  if (env === BuildEnv.Production) return null

  const color = envToColor[env] || 'info'

  return (
    <Box onMouseEnter={() => setIsShown(false)} onMouseLeave={() => setIsShown(true)} sx={styles.container}>
      <Slide in={isShown} direction="down">
        <Box sx={styles.env(color)}>
          <Typography noWrap variant="h5" component="span" color={`${color}.dark`} fontWeight="normal">
            {envToLabel[env] || env} Environment - {toTitleCase(me.role)}
          </Typography>
        </Box>
      </Slide>
    </Box>
  )
}
