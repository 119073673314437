import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import api from '@shared/services'
import { keyToString } from '@shared/utils'

// Tasks Query Key factory
export const TaskKeys = {
  // ['tasks']
  all: ['tasks'],

  // ['tasks', userId],
  user: (userId) => keyToString([...TaskKeys.all, userId]),

  // ['tasks', userId, 'lists']
  lists: (userId) => [...TaskKeys.user(userId), 'lists'],

  // ['tasks', userId, 'lists', { ...query }]
  list: (userId, query) => [...TaskKeys.lists(userId), query],

  // ['tasks', userId, 'task']
  tasks: (userId) => [...TaskKeys.user(userId), 'task'],

  // ['tasks', userId, 'task', taskId]
  task: (userId, taskId) => keyToString([...TaskKeys.tasks(userId), taskId]),
}

export const TaskNotesKeys = {
  // ['task-notes']
  all: ['task-notes'],

  // ['task-notes', taskId]
  task: (taskId) => keyToString([...TaskNotesKeys.all, taskId]),

  // ['task-notes', taskId, 'lists']
  lists: (taskId) => [...TaskNotesKeys.task(taskId), 'lists'],

  // ['task-notes', taskId, 'lists', { ...query }]
  list: (taskId, query) => [...TaskNotesKeys.lists(taskId), query],
}

export const TaskDocuments = {
  // ['task-documents']
  all: ['task-documents'],

  // ['task-documents', taskId]
  task: (taskId) => keyToString([...TaskDocuments.all, taskId]),

  // ['task-documents', taskId, 'lists']
  lists: (taskId) => [...TaskDocuments.task(taskId), 'lists'],

  // ['task-documents', taskId, 'lists', { ...query }]
  list: (taskId, query) => [...TaskDocuments.lists(taskId), query],
}

const TasksApi = {
  tasks: (query) =>
    api
      .get(QS.stringifyUrl({ url: '/tasks', query }, { arrayFormat: 'bracket' }))
      .then((resp) => deserialize(resp.data, { transformKeys: 'camelCase' })?.data ?? []),
  task: (taskId) => api.get(`/tasks/${taskId}`).then((resp) => deserialize(resp.data, { transformKeys: 'camelCase' })?.data ?? {}),
  create: (data) => api.post('/tasks', data).then((resp) => deserialize(resp.data, { transformKeys: 'camelCase' })?.data ?? {}),

  markTodo: (taskId) =>
    api.post(`/tasks/${taskId}/mark_todo`).then((resp) => deserialize(resp.data, { transformKeys: 'camelCase' })?.data ?? {}),
  markInProgress: (taskId) =>
    api.post(`/tasks/${taskId}/mark_in_progress`).then((resp) => deserialize(resp.data, { transformKeys: 'camelCase' })?.data ?? {}),
  markWaiting: (taskId) =>
    api.post(`/tasks/${taskId}/mark_waiting`).then((resp) => deserialize(resp.data, { transformKeys: 'camelCase' })?.data ?? {}),
  markCompleted: (taskId, data) =>
    api.post(`/tasks/${taskId}/mark_completed`, data).then((resp) => deserialize(resp.data, { transformKeys: 'camelCase' })?.data ?? {}),
  markNotApplicable: (taskId, data) =>
    api
      .post(`/tasks/${taskId}/mark_not_applicable`, data)
      .then((resp) => deserialize(resp.data, { transformKeys: 'camelCase' })?.data ?? {}),
  markExpired: (taskId, data) =>
    api.post(`/tasks/${taskId}/mark_expired`, data).then((resp) => deserialize(resp.data, { transformKeys: 'camelCase' })?.data ?? {}),

  updateAssignee: (taskId, data) =>
    api.post(`/tasks/${taskId}/update_assignee`, data).then((resp) => deserialize(resp.data, { transformKeys: 'camelCase' })?.data ?? {}),
  updatePriority: (taskId, data) =>
    api.post(`/tasks/${taskId}/update_priority`, data).then((resp) => deserialize(resp.data, { transformKeys: 'camelCase' })?.data ?? {}),

  notes: (taskId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/tasks/${taskId}/notes`, query }))
      .then((resp) => deserialize(resp.data, { transformKeys: 'camelCase' })?.data ?? []),

  createNote: (taskId, message) => api.post(`/tasks/${taskId}/notes`, { message }).then((resp) => resp.data),

  documents: (taskId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/tasks/${taskId}/documents`, query }))
      .then((resp) => deserialize(resp.data, { transformKeys: 'camelCase' })?.data ?? []),
  attachDocument: (taskId, data) =>
    api.post(`/tasks/${taskId}/documents`, data).then((resp) => deserialize(resp.data, { transformKeys: 'camelCase' })?.data ?? {}),
  removeDocument: (taskId, fileId) =>
    api.delete(`/tasks/${taskId}/documents/${fileId}`).then((resp) => deserialize(resp.data, { transformKeys: 'camelCase' })?.data ?? {}),
}

export default TasksApi
