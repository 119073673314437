import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import api from '@shared/services'
import { keyToString } from '@shared/utils'

// Query Key factory
export const ConversationKeys = {
  // ['conversations']
  all: ['conversations'],

  // ['conversations', 'between-users', { ...query }]
  betweenUsers: (query) => [...ConversationKeys.all, 'between-users', query],

  // ['conversations', 'list']
  lists: () => [...ConversationKeys.all, 'list'],

  // ['conversations', 'list', { ...query }]
  list: (query) => [...ConversationKeys.lists(), query],

  // ['conversations', 'searches']
  searches: () => [...ConversationKeys.all, 'searches'],

  // ['conversations', 'searches', { ...query }]
  search: (query) => [...ConversationKeys.searches(), query],

  // ['conversations', 'thread']
  threads: () => [...ConversationKeys.all, 'thread'],

  // ['conversations', 'thread', conversationId]
  thread: (conversationId) => keyToString([...ConversationKeys.threads(), conversationId]),

  // ['conversations', 'thread', 'internal', patientId]
  internalThread: (patientId) => keyToString([...ConversationKeys.threads(), 'internal', patientId]),

  // ['conversations', 'thread', conversationId, 'details']
  details: (conversationId) => [...ConversationKeys.thread(conversationId), 'details'],

  // ['conversations', 'thread', conversationId, 'messages']
  messages: (conversationId) => [...ConversationKeys.thread(conversationId), 'messages'],

  // ['conversations', 'thread', conversationId, 'message', messageId]
  message: (conversationId, messageId) => [...ConversationKeys.thread(conversationId), 'message', messageId],

  // ['conversations', 'thread', conversationId, 'behalf-users', { ...query }]
  behalfUsers: (conversationId, query) => [...ConversationKeys.thread(conversationId), 'behalf-users', query],

  // ['conversations', 'thread', conversationId, 'possible-users', { ...query }]
  possibleUsers: (conversationId, query) => [...ConversationKeys.thread(conversationId), 'possible-users', query],
}

const ConversationsApi = {
  conversations: (query, config) =>
    api
      .get(QS.stringifyUrl({ url: '/conversations', query }), config)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  conversation: (conversationId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/conversations/${conversationId}`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  internalConversation: (patientId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/internal_conversations`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  newConversation: (data, config) =>
    api.post('/conversations', data, config).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  messages: (conversationId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/conversations/${conversationId}/messages`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  message: (conversationId, messageId) =>
    api
      .get(`/conversations/${conversationId}/messages/${messageId}`)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  possibleUsers: (conversationId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/conversations/${conversationId}/users/search`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  addUser: (conversationId, data) =>
    api
      .post(`/conversations/${conversationId}/users`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  removeUser: (conversationId, userId) =>
    api
      .delete(`/conversations/${conversationId}/users/${userId}`)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  search: (query, config) =>
    api
      .get(QS.stringifyUrl({ url: '/conversations/search', query }), config)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  behalfUsers: (conversationId, query, config) =>
    api
      .get(QS.stringifyUrl({ url: `/conversations/${conversationId}/send_on_behalf_of_users`, query }), config)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  sendMessage: (conversationId, data, config) =>
    api
      .patch(`/conversations/${conversationId}`, data, config)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  sendUserMessage: (userId, data, config) =>
    api
      .post(`/users/${userId}/conversations`, data, config)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  // Send a message to customer support
  sendCSMessage: (userId, data) =>
    api
      .post(`/users/${userId}/support_conversations`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  sendUserReminder: (userId, data) =>
    api.post(`/users/${userId}/message_reminders`, data).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  pin: (conversationId) =>
    api.post(`/conversations/${conversationId}/pin`).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  unpin: (conversationId) =>
    api.post(`/conversations/${conversationId}/unpin`).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  archive: (conversationId) =>
    api.post(`/conversations/${conversationId}/archive`).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  unarchive: (conversationId) =>
    api.post(`/conversations/${conversationId}/unarchive`).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
}

export default ConversationsApi
