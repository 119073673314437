import { Lookup, useLookup } from '@shared/providers/src/DropdownOptionsProvider'

import { Grid, MenuItem, Paper, TextField } from '@mui-components'
import HookInputControl from '@components/HookInputControl'

export default function Title({ isSubmitting }) {
  const policies = useLookup(Lookup.SOPPolicies)
  const categories = useLookup(Lookup.SOPCategories)

  return (
    <Paper variant="outlined" sx={{ px: 2, py: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <HookInputControl field="title">
            <TextField required disabled={isSubmitting} />
          </HookInputControl>
        </Grid>
        <Grid item xs={4}>
          <HookInputControl field="policy" disableOptionalLabel>
            <TextField select label="Policy Section" disabled={isSubmitting}>
              {policies.map((label) => (
                <MenuItem key={label} value={label}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          </HookInputControl>
        </Grid>
        <Grid item xs={4}>
          <HookInputControl field="categories" disableOptionalLabel>
            <TextField label="Category" select SelectProps={{ multiple: true }} disabled={isSubmitting}>
              {categories.map((label) => (
                <MenuItem key={label} value={label}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          </HookInputControl>
        </Grid>
        <Grid item xs={4}>
          <HookInputControl field="version">
            <TextField required disabled={isSubmitting} />
          </HookInputControl>
        </Grid>
      </Grid>
    </Paper>
  )
}
