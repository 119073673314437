import { useState } from 'react'

import { EyeInvisibleOutlinedIcon, EyeOutlinedIcon } from '@icons'
import { IconButton, InputAdornment, TextField } from '@mui-components'
import InputControl from '@components/InputControl'

export default function PasswordField({ formik }) {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <InputControl field="password" formikProps={formik}>
      <TextField
        autoFocus
        required
        fullWidth
        type={showPassword ? 'text' : 'password'}
        placeholder="Enter your password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword((sp) => !sp)}
                onMouseDown={(e) => e.preventDefault()}
                edge="end"
                color="secondary"
              >
                {showPassword ? <EyeOutlinedIcon /> : <EyeInvisibleOutlinedIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          '& .MuiInputBase-root': {
            backgroundColor: 'background.paper',
          },
        }}
      />
    </InputControl>
  )
}
