import { useMemo, useState } from 'react'
import { isMacOs } from 'react-device-detect'
import { useLocalStorage } from 'usehooks-ts'
import TimelineDot from '@mui/lab/TimelineDot'

import { useMe } from '@shared/providers/src/MeProvider'

import { SendOutlinedIcon } from '@icons'
import { IconButton, OutlinedInput, Stack, Tooltip } from '@mui-components'
import Avatar from '@components/Avatar'

const styles = {
  input: {
    // ref: packages/apps/admin/src/themes/overrides/OutlinedInput.js
    padding: '10.5px 14px 10.5px 12px',
    flex: '1 1 auto',
  },
  dot: {
    p: 0,
    borderWidth: 1,
  },
  sendBtn: {
    bgcolor: 'primary.main',
    color: 'primary.contrastText',
    '&:hover': {
      bgcolor: 'primary.dark',
      color: 'primary.contrastText',
    },
  },
}

export default function TimelineNoteCreation({ autoFocus = false, disabled = false, onSend }) {
  const me = useMe()
  const [sendOnEnter] = useLocalStorage('send-on-enter', true)
  const [content, setContent] = useState('')

  const handleChange = (e) => setContent(e.target.value)

  const handleSend = () => {
    if (!content) return
    onSend(content)
    setContent('')
  }

  const handleKeyDown = (e) => {
    if (sendOnEnter && e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      handleSend()
    }
  }

  const placeholder = useMemo(() => {
    const enterText = isMacOs ? 'Return' : 'Enter'

    return sendOnEnter
      ? `Press '${enterText}' to leave a note, 'Shift + ${enterText}' to start a new line`
      : `Press '${enterText}' to start a new line`
  }, [sendOnEnter])

  return (
    <Stack direction="row" alignItems="center" spacing={2} sx={{ flex: '0 0 auto', pr: 2 }}>
      <TimelineDot variant="outlined" sx={styles.dot}>
        <Avatar user={me} variant="circular" />
      </TimelineDot>
      <OutlinedInput
        multiline
        maxRows={6}
        autoFocus={autoFocus}
        disabled={disabled}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        sx={styles.input}
        value={content}
      />
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <Tooltip title="Send">
          <span>
            <IconButton color="primary" disabled={!content || disabled} onClick={handleSend} sx={styles.sendBtn}>
              <SendOutlinedIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Stack>
    </Stack>
  )
}
