import React, { useContext } from 'react'

export const TestIdContext = React.createContext('')

export default function TestId({ id, prop = '', children }) {
  const parentTestId = useContext(TestIdContext)
  const testId = parentTestId ? parentTestId + '-' + id : id

  const child = React.Children.only(children)

  return (
    <TestIdContext.Provider value={testId}>
      {React.cloneElement(child, !prop ? { 'data-testid': testId } : { [prop]: { ...child.props[prop], 'data-testid': testId } })}
    </TestIdContext.Provider>
  )
}
