import PropTypes from 'prop-types'

import { Skeleton, Stack, Typography } from '@mui-components'
import Breadcrumbs from '@components/Breadcrumbs'
import DocTitle from '@components/DocTitle'
import SearchInput from '@components/SearchInput'

PageHeader.propTypes = {
  /** Set the document title to the title of the page */
  docTitle: PropTypes.bool,

  /** Title of the page */
  title: PropTypes.string.isRequired,

  /** Subtitle of the page */
  subtitle: PropTypes.string,

  /** Size of the title */
  size: PropTypes.oneOf(['small', 'medium', 'large']),

  /** Action to be displayed on the right side of the header */
  action: PropTypes.node,

  /** Props for the search input */
  searchProps: PropTypes.object,

  /** Can display a loading indicator */
  loading: PropTypes.bool,
}

const styles = {
  container: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    py: 0.5,
    backgroundImage: 'url("/waves/primary/bottom.svg"), url("/waves/primary/top.svg")',
    backgroundPosition: 'bottom left, top right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '25%, 35%',
    backgroundColor: 'primary.lighter',
    boxShadow: (theme) => theme.customShadows.z1,
  },
  right: {
    flexGrow: 1,
    flexShrink: 0,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  large: {
    minHeight: 112,
    pl: 4,
    pr: 3,
  },
  medium: {
    minHeight: 88,
    pl: 4,
    pr: 3,
  },
  small: {
    minHeight: 48,
    px: 2,
  },
}

/**
 * Header of page
 *
 * @example
 * <PageHeader
 *   title="Themes"
 *   subtitle="Color and Images applied to the patient app to personalize the experience."
 *   searchProps={{ label: 'Search', value: '', onChange: () => {} }}
 *   size="large"
 * />
 */
export default function PageHeader({ docTitle = false, title, subtitle, action, searchProps, size = 'medium', loading = false }) {
  const content = (
    <Typography variant="h3" fontWeight="medium">
      {loading && !title ? <Skeleton width={200} /> : title}
    </Typography>
  )

  return (
    <Stack direction="row" spacing={2} sx={[styles.container, styles[size]]}>
      {docTitle && <DocTitle title={title} />}
      <Stack justifyContent="center">
        <Stack direction="row" alignItems="center">
          <Breadcrumbs content={content} />
        </Stack>
        {subtitle && size === 'large' && <Typography>{subtitle}</Typography>}
      </Stack>
      <Stack direction="row" spacing={2} sx={styles.right}>
        {action}
        {searchProps && (
          <SearchInput
            sx={{
              maxWidth: 260,
              backgroundColor: 'common.white',
              '& .MuiInputBase-input': { py: 1 },
            }}
            {...searchProps}
          />
        )}
      </Stack>
    </Stack>
  )
}
