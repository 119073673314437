import { SvgIcon } from '@mui-components'

export default function PillIcon(props) {
  return (
    <SvgIcon width="36" height="36" viewBox="0 0 36 36" fill="none" {...props}>
      <path d="M10 26a8 8 0 0 0 8 8h1a8 8 0 0 0 8-8v-6H10v6z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 2a8 8 0 0 0-8 8v10h17V10a8 8 0 0 0-8-8h-1zm.5 2a6.5 6.5 0 0 0-6.5 6.5V18h13v-7.5A6.5 6.5 0 0 0 18.5 4z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}
