import { Skeleton, Stack, Typography } from '@mui-components'

const styles = {
  container: {
    gap: 1,
    py: 1,
    px: 2,
    '&:not(:last-child)': {
      borderBottom: '1px solid',
      borderColor: 'divider',
    },
  },
  label: {
    minWidth: 120,
    color: 'text.secondary',
    fontWeight: 'normal',
  },
}

export default function Property({ label, children, sx = {} }) {
  return (
    <Stack direction="row" sx={[styles.container, sx.root]}>
      {label && <Typography sx={[styles.label, sx.label]}>{label}</Typography>}
      {['number', 'string'].includes(typeof children) ? <Typography sx={sx.value}>{children}</Typography> : children}
    </Stack>
  )
}

Property.Loading = ({ label, sx }) => (
  <Property label={label} sx={sx}>
    <Typography sx={{ width: '100%' }}>
      <Skeleton />
    </Typography>
  </Property>
)
