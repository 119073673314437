import { useEffect, useLayoutEffect, useState } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import kebabCase from 'lodash/kebabCase'
import { useReadLocalStorage } from 'usehooks-ts'

import { AppointmentStatus, BuildEnv, getFriendlyDuration, pageParam, TimeSlotDuration } from '@shared/utils'

import AppointmentsApi, { ProviderAppointmentsKeys } from '@services/Appointments.api'
import PlatformSettingsApi, { BlackoutPeriodKeys } from '@services/PlatformSettings.api'
import ProvidersApi, { ProviderAdminTimeKeys, ProviderAvailabilitiesKeys } from '@services/Providers.api'

import { format } from './Calendar.utils'

const LIMIT = 50
const isProd = import.meta.env.VITE_BUILD_ENV === BuildEnv.Production

// Loads by LIMIT chunks until there are no more items
export function useWeekAvailability(user, date) {
  const overriddenLimit = useReadLocalStorage('override-page-size') || LIMIT
  const limit = isProd ? LIMIT : overriddenLimit

  const query = {
    limit,
    start_date: date.startOf('week').format(format),
    end_date: date.endOf('week').format(format),
  }

  const id = user?.provider.id
  const timezone = user?.provider.timezone

  const queryResult = useInfiniteQuery({
    queryKey: ProviderAvailabilitiesKeys.list(id, query),
    queryFn: ({ pageParam }) => ProvidersApi.availabilities(id, { ...query, offset: pageParam * limit }),
    enabled: Boolean(id),
    select: ({ pages }) => {
      const data = pages.flat()
      return data.map((availability) => {
        const start = dayjs(availability.start).tz(timezone)
        const end = dayjs(availability.end).tz(timezone)
        const duration = end.diff(start, 'minutes')

        return {
          start: start.format(format),
          end: end.format(format),
          duration,
          display: 'background',
          className: 'availability-slot',
          extendedProps: { id: availability.id, type: 'availability' },
        }
      })
    },
    initialPageParam: 0,
    getNextPageParam: pageParam(limit),
  })

  const { fetchNextPage, hasNextPage, isFetchingNextPage } = queryResult

  useLayoutEffect(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage])

  return queryResult
}

// Loads by LIMIT chunks until there are no more items
export function useWeekAdminTimes(user, date) {
  const overriddenLimit = useReadLocalStorage('override-page-size') || LIMIT
  const limit = isProd ? LIMIT : overriddenLimit

  const query = {
    limit,
    start_date_time: date.startOf('week').format(format),
    end_date_time: date.endOf('week').format(format),
  }

  const id = user?.provider.id
  const timezone = user?.provider.timezone

  const queryResult = useInfiniteQuery({
    queryKey: ProviderAdminTimeKeys.list(id, query),
    queryFn: ({ pageParam }) => ProvidersApi.adminTimes(id, { ...query, offset: pageParam * limit }),
    enabled: Boolean(id),
    select: ({ pages }) => {
      const data = pages.flat()
      return data.map((adminTime) => {
        const start = dayjs(adminTime.start).tz(timezone)
        const end = dayjs(adminTime.end).tz(timezone)
        const duration = end.diff(start, 'minutes')

        return {
          start: start.format(format),
          end: end.format(format),
          duration,
          display: 'background',
          className: 'admin-time-slot',
          extendedProps: { id: adminTime.id, type: 'adminTime' },
        }
      })
    },
    initialPageParam: 0,
    getNextPageParam: pageParam(limit),
  })

  const { fetchNextPage, hasNextPage, isFetchingNextPage } = queryResult

  useLayoutEffect(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage])

  return queryResult
}

// Loads by LIMIT chunks until there are no more items
export function useWeekAppointments(user, date) {
  const overriddenLimit = useReadLocalStorage('override-page-size') || LIMIT
  const limit = isProd ? LIMIT : overriddenLimit

  const query = {
    start_date: date.startOf('week').format(format),
    end_date: date.endOf('week').format(format),
    appointment_status: [
      AppointmentStatus.Missed,
      AppointmentStatus.Scheduled,
      AppointmentStatus.Waiting,
      AppointmentStatus.InProgress,
      AppointmentStatus.Documenting,
      AppointmentStatus.Complete,
    ],
    limit,
  }

  const id = user?.provider.id
  const timezone = user?.provider.timezone

  const queryResult = useInfiniteQuery({
    queryKey: ProviderAppointmentsKeys.list(id, query),
    queryFn: ({ pageParam }) => AppointmentsApi.appointments(id, { ...query, offset: pageParam * limit }),
    enabled: Boolean(id),
    select: ({ pages }) => {
      const data = pages.flat()
      const events = data.map((appointment) => {
        const start = dayjs(appointment.start).tz(timezone)
        const end = start.add(appointment.duration || TimeSlotDuration, 'minutes')
        const time = start.format('LT')
        const name = appointment.user.fullName

        return {
          id: appointment.id,
          start: start.format(format),
          end: end.format(format),
          title: `${time} ${name}`,
          className: ['appointment-slot', kebabCase(appointment.status)],
          extendedProps: {
            type: 'appointment',
            tooltip: `${time}  ${getFriendlyDuration(start, end)} - ${name} - ${appointment.status}`,
          },
        }
      })
      return { data, events }
    },
    initialPageParam: 0,
    getNextPageParam: pageParam(limit),
  })

  const { fetchNextPage, hasNextPage, isFetchingNextPage } = queryResult

  useLayoutEffect(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage])

  return queryResult
}

// Loads by LIMIT chunks until there are no more items
export function useBlackoutPeriods(user, date, businessHours) {
  const overriddenLimit = useReadLocalStorage('override-page-size') || LIMIT
  const limit = isProd ? LIMIT : overriddenLimit

  const query = {
    limit,
    start_date_time: date.startOf('week').format(format),
    end_date_time: date.endOf('week').format(format),
  }

  const queryResult = useInfiniteQuery({
    queryKey: BlackoutPeriodKeys.list(query),
    queryFn: ({ pageParam }) => PlatformSettingsApi.blackoutPeriods({ ...query, offset: pageParam * limit }),
    enabled: Boolean(user) && Boolean(businessHours),
    select: ({ pages }) => {
      const timezone = user?.provider.timezone
      const data = pages.flat()
      return data.map((event) => {
        if (event.allDay) {
          const date = dayjs(event.allDayDate)
          return {
            className: 'blackout-slot',
            title: event.title,
            start: `${date.format('YYYY-MM-DD')} ${businessHours.start_time}`,
            end: `${date.format('YYYY-MM-DD')} ${businessHours.end_time}`,
            extendedProps: {
              tooltip: `${date.format('L')} - ${event.title}`,
            },
          }
        } else {
          const start = dayjs(event.start).tz(timezone)
          const end = dayjs(event.end).tz(timezone)
          return {
            className: 'blackout-slot',
            title: `${start.format('LT')} ${event.title}`,
            start: start.format(format),
            end: end.format(format),
            extendedProps: {
              tooltip: `${start.format('LT')} - ${end.format('LT')} ${event.title}`,
            },
          }
        }
      })
    },
    initialPageParam: 0,
    getNextPageParam: pageParam(limit),
  })

  const { fetchNextPage, hasNextPage, isFetchingNextPage } = queryResult

  useLayoutEffect(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage])

  return queryResult
}

export const useDate = (timezone) => {
  const [date, setDate] = useState(timezone ? dayjs().tz(timezone) : dayjs())

  useEffect(() => {
    if (!timezone) return
    setDate(dayjs(date).tz(timezone))
  }, [timezone]) // eslint-disable-line react-hooks/exhaustive-deps

  return [date, setDate]
}
