import dayjs from 'dayjs'
import * as Yup from 'yup'

import { getErrorDetails, TimeSlotDuration } from '@shared/utils'

export const appointmentErrors = ['Availability has appointments', 'There are availabilities with appointments with the range provided']

export const validationSchema = Yup.object()
  .shape({
    start: Yup.object()
      .required('Required')
      .nullable()
      .test('invalid-start', 'Invalid Date', (v) => dayjs.isDayjs(v)),
    end: Yup.object()
      .required('Required')
      .nullable()
      .test('invalid-end', 'Invalid Date', (v) => dayjs.isDayjs(v)),
  })
  .test({
    name: 'validate-range',
    test: function ({ start, end }) {
      if (dayjs.isDayjs(start) && dayjs.isDayjs(end)) {
        if (start.minute?.() % TimeSlotDuration !== 0 || end.minute?.() % TimeSlotDuration !== 0)
          return this.createError({
            path: 'range',
            message: 'Start and End must be in 10 minute increments',
          })
        if (start.isAfter(end)) return this.createError({ path: 'range', message: 'Start must be before End' })
        if (start.isSame(end))
          return this.createError({
            path: 'range',
            message: 'Start and End cannot be the same',
          })
      }
      return true
    },
  })

export const parseBlackoutOverlap = (e) => {
  return getErrorDetails(e)
    .reduce((carry, m) => carry.concat(m.split('\n')), [])
    .filter((message) => /^(From .+ to .+|On .+)$/.test(message))
    .map((message) => {
      if (/^From .+ to .+$/.test(message)) {
        let [start, end] = message.match(/^From\s(.*?)\sto\s(.*?)$/).slice(1)
        start = dayjs(start)
        end = dayjs(end)

        if (start.isSame(end, 'day')) {
          return `From ${start.format('L LT')} - ${end.format('LT')}`
        } else {
          return `From ${start.format('L LT')} - ${end.format('L LT')}`
        }
      } else {
        let start = message.match(/^On\s(.*?)$/).slice(1)
        start = dayjs(start)
        return `On ${start.format('L')}`
      }
    })
    .join(', ')
}
