import { useState } from 'react'

export default function usePromiseLoading(promise) {
  const [loading, setLoading] = useState(false)

  const promiseWrap = (...args) => {
    setLoading(true)
    return promise(...args).finally((resp) => {
      setLoading(false)
      return resp
    })
  }

  return [promiseWrap, loading]
}
