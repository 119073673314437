import * as yup from 'yup'

const isNullOrUndefined = function (value) {
  return value === null || value === undefined
}

function minLowercase(length = 1, message) {
  return this.test({
    name: 'minLowercase',
    exclusive: true,
    message,
    params: { length },
    test(value) {
      return isNullOrUndefined(value) || (value.match(/[a-z]/g) || []).length >= length
    },
  })
}

function minUppercase(length = 1, message) {
  return this.test({
    name: 'minUppercase',
    exclusive: true,
    message,
    params: { length },
    test(value) {
      return isNullOrUndefined(value) || (value.match(/[A-Z]/g) || []).length >= length
    },
  })
}

function minNumbers(length = 1, message) {
  return this.test({
    name: 'minNumber',
    exclusive: true,
    message,
    params: { length },
    test(value) {
      return isNullOrUndefined(value) || (value.match(/[0-9]/g) || []).length >= length
    },
  })
}

function minSymbols(length = 1, message) {
  return this.test({
    name: 'minSymbol',
    exclusive: true,
    message,
    params: { length },
    test(value) {
      return isNullOrUndefined(value) || (value.match(/[!%&@#$^*?_~]/g) || []).length >= length
    },
  })
}

function zip(message = 'Zip code must be 5 digits of 0-9') {
  return this.test({
    name: 'zip',
    exclusive: true,
    message,
    test(value) {
      return isNullOrUndefined(value) || /^\d{5}$/.test(value)
    },
  })
}

yup.addMethod(yup.string, 'minLowercase', minLowercase)
yup.addMethod(yup.string, 'minUppercase', minUppercase)
yup.addMethod(yup.string, 'minNumbers', minNumbers)
yup.addMethod(yup.string, 'minSymbols', minSymbols)
yup.addMethod(yup.string, 'zip', zip)

export default yup
