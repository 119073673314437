import { useMemo } from 'react'
import PubSub from 'pubsub-js'
import { useLocalStorage } from 'usehooks-ts'

import VideoTrack from '@shared/twilio/src/components/VideoTrack'
import useDevices from '@shared/twilio/src/hooks/useDevices'
import useMediaStreamTrack from '@shared/twilio/src/hooks/useMediaStreamTrack'
import { useTracksContext } from '@shared/twilio/src/providers/TracksProvider'
import { SELECTED_VIDEO_INPUT_KEY } from '@shared/twilio/src/utils'

import { Box, FormControl, MenuItem, Select, Typography } from '@mui-components'

const styles = {
  preview: {
    width: '300px',
    maxHeight: '200px',
    margin: '0.5em auto',
    '& video': {
      maxHeight: '200px',
    },
  },
}

export default function VideoInputList() {
  const { videoInputDevices } = useDevices()
  const { localTracks } = useTracksContext()
  const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] = useLocalStorage(SELECTED_VIDEO_INPUT_KEY, '')

  const localVideoTrack = localTracks.find((track) => track.kind === 'video')
  const mediaStreamTrack = useMediaStreamTrack(localVideoTrack)

  const localVideoInputDeviceId = useMemo(() => {
    const exists = videoInputDevices.some((device) => device.deviceId === storedLocalVideoDeviceId)
    return exists ? storedLocalVideoDeviceId : mediaStreamTrack?.getSettings().deviceId || ''
  }, [mediaStreamTrack, storedLocalVideoDeviceId, videoInputDevices])

  function replaceTrack(newDeviceId) {
    setStoredLocalVideoDeviceId(newDeviceId)
    PubSub.publish(SELECTED_VIDEO_INPUT_KEY, newDeviceId)
  }

  return (
    <div>
      {localVideoTrack && (
        <Box sx={styles.preview}>
          <VideoTrack isLocal track={localVideoTrack} />
        </Box>
      )}
      {videoInputDevices.length > 1 ? (
        <FormControl fullWidth>
          <Typography gutterBottom color="text.secondary">
            Video Input
          </Typography>
          <Select onChange={(e) => replaceTrack(e.target.value)} value={localVideoInputDeviceId} variant="outlined">
            {videoInputDevices.map((device) => (
              <MenuItem value={device.deviceId} key={device.deviceId}>
                {device.label || device.deviceId}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <>
          <Typography gutterBottom color="text.secondary">
            Video Input
          </Typography>
          <Typography>{localVideoTrack?.mediaStreamTrack.label || 'No Local Video'}</Typography>
        </>
      )}
    </div>
  )
}
