import { isMobile } from 'react-device-detect'
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state'
import HoverPopover from 'material-ui-popup-state/HoverPopover'

import { QuestionCircleOutlinedIcon } from '@icons'
import { Box, Divider, IconButton, Skeleton, Stack, Typography } from '@mui-components'
import { bindDelayedHover } from '@components/UserCard/UserCard.utils'

export const format = 'YYYY-MM-DD HH:mm'

export function Timing({ data }) {
  if (!data) {
    return (
      <Typography variant="body2" align="right">
        <Skeleton width={200} />
        <Skeleton width={200} />
      </Typography>
    )
  }
  const { max, adminTime, apptsTime } = data
  const adminTimeString = stringifyDuration(adminTime)
  const apptsTimeString = stringifyDuration(apptsTime)
  if (!max) {
    return (
      <Typography variant="body2" align="right">
        Max Hrs not defined
        <br />
        Admin: <b>{adminTimeString}</b> | Appointments: <b>{apptsTimeString}</b>
      </Typography>
    )
  }
  const remaining = max - adminTime - apptsTime
  const remainingString = stringifyDuration(Math.abs(remaining))
  const maxString = stringifyDuration(max)
  return (
    <Typography variant="body2" align="right">
      Max Hrs: <b>{maxString}</b> | {remaining >= 0 ? 'Remaining' : 'Exceeded'}: <b>{remainingString}</b>
      <br />
      Admin: <b>{adminTimeString}</b> | Appointments: <b>{apptsTimeString}</b>
    </Typography>
  )
}

export function IndicatorGuide() {
  return (
    <PopupState variant="popover" popupId="indicator-guide-popover">
      {(popupState) => {
        const hoverHandler = isMobile ? bindHover(popupState) : bindDelayedHover(popupState, 250)

        return (
          <div>
            <Stack direction="row" alignItems="center" spacing={1} sx={{ cursor: 'progress' }} {...hoverHandler}>
              <Typography color="text.secondary">Indicator Guide</Typography>
              <IconButton shape="rounded" color="secondary" size="small">
                <QuestionCircleOutlinedIcon style={{ fontSize: 18 }} />
              </IconButton>
            </Stack>
            <HoverPopover {...bindPopover(popupState)} sx={{ '& .MuiPaper-root': { width: 300 } }}>
              <Stack p={2} spacing={2}>
                <LegendItem label="Appointment Availability" color="warning.lighter" />
                <LegendItem
                  label="Admin Availability"
                  background={(theme) => {
                    return `repeating-linear-gradient(45deg, ${theme.palette.warning.light}, ${theme.palette.warning.light} 8px, ${theme.palette.warning.lighter} 8px, ${theme.palette.warning.lighter} 10px)`
                  }}
                />
                <LegendItem
                  label="Platform Unavailable"
                  background={(theme) => {
                    return `repeating-linear-gradient(45deg, ${theme.palette.info.dark}, ${theme.palette.info.dark} 8px, ${theme.palette.info.darker} 8px, ${theme.palette.info.darker} 10px)`
                  }}
                />
                <Divider />
                <LegendItem label="Scheduled Appointment" color="warning.dark" />
                <LegendItem label="Documenting Appointment" color="primary.500" />
                <LegendItem label="Complete Appointment" color="background.paper" />
                <LegendItem label="Missed Appointment" color="grey.400" />
              </Stack>
            </HoverPopover>
          </div>
        )
      }}
    </PopupState>
  )
}

function LegendItem({ label, color: backgroundColor, background }) {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Box
        sx={{
          width: 24,
          height: 24,
          borderRadius: 1,
          backgroundColor,
          background,
          border: '1px solid',
          borderColor: 'divider',
        }}
      />
      <Typography>{label}</Typography>
    </Stack>
  )
}

const stringifyDuration = (duration) => {
  const hours = Math.floor(duration / 60)
  const minutes = duration % 60

  if (hours && minutes) return `${hours}h ${minutes}m`
  if (hours) return `${hours}h`
  return `${minutes}m`
}
