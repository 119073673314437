import { useMemo, useRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'

import useDelayedHover from '@shared/hooks/src/useDelayedHover'
import { UserRole } from '@shared/utils'

import { BusinessIcon, LocationIcon, MedicalServiceIcon, VerifiedIcon } from '@icons'
import { Divider, Link, Skeleton, Stack, Tooltip, Typography } from '@mui-components'

import { useProviderCBOs } from '../UserCard.hooks'
import { Avatar } from '../UserCard.utils'

export default function Content({ user }) {
  if (user.role === UserRole.Provider) {
    const provider = user.provider
    const address = provider.homeAddress

    return (
      <>
        <LocationAndServices
          address={[address.city, address.state].filter(Boolean).join(', ')}
          services={provider.providerTypes?.join(', ')}
        />
        <Licenses providerId={provider.id} licenses={provider.licenses} />
      </>
    )
  }

  if ([UserRole.CBO, UserRole.Pharmacy].includes(user.role)) {
    return <Business business={user.cbo?.name || user.pharmacy?.name} />
  }

  if (user.role === UserRole.Patient) {
    const patient = user.patient
    const address = patient.homeAddress
    return (
      <>
        <LocationAndServices address={[address.city, address.state].filter(Boolean).join(', ')} services={patient.treamentTypes} />
        <MRN userId={user.id} />
        <Divider />
        <CareTeam user={user.patient} />
      </>
    )
  }

  return null
}

Content.Loading = function () {
  return <Skeleton variant="rounded" width="100%" height={100} />
}

export function LocationAndServices({ address, services }) {
  const theme = useTheme()

  return (
    <Stack direction="row" justifyContent="space-between" flexWrap="wrap" gap={1}>
      <Stack direction="row" alignItems="center" gap={1}>
        <LocationIcon style={{ fontSize: 16 }} />
        <Typography>{address || '-'}</Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap={1}>
        <MedicalServiceIcon style={{ fontSize: 16, color: theme.palette.text.secondary }} />
        <Typography>{services || '-'}</Typography>
      </Stack>
    </Stack>
  )
}

export function Licenses({ providerId, licenses = [] }) {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <VerifiedIcon style={{ fontSize: 16 }} />
      {licenses.length === 0 && <Typography>-</Typography>}
      {licenses.length > 0 && (
        <Stack direction="row" flexWrap="wrap" spacing={0.5}>
          {licenses.map((state, i) => (
            <State key={state} providerId={providerId} value={state} isLast={licenses.length - 1 === i} />
          ))}
        </Stack>
      )}
    </Stack>
  )
}

function State({ providerId, value, isLast }) {
  const ref = useRef(null)
  const isHovered = useDelayedHover(ref, { delay: 200 })

  const { data, isError, isFetching } = useProviderCBOs(providerId, value, { enabled: isHovered })

  const tooltip = useMemo(() => {
    if (isError) return 'Could not load CBOs'
    if (isFetching) return 'Loading...'
    if (data?.length === 0) return 'No CBOs found'
    return data?.map((cbo) => cbo.name).join('\n')
  }, [data, isError, isFetching])

  return (
    <Tooltip
      open={isHovered}
      title={
        <Typography component="span" fontWeight="bold" sx={{ whiteSpace: 'pre-line' }}>
          {tooltip}
        </Typography>
      }
    >
      <Typography ref={ref}>
        {value}
        {isLast ? '' : ','}
      </Typography>
    </Tooltip>
  )
}

export function Business({ business }) {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <BusinessIcon style={{ fontSize: 16 }} />
      <Typography>{business || '-'}</Typography>
    </Stack>
  )
}

export function MRN({ userId }) {
  return (
    <Stack direction="row" alignItems="flex-end" spacing={1}>
      <Typography variant="subtitle2" color="text.secondary" sx={{ mb: '2px' }}>
        MRN
      </Typography>
      <Link component={RouterLink} to={`/app/patients/${userId}`} fontWeight="bold" target="_blank">
        {userId}
      </Link>
    </Stack>
  )
}

export function CareTeam({ user }) {
  const { pharmacy, provider, cbo } = user

  return (
    <Stack spacing={1}>
      <Typography variant="subtitle2" color="text.secondary" fontWeight="bold">
        Q Care Team
      </Typography>
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <Typography>Provider</Typography>
        <Stack direction="row" spacing={1} sx={{ overflow: 'hidden' }}>
          {provider && <Avatar user={provider} size="xs" />}
          <Typography noWrap>{provider?.fullName || '-'}</Typography>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <Typography>CBO</Typography>
        <Typography>{cbo?.name || '-'}</Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <Typography>Pharmacy</Typography>
        <Typography>{pharmacy?.name || '-'}</Typography>
      </Stack>
    </Stack>
  )
}
