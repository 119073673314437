import * as Yup from 'yup'

export const getInitialValues = (cbo, ruleSets, providerTypes = []) => {
  return {
    // grant340BInformation
    hrsaId: ruleSets?.grant340BInformation?.hrsaId ?? '',
    hrsaGrantNumber: ruleSets?.grant340BInformation?.hrsaGrantNumber ?? '',
    noticeOfFundingNumber: ruleSets?.grant340BInformation?.noticeOfFundingNumber ?? '',
    hrsaUrl: ruleSets?.grant340BInformation?.hrsaUrl ?? '',
    entityEMRSystem: ruleSets?.grant340BInformation?.entityEMRSystem ?? '',
    grantPatientDefinition: ruleSets?.grant340BInformation?.grantPatientDefinition ?? '',

    // additionalServices
    hepCScreenAndTreatValue: ruleSets?.additionalServices?.hepCScreenAndTreat?.value ?? false,
    hepCScreenAndTreatInfo: ruleSets?.additionalServices?.hepCScreenAndTreat?.info ?? '',

    longitudinalHIVCareValue: ruleSets?.additionalServices?.longitudinalHIVCare?.value ?? false,
    longitudinalHIVCareInfo: ruleSets?.additionalServices?.longitudinalHIVCare?.info ?? '',

    inPersonHealthCenterValue: ruleSets?.additionalServices?.inPersonHealthCenter?.value ?? false,
    inPersonHealthCenterInfo: ruleSets?.additionalServices?.inPersonHealthCenter?.info ?? '',

    labsInPersonValue: ruleSets?.additionalServices?.labsInPerson?.value ?? false,
    labsInPersonInfo: ruleSets?.additionalServices?.labsInPerson?.info ?? '',

    behaviorHealthValue: ruleSets?.additionalServices?.behaviorHealth?.value ?? false,
    behaviorHealthInfo: ruleSets?.additionalServices?.behaviorHealth?.info ?? '',

    mentalHealthValue: ruleSets?.additionalServices?.mentalHealth?.value ?? false,
    mentalHealthInfo: ruleSets?.additionalServices?.mentalHealth?.info ?? '',

    housingValue: ruleSets?.additionalServices?.housing?.value ?? false,
    housingInfo: ruleSets?.additionalServices?.housing?.info ?? '',

    foodInsecurityValue: ruleSets?.additionalServices?.foodInsecurity?.value ?? false,
    foodInsecurityInfo: ruleSets?.additionalServices?.foodInsecurity?.info ?? '',

    // patientCare
    stiReporting: ruleSets?.patientCare?.stiReporting ?? '',
    posChlamydia: ruleSets?.patientCare?.posChlamydia ?? '',
    posGonorrhea: ruleSets?.patientCare?.posGonorrhea ?? '',
    posSyphilis: ruleSets?.patientCare?.posSyphilis ?? '',
    posHIV: ruleSets?.patientCare?.posHIV ?? '',

    ...Object.keys(providerTypes).reduce((acc, name) => {
      const isCBOProvidesTheType = cbo?.cbosProviderTypes?.some((providerType) => providerType.name === name) ?? false
      return { ...acc, [name]: isCBOProvidesTheType }
    }, {}),

    // financials
    copayAssistancePrograms: ruleSets?.financials?.copayAssistancePrograms ?? false,
    cashSaleProgram340b: ruleSets?.financials?.cashSaleProgram340b ?? false,
    financialCapAmount: ruleSets?.financials?.financialCapAmount ?? '',
    financialCapUninsuredAmount: ruleSets?.financials?.financialCapUninsuredAmount ?? '',
    patientTransfers: ruleSets?.financials?.patientTransfers ?? '',
    payorRequiresTransfer: ruleSets?.financials?.payorRequiresTransfer ?? '',
    feeForService: ruleSets?.financials?.feeForService ?? '',

    // contacts
    contacts: ruleSets?.contacts ?? [],
  }
}

export const validationSchema = Yup.object().shape({
  // grant_340b_information
  hrsaId: Yup.string().required('Required'),
  hrsaGrantNumber: Yup.string().required('Required'),
  noticeOfFundingNumber: Yup.string().required('Required'),
  hrsaUrl: Yup.string().required('Required'),
  entityEMRSystem: Yup.string(),
  grantPatientDefinition: Yup.string(),

  // additional_services
  hepCScreenAndTreatValue: Yup.boolean(),
  hepCScreenAndTreatInfo: Yup.string().when('hepCScreenAndTreatValue', {
    is: true,
    then: (schema) => schema.required('Required'),
  }),

  longitudinalHIVCareValue: Yup.boolean(),
  longitudinalHIVCareInfo: Yup.string().when('longitudinalHIVCareValue', {
    is: true,
    then: (schema) => schema.required('Required'),
  }),

  inPersonHealthCenterValue: Yup.boolean(),
  inPersonHealthCenterInfo: Yup.string().when('inPersonHealthCenterValue', {
    is: true,
    then: (schema) => schema.required('Required'),
  }),

  labsInPersonValue: Yup.boolean(),
  labsInPersonInfo: Yup.string().when('labsInPersonValue', {
    is: true,
    then: (schema) => schema.required('Required'),
  }),

  behaviorHealthValue: Yup.boolean(),
  behaviorHealthInfo: Yup.string().when('behaviorHealthValue', {
    is: true,
    then: (schema) => schema.required('Required'),
  }),

  mentalHealthValue: Yup.boolean(),
  mentalHealthInfo: Yup.string().when('mentalHealthValue', {
    is: true,
    then: (schema) => schema.required('Required'),
  }),

  housingValue: Yup.boolean(),
  housingInfo: Yup.string().when('housingValue', {
    is: true,
    then: (schema) => schema.required('Required'),
  }),

  foodInsecurityValue: Yup.boolean(),
  foodInsecurityInfo: Yup.string().when('foodInsecurityValue', {
    is: true,
    then: (schema) => schema.required('Required'),
  }),

  // patient_care
  stiReporting: Yup.string().required('Required'),
  posChlamydia: Yup.string().required('Required'),
  posGonorrhea: Yup.string().required('Required'),
  posSyphilis: Yup.string().required('Required'),
  posHIV: Yup.string().required('Required'),

  // financials
  copayAssistancePrograms: Yup.boolean(),
  cashSaleProgram340b: Yup.boolean(),
  financialCapAmount: Yup.string().required('Required'),
  financialCapUninsuredAmount: Yup.string(),
  patientTransfers: Yup.string().required('Required'),
  payorRequiresTransfer: Yup.string().required('Required'),
  feeForService: Yup.string().required('Required'),

  // contacts
  contacts: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Name is required'),
      email: Yup.string().email('A valid email address is required'),
      title: Yup.string(),
    })
  ),
})

export const formikToApiData = (data) => {
  return {
    grant_340b_information: JSON.stringify({
      hrsaId: data.hrsaId,
      hrsaGrantNumber: data.hrsaGrantNumber,
      noticeOfFundingNumber: data.noticeOfFundingNumber,
      hrsaUrl: data.hrsaUrl,
      entityEMRSystem: data.entityEMRSystem,
      grantPatientDefinition: data.grantPatientDefinition,
    }),
    additional_services: JSON.stringify({
      hepCScreenAndTreat: { value: data.hepCScreenAndTreatValue, info: data.hepCScreenAndTreatInfo },
      longitudinalHIVCare: { value: data.longitudinalHIVCareValue, info: data.longitudinalHIVCareInfo },
      inPersonHealthCenter: { value: data.inPersonHealthCenterValue, info: data.inPersonHealthCenterInfo },
      labsInPerson: { value: data.labsInPersonValue, info: data.labsInPersonInfo },
      behaviorHealth: { value: data.behaviorHealthValue, info: data.behaviorHealthInfo },
      mentalHealth: { value: data.mentalHealthValue, info: data.mentalHealthInfo },
      housing: { value: data.housingValue, info: data.housingInfo },
      foodInsecurity: { value: data.foodInsecurityValue, info: data.foodInsecurityInfo },
    }),
    patient_care: JSON.stringify({
      stiReporting: data.stiReporting,
      posChlamydia: data.posChlamydia,
      posGonorrhea: data.posGonorrhea,
      posSyphilis: data.posSyphilis,
      posHIV: data.posHIV,
    }),
    financials: JSON.stringify({
      copayAssistancePrograms: data.copayAssistancePrograms,
      cashSaleProgram340b: data.cashSaleProgram340b,
      financialCapAmount: data.financialCapAmount,
      financialCapUninsuredAmount: data.financialCapUninsuredAmount,
      patientTransfers: data.patientTransfers,
      payorRequiresTransfer: data.payorRequiresTransfer,
      feeForService: data.feeForService,
    }),
    contacts: JSON.stringify(data.contacts),
  }
}
