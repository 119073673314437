const fontFamily = 'proxima-nova, sans-serif'
const fontWeightLight = 300
const fontWeightRegular = 400
const fontWeightMedium = 400
const fontWeightBold = 700

const Typography = {
  htmlFontSize: 16,
  fontFamily,
  fontWeightLight,
  fontWeightRegular,
  fontWeightMedium,
  fontWeightBold,
  h1: {
    fontWeight: fontWeightBold,
    fontSize: '2.375rem', // 38px
    lineHeight: 1.21, // 46px
    fontFamily: 'Raleway, sans-serif',
    textTransform: 'capitalize',
  },
  h2: {
    fontWeight: fontWeightBold,
    fontSize: '1.875rem', // 30px
    lineHeight: 1.27, // 39px
    fontFamily: 'Raleway, sans-serif',
    textTransform: 'capitalize',
  },
  h3: {
    fontWeight: fontWeightBold,
    fontSize: '1.5rem', // 24px
    lineHeight: 1.33, // 32px
  },
  h4: {
    fontWeight: fontWeightBold,
    fontSize: '1.25rem', // 20px
    lineHeight: 1.4, // 28px
  },
  h5: {
    fontWeight: fontWeightBold,
    fontSize: '1rem', // 16px
    lineHeight: 1.5, // 24px
  },
  h6: {
    fontWeight: fontWeightMedium,
    fontSize: '0.875rem', // 14px
    lineHeight: 1.57, // 24px
  },
  caption: {
    fontWeight: fontWeightRegular,
    fontSize: '0.75rem', // 12px
    lineHeight: 1.66, // 20px
  },
  body1: {
    fontSize: '1rem', // 16px
    lineHeight: 1.57, // 24px
  },
  body2: {
    fontSize: '0.875rem', // 14px
    lineHeight: 1.66, // 20px
  },
  subtitle1: {
    fontSize: '0.875rem', // 14px
    fontWeight: fontWeightBold,
    lineHeight: 1.57, // 23px
  },
  subtitle2: {
    fontSize: '0.75rem', // 12px
    fontWeight: fontWeightRegular,
    lineHeight: 1.66, // 20px
  },
  overline: {
    lineHeight: 1.66,
  },
  button: {
    textTransform: 'capitalize',
  },
}

export default Typography
