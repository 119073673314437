import { CloseOutlinedIcon } from '@icons'
import { IconButton, Stack, Typography } from '@mui-components'

export const styles = {
  container: {
    minWidth: 300,
    maxWidth: 400,
    width: '100%',
    px: 2,
    py: 1,
    background: 'radial-gradient(ellipse at 10% 70%, #008ECF 6%, #00A5F0 100%)',
    color: 'text.contrast',
    borderRadius: 3,
    boxShadow: '0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 11px 15px -7px rgba(0, 0, 0, 0.2)',
  },
  close: {
    top: 4,
    right: 4,
    color: 'text.contrast',
    position: 'absolute',
  },
}

export function Title({ label, onClose }) {
  return (
    <Stack direction="row" pr={3}>
      <Typography variant="h5">{label}</Typography>
      <IconButton
        size="small"
        shape="rounded"
        component="div"
        onClick={(e) => {
          e.stopPropagation()
          onClose?.()
        }}
        sx={styles.close}
      >
        <CloseOutlinedIcon style={{ fontSize: 16 }} />
      </IconButton>
    </Stack>
  )
}
