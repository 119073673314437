import { deserialize } from 'deserialize-json-api'

import api from '@shared/services'

const SettingsApi = {
  consentDocuments: () => api.get('/consents_documents').then((res) => res.data),
  featureFlags: () => api.get('/feature_flags').then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  systemSettings: () => {
    return api.get(`/system_settings`).then((res) => {
      const settings = deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []
      if (settings.length === 0) return undefined
      return settings.reduce((acc, setting) => ({ ...acc, [setting.key]: setting.value }), {})
    })
  },
}

export default SettingsApi
