import { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'

import { CaretDownOutlinedIcon } from '@icons'
import { Button, Divider, Menu, MenuItem } from '@mui-components'

import DateRangeDialog, { DateRangeShortcuts } from '../DateRangeDialog'

DateRangeSelect.propTypes = {
  /** Label of the button */
  label: PropTypes.string.isRequired,

  /** Callback fired when the value changes */
  onChange: PropTypes.func.isRequired,

  /** Dayjs values of range */
  value: PropTypes.array,
}

/**
 * Button with a dropdown menu that allows to select a date range via shortcuts or a custom date range
 *
 * @example
 * <DateRangeSelect label="Dates" value={value} onChange={handleChange} />
 */
export default function DateRangeSelect({ label, onChange, value = [], ...other }) {
  const anchorRef = useRef(null)

  const [openMenu, setOpenMenu] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  return (
    <>
      <Button color="inherit" endIcon={<DownIcon />} onClick={() => setOpenMenu(true)} ref={anchorRef} {...other}>
        {label}
      </Button>
      <Menu anchorEl={anchorRef.current} onClose={() => setOpenMenu(false)} open={openMenu} PaperProps={{ style: { width: 250 } }}>
        <DateRangeShortcuts
          value={value}
          onChange={(value) => {
            setOpenMenu(false)
            onChange(value)
          }}
        />
        <Divider />
        <MenuItem
          onClick={() => {
            setOpenDialog(true)
            setTimeout(() => setOpenMenu(false), 300)
          }}
        >
          Choose Dates...
        </MenuItem>
      </Menu>
      <DateRangeDialog open={openDialog} onClose={() => setOpenDialog(false)} initialValue={value} onChange={onChange} />
    </>
  )
}

const DownIcon = styled(CaretDownOutlinedIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
}))
