import { Paper, Stack, Typography } from '@mui-components'

export const CycleOptions = {
  'First Q Care Plus cycle': 'First Q Care Plus quarter',
  'All cycles': 'All quarters',
}

export function Section({ paper, title, children }) {
  const content = (
    <Stack spacing={{ xs: 2, sm: 1, md: 2 }} p={{ xs: 3, sm: 1, md: 3 }}>
      <Typography variant="h4" fontWeight="light">
        {title}
      </Typography>
      <div>{children}</div>
    </Stack>
  )

  if (paper) {
    return (
      <Paper variant="outlined" sx={{ height: '100%' }}>
        {content}
      </Paper>
    )
  }

  return content
}
