import { SvgIcon } from '@mui-components'

export default function LocationIcon(props) {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path fill="#fff" d="M0 0h16v16H0z" />
      <path d="M8 7.667a1.667 1.667 0 1 0 0-3.334 1.667 1.667 0 0 0 0 3.334z" fill="#949696" />
      <path
        d="M8 1.333A4.663 4.663 0 0 0 3.333 6C3.333 9.5 8 14.667 8 14.667S12.667 9.5 12.667 6A4.663 4.663 0 0 0 8 1.333zM4.667 6a3.335 3.335 0 0 1 6.667 0c0 1.92-1.92 4.793-3.334 6.587C6.614 10.807 4.667 7.9 4.667 6z"
        fill="#949696"
      />
    </SvgIcon>
  )
}
