import dayjs from 'dayjs'

/**
 * Convert a camel case string into title case. This will separate the words and capitalize each one
 *
 * @param {string} str - a string to convert
 * @return { {string} } - The passed string in title case, i.e. "goForth" to "Go Forth"
 */
export const camelToTitleCase = (str) => {
  if (str === '') return str
  if (!str || typeof str !== 'string') throw new Error('camelToTitleCase requires a string argument')

  const withSpaces = str.replace(/([A-Z])/g, ' $1')
  return withSpaces.charAt(0).toUpperCase() + withSpaces.slice(1)
}

/**
 * Convert a string into title case
 *
 * @param {string} str - a string to convert
 * @return {string} - The passed string in title case, i.e. first letters of all words capitalized
 */
export const toTitleCase = (str) => {
  if (str === '') return str
  if (!str || typeof str !== 'string') throw new Error('toTitleCase requires a string argument')

  return str
    .trim()
    .split(' ')
    .filter(Boolean)
    .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
    .join(' ')
}

/**
 * Convert a snake case string into title case. This will separate the words and capitalize each one
 *
 * @param {string} str - a string to convert
 * @return { {string} } - The passed string in title case, i.e. "go_forth" to "Go Forth"
 */
export const snakeToTitleCase = (str) => {
  if (str === '') return str
  if (!str || typeof str !== 'string') throw new Error('snakeToTitleCase requires a string argument')

  const withSpaces = str
    .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()) // Initial char (after -/_)
    .replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase()) // First char after each -/_
  return withSpaces.charAt(0).toUpperCase() + withSpaces.slice(1)
}

/**
 * Converts user data into their full name, handles nickname//preferred/chosen name properly
 *
 * @param {Object} user - user object to get the data from
 * @param {string} user.fullName - user's full name
 * @returns {string} - the full name of the user
 */
export const getFullName = (user) => {
  if (!user || typeof user !== 'object') return ''

  return user.fullName
}

/**
 * Converts a patient user data into their full name, handles nickname//preferred/chosen name properly
 *
 * @param {Object} user - user object to get the data from
 * @param {string} user.fullChosenName - user's chosen name
 * @returns {string} - the full name of the user
 */
export const GetPatientFullNameError = "getPatientFullName requires a user object with 'fullChosenName' property"
export const getPatientFullName = (user) => {
  if (!user || typeof user !== 'object') throw new Error(GetPatientFullNameError)

  return user.fullChosenName
}

export const getFirstLetter = (str) => {
  if (!str) return ''
  return str.charAt(0).toUpperCase()
}

export const getInitials = (user) => {
  if (!user) return ''
  const getFirstLetters = (s1, s2) => getFirstLetter(s1) + getFirstLetter(s2)
  if (user.firstName && user.lastName) return getFirstLetters(user.firstName, user.lastName)
  if (user.fullName) {
    const [first, second, third] = user.fullName.replace(/[^a-zA-Z0-9 ]/g, '').split(' ')
    // If there is three parts, which means nickname included, use the nickname and lastname letters
    if (third) return getFirstLetters(first, third)
    // Otherwise use the first and last name letters
    return getFirstLetters(first, second)
  }
  return ''
}

/**
 * Masks email with *'s except for the first and last characters
 *
 * @param email - email to mask
 * @returns {string} - masked email, i.e. 'patient@mail.com' to 'p*****t@mail.com'
 */
export const getEmailMasked = (email) => {
  if (!email) return ''
  const [first, last] = email.split('@')
  const maskedFirst = first.substring(0, 1) + '*'.repeat(first.length - 2) + first.substring(first.length - 1)
  return `${maskedFirst}@${last}`
}

/**
 * Masks phone number with *'s except for the last 4 digits
 *
 * @param phone - phone number to mask
 * @returns {string} - masked phone number, i.e. '(***) ****-1234'
 */
export const getPhoneMasked = (phone) => {
  if (!phone) return ''
  return phone.replace(/(\d{3})(\d{3})(\d{4})/, '(***) ****-$3')
}

/**
 * Format a US phone number.
 *
 * @param phone - phone number to format
 * @returns {string}
 */
export const getPhoneFormatted = (phone) => {
  if (!phone || typeof phone !== 'string') {
    throw new Error('getPhoneFormatted requires a string argument')
  }

  const cleaned = ('' + phone).replace(/\D/g, '')

  if (cleaned.length === 10) {
    return cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
  }

  if (cleaned.length === 11 && phone.substring(0, 2) === '+1') {
    return cleaned.replace(/^1(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3')
  }

  return phone
}

export const getHeightFromStringToInches = (height) => {
  if (!height || !height?.length) return 0
  // Keep just numbers
  const heightStr = height.replace(/\D+/g, '')

  // Transform to inches
  return heightStr.length ? heightStr[0] * 12 + parseInt(heightStr.substring(1) ?? 0) : 0
}

export const getHeightInFeetAndInches = (height) => {
  if (!height) return '0\' 0"'

  const feet = Math.floor(height / 12)
  const inches = height % 12

  // Transform to inches
  return `${feet}' ${inches}"`
}

const getFriendlyDurationFromHoursAndMinutes = (hours, minutes) => {
  if (hours && minutes) return `${hours}h ${minutes}m`
  if (hours) return `${hours}h`
  return `${minutes}m`
}

// Gets duration in hours and minutes from start and end dates, like
// 30m OR 1h OR 1h 30m
export const getFriendlyDuration = (start, end) => {
  if (!start || !end) return ''

  const duration = dayjs.duration(dayjs(end).diff(dayjs(start)))
  const hours = duration.hours()
  const minutes = duration.minutes()

  return getFriendlyDurationFromHoursAndMinutes(hours, minutes)
}

export const getFriendlyDurationFromMinutes = (durationInMinutes) => {
  if (!durationInMinutes) return ''

  const hours = Math.floor(durationInMinutes / 60)
  const minutes = durationInMinutes % 60

  return getFriendlyDurationFromHoursAndMinutes(hours, minutes)
}

/**
 * Checks if a string contains a number
 *
 * @param value - string to check
 * @returns {boolean} - true if string contains a number
 */
export const containsNumber = (value) => new RegExp('^(?=.*[0-9]).+$').test(value)

/**
 * Checks if a string contains a lowercase character
 *
 * @param value - string to check
 * @returns {boolean} - true if string contains a lowercase character
 */
export const containsLowercaseChar = (value) => new RegExp('^(?=.*[a-z]).+$').test(value)

/**
 * Checks if a string contains an uppercase character
 *
 * @param value - string to check
 * @returns {boolean} - true if string contains an uppercase character
 */
export const containsUppercaseChar = (value) => new RegExp('^(?=.*[A-Z]).+$').test(value)

/**
 * Checks if a string contains a special character
 *
 * @param value - string to check
 * @returns {boolean} - true if string contains a special character
 */
export const containsSpecialChar = (value) => new RegExp('^(?=.*[-+_!@#$%^&*.,?]).+$').test(value)

/**
 * Returns a string saying the difference
 * between the user's timezone and the current user's timezone in friendly format
 *
 * @param userTimezone - user's timezone
 * @returns {*|string} - friendly timezone difference
 *
 * @example: 'Asia/Kolkata +5hrs 30m'
 */
export const getTimezoneShift = (userTimezone) => {
  if (!userTimezone) return ''

  try {
    const myTime = dayjs().format('L LT')
    const userTime = dayjs().tz(userTimezone).format('L LT')

    const hoursDiff = dayjs(userTime).diff(dayjs(myTime), 'hours')
    const minutesDiff = dayjs(userTime).diff(dayjs(myTime), 'minute') % 60

    if (hoursDiff === 0 && minutesDiff === 0) return userTimezone

    const isPositive = Math.sign(hoursDiff) === 1 || Math.sign(minutesDiff) === 1
    const hrs = hoursDiff !== 0 ? `${Math.abs(hoursDiff)}hrs` : ''
    const minutes = minutesDiff !== 0 ? `${Math.abs(minutesDiff)}m` : ''

    return `${userTimezone} ${isPositive ? '+' : '-'}${[hrs, minutes].filter(Boolean).join(' ')}`
  } catch (e) {
    return ''
  }
}

/**
 * @example
 * /api/patients/6766/update_data_status -> /api/patients/:id/update_data_status
 * /api/patients/6766 -> /api/patients/:id
 * /api/patients/6766/encounters/1234 -> /api/patients/:id/encounters/:id
 */
export const sanitizeUrl = (url) => {
  if (!url) return url
  return url.replace(/(\d+)/g, ':id')
}

/**
 * Check if a value is a valid JSON
 *
 * @param value
 * @returns {boolean}
 */
export const isJsonString = (value) => {
  try {
    JSON.parse(value)
  } catch (e) {
    return false
  }
  return true
}

/**
 * Parse a JSON string safely
 *
 * @param value
 * @returns {*}
 */
export const parseJsonSafe = (value) => {
  try {
    return JSON.parse(value)
  } catch (e) {
    return value
  }
}

// Convert value to string if it's a number
export const stringifyKey = (key) => (typeof key === 'number' ? key.toString() : key)

// Convert all numbers in the key to strings
export const keyToString = (key = []) => key.map(stringifyKey)

/**
 * Converts address object to a string
 * @example:
 * from:
 * {
 *   address: '123 Main St',
 *   address2: 'Apt 2',
 *   city: 'San Francisco',
 *   state: 'CA',
 *   zip: '94105',
 *   county: 'San Francisco',
 * }
 * to:
 * 123 Main St, Apt 2
 * San Francisco, CA 94105
 * San Francisco
 */
export const addressToString = (a) => {
  if (!a) return ''

  const address = [a.address, a.address2].filter(Boolean).join(', ')
  const cityStateZip = [a.city, a.state, a.zip].filter(Boolean).join(', ')
  return [address, cityStateZip, a.county].filter(Boolean).join('\n')
}
