import { Skeleton, Stack, Typography } from '@mui-components'
import Avatar from '@components/Avatar'

export function Provider({ user }) {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Avatar size="sm" user={user} hover="card" />
      <Stack spacing={-0.5}>
        <Typography>{user.name}</Typography>
        <Typography variant="body2" color="text.secondary">
          {[user.city, user.state].filter(Boolean).join(', ')}
        </Typography>
      </Stack>
    </Stack>
  )
}

Provider.Loading = function () {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Skeleton variant="rounded" width={32} height={32} />
      <Stack spacing={-0.5}>
        <Typography variant="h5">
          <Skeleton width={150} />
        </Typography>
        <Typography variant="body2">
          <Skeleton width={80} />
        </Typography>
      </Stack>
    </Stack>
  )
}
