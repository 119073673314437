import { Skeleton, Stack, Typography } from '@mui-components'
import Avatar from '@components/Avatar'

export default function Patient({ small = false, user, children }) {
  if (!user) return null

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Avatar user={user} size={small ? 'sm' : 'md'} hover="card" />
      <Stack spacing={-0.5}>
        <Typography fontWeight="bold" variant={small ? 'body2' : 'body1'}>
          {user.fullName}
        </Typography>
        {children}
      </Stack>
    </Stack>
  )
}

Patient.Loading = function ({ small }) {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Skeleton variant="rounded" width={small ? 32 : 40} height={small ? 32 : 40} />
      <Stack spacing={-0.5}>
        <Typography variant={small ? 'body2' : 'body1'}>
          <Skeleton width={150} />
        </Typography>
        <Typography variant={small ? 'body2' : 'body1'}>
          <Skeleton width={80} />
        </Typography>
      </Stack>
    </Stack>
  )
}
