import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import api from '@shared/services'
import { keyToString } from '@shared/utils'

export const AppointmentKey = (id) => keyToString(['appointments', id])

export const AppointmentNotesKeys = {
  // ['appointment-notes', appointmentId]
  appointment: (appointmentId) => keyToString(['appointment-notes', appointmentId]),

  // ['appointment-notes', appointmentId, 'lists']
  lists: (appointmentId) => [...AppointmentNotesKeys.appointment(appointmentId), 'lists'],

  // ['appointment-notes', appointmentId, 'lists', { ...query }]
  list: (appointmentId, query) => [...AppointmentNotesKeys.lists(appointmentId), query],
}

const AppointmentsApi = {
  getVideoToken: (appointmentId, identity) =>
    api.get(QS.stringifyUrl({ url: `/appointments/${appointmentId}/video_token`, query: { identity } })).then((resp) => resp.data),
  appointment: (appointmentId, config) =>
    api.get(`/appointments/${appointmentId}`, config).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  notes: (appointmentId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/appointments/${appointmentId}/notes`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  createNote: (appointmentId, message) =>
    api
      .post(`/appointments/${appointmentId}/notes`, { message })
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
}

export default AppointmentsApi
