import { createContext, useContext } from 'react'

import useBackgroundSettings from '../VideoProvider/useBackgroundSettings'
import useLocalTracks from '../VideoProvider/useLocalTracks'

export const TracksContext = createContext(null)

export function useTracksContext() {
  const context = useContext(TracksContext)
  if (!context) {
    throw new Error('useTracksContext must be used within a TracksProvider')
  }
  return context
}

// Provider for local audio and video tracks
export default function TracksProvider({ children, disableBackgroundProcessors = false }) {
  const {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalTracks,
    isAcquiringLocalTracksCompleted,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
    getAudioTracks,
  } = useLocalTracks()

  const videoTrack = localTracks.find((track) => !track.name.includes('screen') && track.kind === 'video')
  const [backgroundSettings, setBackgroundSettings] = useBackgroundSettings(videoTrack, undefined, disableBackgroundProcessors)

  return (
    <TracksContext.Provider
      value={{
        localTracks,
        getLocalVideoTrack,
        getLocalAudioTrack,
        isAcquiringLocalTracks,
        isAcquiringLocalTracksCompleted,
        removeLocalAudioTrack,
        removeLocalVideoTrack,
        getAudioAndVideoTracks,
        getAudioTracks,
        backgroundSettings,
        setBackgroundSettings,
      }}
    >
      {children}
    </TracksContext.Provider>
  )
}
