import { MoreOutlinedIcon } from '@icons'
import { LoadingButton } from '@mui-components'
import Section from '@components/Details/Section'
import TimelineNotes, { TimelineNoteCreation } from '@components/TimelineNotes'

import { useAppointmentNoteCreation, useAppointmentNotes } from '../AppointmentDetailsModal.hooks'

export default function NotesSection({ appointmentId, readOnly, onCreate }) {
  const create = useAppointmentNoteCreation(appointmentId, onCreate)
  const { data, isPending, fetchNextPage, hasNextPage, isFetchingNextPage } = useAppointmentNotes(appointmentId)

  const showLoading = isPending
  const showEmpty = !showLoading && data?.length === 0
  const showData = !showEmpty && data?.length > 0

  return (
    <Section mini title="Notes">
      {!readOnly && <TimelineNoteCreation onSend={(message) => create.mutateAsync(message)} />}
      {showLoading && <TimelineNotes.Loading />}
      {showEmpty && <TimelineNotes.Empty readOnly={readOnly} />}
      {showData && (
        <TimelineNotes>
          {data.map((note, i) => {
            return <TimelineNotes.Item key={note.id} data={note} isLast={data.length - 1 === i} />
          })}
          {hasNextPage && (
            <LoadingButton
              fullWidth
              loading={isFetchingNextPage}
              onClick={() => fetchNextPage()}
              endIcon={<MoreOutlinedIcon rotate={90} />}
              loadingPosition="end"
              sx={{ textTransform: 'none' }}
            >
              more
            </LoadingButton>
          )}
        </TimelineNotes>
      )}
    </Section>
  )
}
