import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import api from './index'

/**
 * Get a specific list of dropdown items by key
 */
const LookupApi = {
  // GET
  all: () => api.get('/dropdown_options').then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),
  key: (key, query) =>
    api
      .get(QS.stringifyUrl({ url: `/dropdown_options/${key}`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data?.value),
}

export default LookupApi
